import React, { FC, useState, useRef } from "react";
import { Container } from "../../components/Container/index";
import { Button, Avatar, Space } from "antd";
import styles from "./styles.module.less";
import querystring from "query-string";
import { Protocol } from "../../config";
import { useTitle } from "../../hooks/useTitle";
import { useTranslation } from "react-i18next";

export const AuthorizePage: FC = () => {
  const { t } = useTranslation();

  useTitle(t("common.authoriztion"));
  const [formHidden, setFormHidden] = useState({
    action: "",
    scopes: [],
  });
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const submitBtn = useRef<any>();

  const query = querystring.parse(window.location.search);
  const uuid = query["uuid"];
  const protocol = window.__config__.protocol;
  const scope = JSON.parse((query["scopes"] as string) ?? "[]");
  const { new: newScope, accepted } = scope;

  const onCancel = () => {
    if (protocol === Protocol.OIDC) {
      setFormHidden({
        action: `/interaction/oidc/${uuid}/abort`,
        scopes: [],
      });
    }

    if (protocol === Protocol.OAUTH) {
      setFormHidden({
        action: `/interaction/oauth/abort`,
        scopes: [],
      });
    }

    setTimeout(() => submitBtn.current.click(), 0);
  };

  const onConfirm = () => {
    setConfirmBtnLoading(true);
    if (protocol === Protocol.OIDC) {
      setFormHidden({
        action: `/interaction/oidc/${uuid}/confirm`,
        scopes: [...newScope, ...accepted] as never[],
      });
    }

    if (protocol === Protocol.OAUTH) {
      setFormHidden({
        action: `/interaction/oauth/confirm`,
        scopes: [...newScope, ...accepted] as never[],
      });
    }

    setTimeout(() => submitBtn.current.click(), 0);
    // 两秒后取消授权 btn loading 状态
    setTimeout(() => {
      setConfirmBtnLoading(false);
    }, 2000);
  };

  return (
    <Container minHeight="auto">
      <Space size={32} align="center" className={styles.header}>
        <Avatar size={80} src={window.__user__?.photo ?? ""} />
        <i className="authing-icon authing-link" style={{ color: "#999999" }} />
        <Avatar size={80} src={window.__config__.logo} />
      </Space>

      <div className={styles.body}>
        <h1>{t("common.authApp")}</h1>
        <p>
          {t("common.authAppSomePermisson", {
            name: window.__config__.name,
          })}
        </p>

        <ul>
          <li>{t("common.visitPersonalAccData")}</li>
        </ul>
      </div>

      <div style={{ flex: 1 }}></div>

      <div className={styles.footer}>
        <Button size="large" style={{ width: 130 }} onClick={onCancel}>
          {t("common.cancel")}
        </Button>
        <Button type="primary" size="large" style={{ width: 130 }} onClick={onConfirm} loading={confirmBtnLoading}>
          {t("common.authoriztion")}
        </Button>
      </div>

      <form action={formHidden.action} method="post" style={{ display: "none" }}>
        {formHidden.scopes.map((scope) => (
          <input type="text" name="consent[acceptedScopes][]" value={scope} />
        ))}
        <button ref={submitBtn} type="submit"></button>
      </form>
    </Container>
  );
};
