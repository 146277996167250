import { Form, Input, message, Modal } from "antd";
import React, { FC, useState } from "react";
import { User } from "authing-js-sdk";
import { SendEmailCode } from "../../../../components/SendEmailCode";
import { useTranslation } from "react-i18next";

export interface BindEmailProps {
  visible: boolean;
  onOk?: (user: User) => void;
  onCancel?: () => void;
}

export const BindEmail: FC<BindEmailProps> = ({ visible, onOk, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Modal
      title={t("common.bindEmail")}
      visible={visible}
      onOk={form.submit}
      onCancel={onCancel}
      confirmLoading={loading}
    >
      <Form
        form={form}
        hideRequiredMark
        labelAlign="left"
        labelCol={{ span: 4 }}
        onFinish={async (values) => {
          try {
            setLoading(true);
            const { emailCode } = values;
            const user = await window.__authing__.updateEmail(email, emailCode);
            message.success(t("common.updateSuccess"));
            onOk && onOk(user);
          } finally {
            setLoading(false);
          }
        }}
      >
        <Form.Item
          name="email"
          label={t("common.email")}
          rules={[
            { required: true, message: t("login.noEmpty") },
            { type: "email", message: t("common.emailFormatError") },
          ]}
        >
          <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder={t("login.inputEmail")} />
        </Form.Item>

        <Form.Item
          name="emailCode"
          label={t("common.verifyCode")}
          rules={[{ required: true, message: t("login.noEmpty") }]}
        >
          <Input
            autoComplete="one-time-code"
            placeholder={t("common.inputFourVerifyCode", {
              length: window.__config__.verifyCodeLength,
            })}
            addonAfter={<SendEmailCode email={email} />}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
