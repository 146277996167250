import React, { forwardRef, useState, ForwardRefRenderFunction, useImperativeHandle, useEffect } from "react";
import { Input, Form } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { FormInstance } from "../../../../hooks/createForm";
import { isGuiding, sessionAccountInfo } from "../../../../components/Guidance/utils";
import { useTranslation } from "react-i18next";
import { RegisterInfo } from "@/types/user";
import { PASSWORD_STRENGTH_TEXT_MAP } from "@/constants/enum";
import { getPasswordValidate } from "@/utils/formRules";

export interface EmailRegisterFormProps extends React.HTMLAttributes<HTMLDivElement> {
  onSubmit?: () => void;
  onSuccess?: (registerInfo: RegisterInfo) => void;
  onFail?: (error: any) => void;
}

const setDefaultAccount = () => {
  const defaultFormValues = {
    email: `${+new Date()}@test.com`,
    password: "test@test.com",
    "new-password": "test@test.com",
  };
  sessionAccountInfo.set(defaultFormValues);
  return defaultFormValues;
};

const InternalEmailRegisterForm: ForwardRefRenderFunction<FormInstance, EmailRegisterFormProps> = (
  { onSubmit, onSuccess, onFail },
  ref
) => {
  const [rawForm] = Form.useForm();
  const [formInitValue, setFormInitValue] = useState({});
  const { t } = useTranslation();

  const passwordStrength = window.__config__.passwordStrength;

  useEffect(() => {
    setFormInitValue(isGuiding() ? setDefaultAccount() : {});
  }, []);

  useEffect(() => {
    rawForm.resetFields();
  }, [rawForm, formInitValue]);

  const onFinish = async (values: any) => {
    try {
      // const authenticationClient = window.__authing__;
      await rawForm.validateFields();
      const { email, password } = values;
      // // 注册并获取登录态
      // const user = await authenticationClient.registerByEmail(
      //   email,
      //   password,
      //   {
      //     browser: navigator.userAgent,
      //     device: getOS(),
      //   },
      //   {
      //     generateToken: true,
      //   }
      // );
      onSuccess &&
        onSuccess({
          email,
          password,
        });
    } catch (error) {
      // onFail && onFail(error);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: async () => {
      try {
        onSubmit && onSubmit();
        await rawForm.validateFields();
        rawForm.submit();
      } catch (error) {
        onFail && onFail(error);
      }
    },
  }));

  return (
    <Form initialValues={formInitValue} form={rawForm} onSubmitCapture={onSubmit} onFinish={onFinish}>
      <button type="submit" hidden></button>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: t("login.inputEmail"),
          },
        ]}
      >
        <Input
          autoComplete="email"
          size="large"
          placeholder={t("login.inputEmail")}
          prefix={<UserOutlined style={{ color: "#ddd" }} />}
        />
      </Form.Item>
      <Form.Item name="password" rules={getPasswordValidate(passwordStrength)}>
        <Input.Password
          autoComplete="new-password"
          size="large"
          visibilityToggle={false}
          placeholder={PASSWORD_STRENGTH_TEXT_MAP[passwordStrength].placeholder}
          prefix={<LockOutlined style={{ color: "#ddd" }} />}
        />
      </Form.Item>
      <Form.Item
        name="new-password"
        style={{
          marginBottom: 0,
        }}
        rules={[
          {
            required: true,
            message: t("login.inputConfirmPwd"),
          },
          {
            validator: (rule, value, callback) => {
              if (value !== rawForm.getFieldValue("password")) {
                callback(t("login.mustEqualToPwd"));
              } else {
                callback();
              }
            },
          },
        ]}
      >
        <Input.Password
          autoComplete="new-password"
          size="large"
          visibilityToggle={false}
          placeholder={t("login.inputPwdAgain")}
          prefix={<LockOutlined style={{ color: "#ddd" }} />}
        />
      </Form.Item>
    </Form>
  );
};

export const EmailRegisterForm = forwardRef<FormInstance, EmailRegisterFormProps>(InternalEmailRegisterForm);
