import { PasswordStrength, PASSWORD_STRENGTH_TEXT_MAP } from "@/constants/enum";
import { Rule } from "antd/lib/form";
import dayjs from "dayjs";

/**
 * 数字，英文字符，特殊符号
 */
const SYMBOL_TYPE_PATTERNS = [/\d+/, /[a-zA-Z]/, /[-!$%^&*()_+|~=`{}[\]:";'<>?,./]/];
/**
 * 获取字符类型种类数量
 */
const getSymbolTypeLength = (pwd: string) => {
  return SYMBOL_TYPE_PATTERNS.map((pattern) => pattern.test(pwd)).filter((item) => item).length;
};
export const getPasswordValidate = (strength: PasswordStrength): Rule[] => {
  const required = {
    required: true,
    message: "密码不能为空",
  };

  const validateMap: Record<PasswordStrength, Rule[]> = {
    [PasswordStrength.NoCheck]: [required],
    [PasswordStrength.Low]: [
      required,
      {
        validator(r, v) {
          if (v && v.length < 6) {
            return Promise.reject(PASSWORD_STRENGTH_TEXT_MAP[PasswordStrength.Low].validateMessage);
          }
          return Promise.resolve();
        },
      },
    ],
    [PasswordStrength.Middle]: [
      required,
      {
        validator(r, v) {
          if (v && (v.length < 6 || getSymbolTypeLength(v) < 2)) {
            return Promise.reject(PASSWORD_STRENGTH_TEXT_MAP[PasswordStrength.Middle].validateMessage);
          }
          return Promise.resolve();
        },
      },
    ],
    [PasswordStrength.High]: [
      required,
      {
        validator(r, v) {
          if (v && (v.length < 6 || getSymbolTypeLength(v) < 3)) {
            return Promise.reject(PASSWORD_STRENGTH_TEXT_MAP[PasswordStrength.High].validateMessage);
          }
          return Promise.resolve();
        },
      },
    ],
  };

  return validateMap[strength];
};

// 格式化时间
const RULES_TIME: string[] = ["YYYY-MM-DD", "YYYY-MM-DD HH:mm", "YYYY-MM-DD HH:mm:ss"];
export const formatTime = (time: string | number, rule: number = 0): string => {
  return dayjs(time).format(RULES_TIME[rule]);
};
