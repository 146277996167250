import React, { FC } from "react";

export interface TipPageLayoutProps {
  img: string;
  imgAlt: string;
  tip: string;
}

export const TipPageLayout: FC<TipPageLayoutProps> = ({ img, imgAlt, tip }) => {
  return (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <img width="250px" src={img} alt={imgAlt} />
      <p
        style={{
          marginTop: 50,
        }}
      >
        {tip}
      </p>
    </div>
  );
};
