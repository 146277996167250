import { Input, Form, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { User } from 'authing-js-sdk';
import { RouterLink } from '../../RouterLink';
import { getCaptchaUrl } from '@/utils';
// import { getUserRegisterParams } from '../../../utils';
export interface SubAccountLoginFormProps extends React.HTMLAttributes<HTMLDivElement> {
	onSubmit?: () => void;
	onSuccess?: (user: User) => void;
	onFail?: (error: any) => void;
	onFinishFailed?: (error: any) => void;
}

export const SubAccountLoginForm = forwardRef<FormInstance, SubAccountLoginFormProps>(({ onSuccess, onFail }, ref) => {
	const [rawForm] = Form.useForm();

	const [needCaptcha, setNeedCaptcha] = useState(false);
	const [verifyCodeUrl, setVerifyCodeUrl] = useState<string | null>(null);
	const [loading, setLoading] = useState(false);

	const onFinishFailed = (errorInfo: any) => {
		setLoading(false);
	};

	const onFinish = async (values: any) => {
		try {
			const authenticationClient = window.__authing__;
			const account = values.account && values.account.trim();
			const password = values.password && values.password.trim();
			const captchaCode = values.captchaCode && values.captchaCode.trim();

			const user = await authenticationClient.loginBySubAccount(account, password, {
				captchaCode
				// customData: getUserRegisterParams()
			});
			onSuccess && onSuccess(user);
		} catch (error) {
			if (typeof error.message === 'string') {
				// js sdk httpclient 的报错，这里只有一种情况就是用户开启了 mfa 的报错
				try {
					const errorData = JSON.parse(error.message);
					onFail && onFail(errorData);
					return;
				} catch (_) {}
			}

			if (error.code === 2000 && verifyCodeUrl === null) {
				setNeedCaptcha(true);
				setVerifyCodeUrl(getCaptchaUrl());
			}

			onFail && onFail(error);
		} finally {
			setLoading(false);
		}
	};

	useImperativeHandle(ref, () => rawForm);

	const formItems = [
		{
			component: (
				<Input
					autoComplete="username"
					size="large"
					placeholder="请输入子账号"
					prefix={<UserOutlined style={{ color: '#ddd' }} />}
				/>
			),
			name: 'account',
			rules: [
				{
					required: true,
					message: '子账号不能为空'
				}
			]
		},
		{
			component: (
				<Input.Password
					size="large"
					placeholder="请输入子账号密码"
					prefix={<LockOutlined style={{ color: '#ddd' }} />}
				/>
			),
			name: 'password',
			rules: [
				{
					required: true,
					message: '请输入子账号密码'
				}
			]
		},
		{
			component: (
				<Input
					size="large"
					placeholder="请输入图形验证码"
					addonAfter={
						<img
							src={verifyCodeUrl ?? ''}
							alt="图形验证码"
							style={{ height: '2em', cursor: 'pointer' }}
							onClick={() => setVerifyCodeUrl(getCaptchaUrl())}
						/>
					}
				/>
			),
			name: 'captchaCode',
			rules: [
				{
					required: true,
					message: `请输入 ${window.__config__.verifyCodeLength} 位图形验证码`
				}
			],
			hide: !needCaptcha
		}
	];

	return (
		<div>
			<Form
				form={rawForm}
				onSubmitCapture={() => setLoading(true)}
				onFinishFailed={onFinishFailed}
				onFinish={onFinish}
			>
				{formItems.map(
					item =>
						!item.hide && (
							<Form.Item key={item.name} name={item.name} rules={item.rules}>
								{item.component}
							</Form.Item>
						)
				)}
				<Button htmlType="submit" size="large" type="primary" loading={loading} block>
					{loading ? '加载中' : '登录'}
				</Button>
			</Form>
			<div
				style={{
					marginTop: 20,
					float: 'right'
				}}
			>
				<RouterLink to="/login">{'主账号登录'}</RouterLink>
			</div>
		</div>
	);
});
