import React, { FC, useEffect } from "react";
import styles from "./styles.module.less";
import { SizeBox } from "../SizeBox";
import classnames from "classnames";
import { popupCenter, isWechatBrowser } from "../../utils/index";
import { Button, Avatar, Space, Tooltip, message } from "antd";
import shortid from "shortid";
import axios from "axios";
import querystring from "query-string";
import { User } from "authing-js-sdk";
import {
  IAzureAdConnectionConfig,
  ICasConnectionConfig,
  IOAuthConnectionConfig,
  ISamlConnectionConfig,
  OIDCConnectionConfig,
  Protocol,
} from "../../config/index";
import { useTranslation } from "react-i18next";
import { ErrorCodes, ScreenSize } from "@/constants/enum";
import { useScreenSize } from "@/hooks/useScreenSize";
import { SocialConnectionProvider } from "@/config";
import { IconFont } from "../IconFont";
import { i18n, Lang } from "@/locales";
// import { getUserRegisterParams } from '../../utils';

export interface SocialLoginProps extends React.HTMLAttributes<HTMLDivElement> {
  noForm?: boolean;
  onFail?: (error: any) => void;
  onSuccess?: (user: User) => void;
}

/**
 * 第三方登录方式列表
 * @param props
 */
export const SocialLogin: FC<SocialLoginProps> = ({ noForm = false, onFail = () => {}, onSuccess = () => {} }) => {
  const config = window.__config__;
  const { t } = useTranslation();

  const [screenSize] = useScreenSize();
  const loginLogoSize = screenSize === ScreenSize.Mobile ? 14 : 20;

  useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      // TODO: event.origin是指发送的消息源，一定要进行验证！！！

      const { code, message: errmsg, data } = event.data;
      // TODO: 和前端约定

      try {
        const parsedMsg = JSON.parse(errmsg);
        const { code: authingCode } = parsedMsg;
        if ([ErrorCodes.MSG_MFA_CODE, ErrorCodes.OTP_MFA_CODE].includes(authingCode)) {
          onFail(parsedMsg);
          return;
        }
      } catch (e) {
        // do nothing...
      }

      if (code !== undefined) {
        if (code === 200) {
          onSuccess(data);
        } else {
          message.error(errmsg);
        }
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onFail, onSuccess]);

  const idpButtons = config.identityProviders.map((i) => {
    if (i.protocol === Protocol.OIDC) {
      const config = i.config as OIDCConnectionConfig;
      const state = shortid.generate();
      const query = querystring.stringify({
        client_id: config.clientId,
        redirect_uri: config.redirectUri,
        scope: config.scopes,
        response_type: config.responseType,
        state,
        nonce: shortid.generate(),
      });
      const url = `${config.authorizationEdpoint}?${query}`;

      return (
        <Button
          key={i.identifier}
          className={styles.button}
          block
          size="large"
          icon={<Avatar size={loginLogoSize} src={i.logo} style={{ marginRight: 8 }} />}
          onClick={async () => {
            await axios.post("/api/v2/connections/oidc/start-interaction", {
              state,
              protocol: i.protocol,
              userPoolId: window.__userPoolId__,
              appId: window.__appId__,
              referer: window.location.href,
              connection: { providerIentifier: i.identifier },
            });
            popupCenter(url);
          }}
        >
          {t("login.loginBy", {
            name: i.displayName,
          })}
        </Button>
      );
    } else if (i.protocol === Protocol.SAML) {
      const config = i.config as ISamlConnectionConfig;
      return (
        <Button
          key={i.identifier}
          className={styles.button}
          block
          size="large"
          icon={<Avatar size={loginLogoSize} src={i.logo} style={{ marginRight: 8 }} />}
          onClick={async () => {
            popupCenter(config.samlRequest!);
          }}
        >
          {t("login.loginBy", {
            name: i.displayName,
          })}
        </Button>
      );
    } else if (i.protocol === Protocol.CAS) {
      const config = i.config as ICasConnectionConfig;

      return (
        <Button
          key={i.identifier}
          className={styles.button}
          block
          size="large"
          icon={<Avatar size={loginLogoSize} src={i.logo} style={{ marginRight: 8 }} />}
          onClick={async () => {
            popupCenter(config.casConnectionLoginUrl!);
          }}
        >
          {t("login.loginBy", {
            name: i.displayName,
          })}
        </Button>
      );
    } else if (i.protocol === Protocol.OAUTH) {
      const config = i.config as IOAuthConnectionConfig;

      return (
        <Button
          key={i.identifier}
          className={styles.button}
          block
          size="large"
          icon={<Avatar size={loginLogoSize} src={i.logo} style={{ marginRight: 8 }} />}
          onClick={async () => {
            popupCenter(config.authUrl!);
          }}
        >
          {t("login.loginBy", {
            name: i.displayName,
          })}
        </Button>
      );
    } else if (i.protocol === Protocol.AZURE_AD) {
      const config = i.config as IAzureAdConnectionConfig;
      return (
        <Button
          key={i.identifier}
          className={styles.button}
          block
          size="large"
          icon={<Avatar size={loginLogoSize} src={i.logo} style={{ marginRight: 8 }} />}
          onClick={async () => {
            popupCenter(config.authorizationUrl);
          }}
        >
          {t("login.loginBy", {
            name: i.displayName,
          })}
        </Button>
      );
    } else {
      return null;
    }
  });

  const socialLoginButtons = config.socialConnections
    // 在微信 App 中只显示微信网页授权登录，普通页面中不显示微信网页授权
    .filter((item) =>
      isWechatBrowser()
        ? item.provider === SocialConnectionProvider.WECHATMP
        : item.provider !== SocialConnectionProvider.WECHATMP
    )
    .map((item) => {
      const iconType = `authing-${item.provider.replace(/:/g, "-")}`;

      let url =
        item.authorizationUrl +
        `?app_id=${window.__appId__}&from_guard=1&authorization_params=${JSON.stringify({
          display: screenSize,
        })}`;

      // if (item.provider !== SocialConnectionProvider.WECHATMP) {
      //   url += `&from_guard=1`;
      // }

      return noForm ? (
        <Button
          key={item.provider}
          block
          size="large"
          className={styles.button}
          icon={<IconFont type={iconType} style={{ fontSize: 20, marginRight: 8 }} />}
          onClick={async () => {
            popupCenter(url);
          }}
        >
          {item.name}
        </Button>
      ) : (
        <Tooltip key={item.provider} title={item?.tooltip?.[i18n.language as Lang] || item.name}>
          <div
            className={classnames(styles.socialLoginItem, "authing-social-login-btn-item")}
            onClick={() => popupCenter(url)}
          >
            <IconFont type={iconType} />
          </div>
        </Tooltip>
      );
    });

  const idp =
    config.identityProviders.length > 0 ? (
      <>
        {!noForm && <div className={styles.socialLoginTitle}>OR</div>}
        <Space size={12} direction="vertical">
          {idpButtons}
        </Space>
      </>
    ) : null;

  const socialLogin =
    socialLoginButtons.length > 0 && noForm ? (
      <Space size={12} direction="vertical">
        {socialLoginButtons}
      </Space>
    ) : (
      socialLoginButtons.length > 0 && (
        <>
          {idp && <SizeBox height={12} />}
          <div className={styles.socialLoginTitle}>{t("login.thridAccLogin")}</div>
          <div className={styles.socialLoginList}>{socialLoginButtons}</div>
        </>
      )
    );

  return (
    <Space
      size={12}
      direction="vertical"
      align={screenSize === ScreenSize.Mobile ? "center" : undefined}
      className={classnames(styles.socialLoginBtns, "__authing-social-login-buttons")}
    >
      {config.ssoPageComponentDisplay.idpBtns && idp}
      {config.ssoPageComponentDisplay.socialLoginBtns && socialLogin}
    </Space>
  );
};
