import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { Input, Form, Alert } from "antd";
import { UserOutlined, SafetyOutlined } from "@ant-design/icons";
import { FormInstance } from "../../../../hooks/createForm";
import { SendPhoneCode } from "../../../SendPhoneCode/index";
import { User } from "authing-js-sdk";
import { useTranslation } from "react-i18next";
import { ScreenSize } from "@/constants/enum";
import { useScreenSize } from "@/hooks/useScreenSize";
import { getUserRegisterParams } from "../../../../utils";

export interface PhoneCodeLoginFormProps extends React.HTMLAttributes<HTMLDivElement> {
  onSubmit?: () => void;
  onSuccess?: (user: User) => void;
  onFail?: (error: any) => void;
  onFinishFailed?: (error: any) => void;
}

const InternalPhoneCodeLoginForm: ForwardRefRenderFunction<FormInstance, PhoneCodeLoginFormProps> = (
  { onSubmit, onSuccess, onFail, onFinishFailed },
  ref
) => {
  const [rawForm] = Form.useForm();
  const [phone, setPhone] = useState("");
  const config = window.__config__;
  const [screenSize] = useScreenSize();
  const { t } = useTranslation();

  const onFinish = async (values: any) => {
    try {
      const authenticationClient = window.__authing__;
      const { phone, code } = values;
      const user = await authenticationClient.loginByPhoneCode(phone, code, {
        customData: getUserRegisterParams(),
      });
      onSuccess && onSuccess(user);
    } catch (error) {
      onFail && onFail(error);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: async () => {
      try {
        onSubmit && onSubmit();
        await rawForm.validateFields();
        rawForm.submit();
      } catch (error) {
        onFail && onFail(error);
      }
    },
  }));

  return (
    <Form form={rawForm} onFinishFailed={onFinishFailed} onSubmitCapture={onSubmit} onFinish={onFinish}>
      <button type="submit" hidden></button>

      {config.ssoPageComponentDisplay.phoneCodeInput && (
        <>
          {config.ssoPageComponentDisplay.autoRegisterThenLoginHintInfo && (
            <Alert message={t("login.phoneAutoRegister")} style={{ marginBottom: 24 }} />
          )}
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: t("login.inputPhone"),
              },
            ]}
          >
            <Input
              autoComplete="tel"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              size="large"
              placeholder={t("login.inputPhone")}
              prefix={<UserOutlined style={{ color: "#ddd" }} />}
            />
          </Form.Item>
          <Form.Item
            name="code"
            rules={[
              {
                required: true,
                message: t("login.inputFourVerifyCode", {
                  length: window.__config__.verifyCodeLength,
                }),
              },
            ]}
          >
            <Input
              className="authing-verify-code-input"
              autoComplete="one-time-code"
              size="large"
              placeholder={t("login.inputFourVerifyCode", {
                length: window.__config__.verifyCodeLength,
              })}
              prefix={<SafetyOutlined style={{ color: "#ddd" }} />}
              addonAfter={screenSize !== ScreenSize.Mobile && <SendPhoneCode phone={phone} />}
              suffix={screenSize === ScreenSize.Mobile && <SendPhoneCode phone={phone} />}
            />
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export const PhoneCodeLoginForm = forwardRef<FormInstance, PhoneCodeLoginFormProps>(InternalPhoneCodeLoginForm);
