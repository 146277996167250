import { getEnv } from "@/utils/getEnv";
import { message } from "antd";
import Axios from "axios";

enum LangxinAppUrl {
  test = "https://api.test.yzbays.com/yazhou/user",
  prod = "https://api.prod.yzbays.com/yazhou/user",
}

// 设置默认过期时间
Axios.defaults.timeout = 10000;
const LANGXIN_APP_BASE_URL =
  process.env.NODE_ENV === "development" ? "https://api.prod.yzbays.com/yazhou/user" : LangxinAppUrl[getEnv()];
const setHeaders = () => ({
  "auth-token":
    process.env.NODE_ENV === "development"
      ? "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cGRhdGVkX2F0IjoiMjAyMi0wNC0yMFQwMjowMDo0Mi4wOTBaIiwiYWRkcmVzcyI6eyJjb3VudHJ5IjpudWxsLCJwb3N0YWxfY29kZSI6bnVsbCwicmVnaW9uIjpudWxsLCJmb3JtYXR0ZWQiOm51bGx9LCJwaG9uZV9udW1iZXJfdmVyaWZpZWQiOmZhbHNlLCJwaG9uZV9udW1iZXIiOm51bGwsImxvY2FsZSI6bnVsbCwiem9uZWluZm8iOm51bGwsImJpcnRoZGF0ZSI6bnVsbCwiZ2VuZGVyIjoiVSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiZW1haWwiOm51bGwsIndlYnNpdGUiOm51bGwsInBpY3R1cmUiOiIvL3N0YXRpYy55emJheXMuY29tL3N0YXRpY3MvYXV0aGluZy1jb25zb2xlL2RlZmF1bHQtdXNlci1hdmF0YXIucG5nIiwicHJvZmlsZSI6bnVsbCwicHJlZmVycmVkX3VzZXJuYW1lIjpudWxsLCJuaWNrbmFtZSI6bnVsbCwibWlkZGxlX25hbWUiOm51bGwsImZhbWlseV9uYW1lIjpudWxsLCJnaXZlbl9uYW1lIjpudWxsLCJuYW1lIjoi5Y-25Y2O5bmzIiwic3ViIjoiNjI1ZWFhMDMxOWY5NWQ4YWQzOWVhNTVmIiwiZXh0ZXJuYWxfaWQiOm51bGwsInVuaW9uaWQiOm51bGwsInVzZXJuYW1lIjoieWhwMTIzMzIxIiwiZGF0YSI6eyJ0eXBlIjoidXNlciIsInVzZXJQb29sSWQiOiI2MDU0MTI0NmE3OTRjMTE2ODQ5ODExZTkiLCJhcHBJZCI6IjYwYjg5ZmFlYjA4NWUwZDhkYTUwNDgxNyIsImlkIjoiNjI1ZWFhMDMxOWY5NWQ4YWQzOWVhNTVmIiwidXNlcklkIjoiNjI1ZWFhMDMxOWY5NWQ4YWQzOWVhNTVmIiwiX2lkIjoiNjI1ZWFhMDMxOWY5NWQ4YWQzOWVhNTVmIiwicGhvbmUiOm51bGwsImVtYWlsIjpudWxsLCJ1c2VybmFtZSI6InlocDEyMzMyMSIsInVuaW9uaWQiOm51bGwsIm9wZW5pZCI6bnVsbCwiY2xpZW50SWQiOiI2MDU0MTI0NmE3OTRjMTE2ODQ5ODExZTkifSwidXNlcnBvb2xfaWQiOiI2MDU0MTI0NmE3OTRjMTE2ODQ5ODExZTkiLCJhdWQiOiI2MGI4OWZhZWIwODVlMGQ4ZGE1MDQ4MTciLCJleHAiOjE2NTE2NjE0NDEsImlhdCI6MTY1MDQ1MTg0MSwiaXNzIjoiaHR0cHM6Ly95ei55emJheXMuY29tL29pZGMifQ.ro9gUOyXIok1u7SVnVObl14Xrsa6eWg8HGruIm-e1Ng"
      : window.__user__?.token,
});
export interface IEnterpriseAuthData {
  enterpriseName: string;
  creditCode: string;
  registerAddr: string;
  legalPersonName: string;
  legalPersonPhone: string;
  businessScope: string;
  enterprisePrincipal: string;
  enterprisePrincipalMobile: string;
  legalPersonCardPositive: string;
  legalPersonCardNegative: string;
}

// 提交企业认证
export const submitEnterpriseAuth = async (data: IEnterpriseAuthData) => {
  const res = (await Axios.post(
    `${LANGXIN_APP_BASE_URL}/enterprise/addOrUpdAuthention`,
    {},
    { headers: setHeaders(), params: data }
  )) as any;
  if (res.code !== 200) {
    message.error(res?.msg);
  }
  return res;
};

// 获取企业认证
export const getEnterpriseAuthInfo = async () => {
  const res = (await Axios.post(
    `${LANGXIN_APP_BASE_URL}/enterprise/selAuthention`,
    {},
    { headers: setHeaders() }
  )) as any;
  if (res.code !== 200) {
    message.error(res?.msg);
  }
  return res.data;
};

// 提交员工认证
export const submitUserAuth = async (data: any) => {
  const res = (await Axios.post(
    `${LANGXIN_APP_BASE_URL}/user/addOrUpdAuthention`,
    {},
    { headers: setHeaders(), params: data }
  )) as any;
  if (res.code !== 200) {
    message.error(res?.msg);
  }
  return res.data;
};

// 获取员工认证信息
export const getUserAuthStatus = async () => {
  const res = (await Axios.post(`${LANGXIN_APP_BASE_URL}/user/selAuthention`, {}, { headers: setHeaders() })) as any;
  if (res.code !== 200) {
    message.error(res?.msg);
  }
  return res.data;
};

// 获取员工是否选择身份、实名认证、员工认证
export const getUserAuthMap = async () => {
  const res = (await Axios.post(`${LANGXIN_APP_BASE_URL}/user/selIsUser`, {}, { headers: setHeaders() })) as any;
  if (res.code !== 200) {
    message.error(res?.msg);
  }
  return res.data;
};

// 获取用户角色
export const fetchUserRole = async () => {
  const res = (await Axios.post(`${LANGXIN_APP_BASE_URL}/user/role`, {}, { headers: setHeaders() })) as any;
  if (res.code !== 200) {
    message.error(res?.msg);
  }
  return res.data;
};
