import React, { FC } from "react";
import { Container } from "../../../components/Container";
import { Header } from "../../../components/Header";
import { SizeBox } from "../../../components/SizeBox";
import { Form, Input, Button, message } from "antd";
import { RouterLink } from "../../../components/RouterLink";
import { SendPhoneCode } from "../../../components/SendPhoneCode/index";
import { SafetyOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import { useTranslation } from "react-i18next";
import { getPasswordValidate } from "@/utils/formRules";
import { PASSWORD_STRENGTH_TEXT_MAP } from "@/constants/enum";

export interface ResetPasswordStep2Props {
  phone: string;
  onFinish?: () => void;
}

export const ResetPasswordStep2: FC<ResetPasswordStep2Props> = ({ phone, onFinish = () => {} }) => {
  const config = window.__config__;
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const onStep2Finish = async (values: any) => {
    const authenticationClient = window.__authing__;
    const code = values.code;
    const password = values.password;

    const res = await authenticationClient.resetPasswordByPhoneCode(phone, code, password);
    if (res.code === 200) {
      onFinish();
    } else {
      message.error(res.message);
    }
  };

  return (
    <Container>
      <Header title={t("login.resetPwd")} logo={config.logo} large />

      <Form form={form} onFinish={onStep2Finish}>
        <Form.Item name="phone" initialValue={phone}>
          <Input
            name="phone"
            readOnly
            size="large"
            placeholder={t("login.inputPhone")}
            prefix={<UserOutlined style={{ color: "#ddd" }} />}
          />
        </Form.Item>
        <Form.Item
          name="code"
          rules={[
            { required: true, message: t("login.noEmpty") },
            {
              len: window.__config__.verifyCodeLength,
              message: t("login.inputFourVerifyCode", {
                length: window.__config__.verifyCodeLength,
              }),
            },
          ]}
        >
          <Input
            name="code"
            size="large"
            autoComplete="code"
            placeholder={t("login.fourVerifyCode", {
              length: window.__config__.verifyCodeLength,
            })}
            prefix={<SafetyOutlined style={{ color: "#ddd" }} />}
            suffix={<SendPhoneCode phone={phone} />}
          />
        </Form.Item>
        <Form.Item name="password" rules={getPasswordValidate(config.passwordStrength)}>
          <Input.Password
            name="password"
            size="large"
            autoComplete="new-password"
            visibilityToggle={false}
            placeholder={PASSWORD_STRENGTH_TEXT_MAP[config.passwordStrength].placeholder}
            prefix={<LockOutlined style={{ color: "#ddd" }} />}
          />
        </Form.Item>
        <Form.Item
          name="repeat-password"
          rules={[
            { required: true, message: t("login.noEmpty") },
            {
              validator: async (rule: RuleObject, value: StoreValue) => {
                if (form.getFieldValue("password") !== value) {
                  throw new Error(t("login.twoPwdNeedSame"));
                }
              },
            },
          ]}
        >
          <Input.Password
            name="repeat-password"
            size="large"
            autoComplete="repeat-password"
            visibilityToggle={false}
            placeholder={t("login.inputPwdAgain")}
            prefix={<LockOutlined style={{ color: "#ddd" }} />}
          />
        </Form.Item>
        <SizeBox height={16} />
        <Form.Item style={{ marginBottom: 8 }}>
          <Button block type="primary" size="large" htmlType="submit" className="authing-reset-password">
            {t("login.resetPwd")}
          </Button>
        </Form.Item>
      </Form>

      <RouterLink
        style={{
          textAlign: "right",
        }}
        className={"authing-link-item-after-btn authing-use-other-accounts"}
        to="/"
      >
        {t("login.otherAccLogin")}
      </RouterLink>
    </Container>
  );
};
