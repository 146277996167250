import React, { FC, useEffect, useCallback } from "react";
import styles from "./styles.module.less";
import { SizeBox } from "../SizeBox/index";
import Avatar from "antd/lib/avatar/avatar";
import { Menu, message, Dropdown, Modal } from "antd";
import axios from "axios";
import querystring from "query-string";
import { useNavigateWithSearch } from "../../hooks/useNavigateWithSearch";
import { useTranslation } from "react-i18next";

export interface LaunchpadLayoutProps {
  name: string;
  showLogo?: boolean;
  children?: React.ReactNode;
}

export const LaunchpadLayout: FC<LaunchpadLayoutProps> = ({
  name,
  showLogo = false,
  children,
}: LaunchpadLayoutProps) => {
  const nav = useNavigateWithSearch();
  const { t } = useTranslation();

  const onLogout = useCallback(async () => {
    await axios.post("/logout");
    if (await window.__authing__.getCurrentUser()) {
      await window.__authing__.logout();
    }
    message.success(t("common.logoutSuccess"));
    setTimeout(() => {
      let redirect = "/login";
      if (window.__config__.logoutRedirectUris?.length) {
        redirect = window.__config__.logoutRedirectUris[0];
      }
      window.location.href = redirect;
    }, 500);
  }, [t]);

  const gotoConsole = () => {
    window.location.href = "/console";
  };

  const gotoUserCenter = () => {
    nav("/u");
  };

  const gotoLaunchpad = () => {
    nav("/launchpad");
  };

  const showLogout = useCallback(() => {
    Modal.warning({
      title: t("common.confirmLogout"),
      okCancel: true,
      onOk: onLogout,
    });
  }, [onLogout, t]);

  useEffect(() => {
    if (querystring.parse(window.location.search)["logout"]) {
      showLogout();
    }
  }, [showLogout]);

  const menu = (
    <Menu>
      {window.__userPoolId__ === window.__config__.rootUserPoolId && (
        <Menu.Item onClick={gotoConsole}>{t("common.dashboard")}</Menu.Item>
      )}
      <Menu.Item onClick={gotoUserCenter}>{t("common.personalCenter")}</Menu.Item>
      <Menu.Item onClick={gotoLaunchpad}>{t("common.appPanel")}</Menu.Item>
      <Menu.Item onClick={showLogout}>{t("common.logout")}</Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        {showLogo && <Avatar src={window.__config__.logo} style={{ marginRight: 8 }} />}
        <span>{name}</span>

        <SizeBox fill />

        <Dropdown overlay={menu}>
          <Avatar src={window.__user__?.photo ?? ""} />
        </Dropdown>
      </header>
      <main className={styles.main}>{children}</main>
    </div>
  );
};
