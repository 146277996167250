import React, { FC, useEffect, useMemo, useState } from "react";
import styles from "./style.module.less";
import { certificationStatusMap } from "./constants";
import { NoCert } from "./NoCert";
import { ProcessingCert } from "./ProcessingCert";
import { YesCert } from "./YesCert";
import { getEnterpriseAuthInfo, getUserAuthMap, getUserAuthStatus } from "@/api/langxinApp";
import { Spin } from "antd";
import { lazyLoading } from "@/utils";
import { useEffectOnce } from "react-use";

interface IEnterpriseCertProps {}

export const EnterpriseCert: FC<IEnterpriseCertProps> = (props) => {
  const [certificationStatus, setCertificationStatus] = useState<string>(certificationStatusMap.NO_CERTIFICATION);
  const [loading, setLoading] = useState<boolean>(false);
  const [isBeginAuth, setIsBeginAuth] = useState<boolean>(false);
  const [enterpriseAuth, setEnterpriseAuth] = useState<any>({});
  const [userAuth, setUserAuth] = useState<any>({});
  const [userAuthStatusMap, setUserAuthStatusMap] = useState<any>({});

  const onUpdate = (type: string) => {
    setCertificationStatus(type);
  };

  const fetchAuthInfo = () => {
    lazyLoading(async () => {
      const [enterpriseAuthInfo, userAuthInfo, userAuthMapInfo] = await Promise.all([
        getEnterpriseAuthInfo(),
        getUserAuthStatus(),
        getUserAuthMap(),
      ]);
      setEnterpriseAuth(enterpriseAuthInfo);
      setUserAuth(userAuthInfo);
      setUserAuthStatusMap(userAuthMapInfo);
      if (enterpriseAuthInfo || userAuthInfo) {
        setCertificationStatus(certificationStatusMap.YES_CERTIFICATION);
      }
    }, setLoading);
  };

  const processComponentMap = useMemo(() => {
    return {
      [certificationStatusMap.NO_CERTIFICATION]: <NoCert onUpdate={onUpdate} userAuthMap={userAuthStatusMap} />,
      [certificationStatusMap.PROCESSING_CERTIFICATION]: (
        <ProcessingCert onUpdate={onUpdate} enterpriseAuth={enterpriseAuth} fetchAuthInfo={fetchAuthInfo} />
      ),
      [certificationStatusMap.YES_CERTIFICATION]: (
        <YesCert enterpriseAuth={enterpriseAuth} userAuth={userAuth} setIsBeginAuth={setIsBeginAuth} />
      ),
    };
  }, [enterpriseAuth, userAuth, userAuthStatusMap]);

  useEffectOnce(() => {
    fetchAuthInfo();
  });

  useEffect(() => {
    if (isBeginAuth) {
      setCertificationStatus(certificationStatusMap.PROCESSING_CERTIFICATION);
    }
  }, [isBeginAuth]);

  return (
    <Spin spinning={loading}>
      <div className={styles.enterpriseCertContainer}>{processComponentMap[certificationStatus]}</div>
    </Spin>
  );
};
