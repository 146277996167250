import classNames from "classnames";
import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./styles.module.less";

export type ProgressStatus = "success" | "exception" | "active";

export interface ProgressProps extends React.HTMLAttributes<HTMLDivElement> {
  status?: ProgressStatus;
  percent?: number;
  size?: number | string;
}

export enum ProgressStatusColor {
  "exception" = "#E63333",
  "active" = "#36C4F2",
  "success" = "#30D95B",
}

export const Progress: FC<ProgressProps> = ({ children, status = "active", percent = 100, size = 400 }) => {
  const secondsRef = useRef<HTMLDivElement>(null);

  const [secondsLeft, setSecondsLeft] = useState<string>("rotateZ(-90deg)");
  const [secondsRight, setSecondsRight] = useState<string>("rotateZ(0deg)");

  const showHours = (percent: number) => {
    const KEY_WORD: number = 50;
    let Rdeg = percent > KEY_WORD ? KEY_WORD : percent;
    let Ldeg = percent > KEY_WORD ? percent - KEY_WORD : 0;

    setSecondsLeft(`rotateZ(${(360 / (2 * KEY_WORD)) * Rdeg - 180}deg)`);
    setSecondsRight(`rotateZ(${(360 / (2 * KEY_WORD)) * Ldeg - 180}deg)`);
  };

  useEffect(() => {
    showHours(percent);
  }, [percent]);

  return (
    <div className={styles.box} style={{ width: size, height: size }}>
      <div ref={secondsRef} className={classNames(styles.clock, styles.seconds)} style={{ width: size, height: size }}>
        <div
          className={styles.left}
          style={{
            borderRadius: `${size}px 0 0 ${size}px`,
          }}
        >
          <div
            style={{
              transform: secondsLeft,
              borderRadius: `${size}px 0 0 ${size}px`,
              backgroundColor: ProgressStatusColor[status],
            }}
          />
        </div>
        <div
          className={styles.right}
          style={{
            borderRadius: `0 ${size}px ${size}px 0`,
          }}
        >
          <div
            style={{
              transform: secondsRight,
              borderRadius: `0 ${size}px ${size}px 0`,
              backgroundColor: ProgressStatusColor[status],
            }}
          />
        </div>
      </div>
      <div className={styles.progress}>{children}</div>
    </div>
  );
};
