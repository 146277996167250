const sensors = require("sa-sdk-javascript");

export const authingWebsite = ["authing.cn", "authing.co"];

export const isAuthing = authingWebsite.includes(window.location.hostname.split(".").slice(-2).join("."));

export const initSensors = () => {
  if (isAuthing) {
    sensors.init({
      sdk_url: "https://static.sensorsdata.cn/sdk/1.15.1/sensorsdata.min.js",
      name: "sensors",
      server_url: "https://authing.datasink.sensorsdata.cn/sa?token=639d94a24a776ac6",
    });
  }
};

export const track = (evtName: string, opts: Record<string, any>) => {
  if (isAuthing) {
    sensors.track(evtName, opts);
  }
};
