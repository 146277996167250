import { User } from "authing-js-sdk";
import React, { createContext, useState, ReactNode } from "react";

export interface IUserContextValue {
  user?: Partial<User>;
  updateUser: (user: Partial<User>) => void;
}

export const UserContext = createContext<IUserContextValue>({ updateUser: () => {} });

export const UserContextProvider = ({ children = null }: { children: ReactNode }) => {
  const [user, setUser] = useState<Partial<User>>((window.__user__ ?? {}) as Partial<User>);

  const updateUser = (_user: Partial<User>) => {
    setUser({ ...user, ..._user });
  };

  const initialData = {
    user,
    updateUser,
  };
  return <UserContext.Provider value={initialData}>{children}</UserContext.Provider>;
};
