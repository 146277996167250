import { ITodoRemindData, todoRemindList } from "@/api/user";
import { Pagination, Table } from "antd";
import React, { FC, useMemo, useState } from "react";
import { useAsyncFn, useEffectOnce } from "react-use";
import { pageSizeOptions } from "../../constants";
import { Card } from "../Card";
import styles from "./style.module.less";
import Indexstyles from "../style.module.less";
import { ToDoModal } from "./ToDoModal";

interface IToDoCardProps {}

let PAGE_SIZE: number = 5;

export const ToDoCard: FC<IToDoCardProps> = (props) => {
  const [detailVisible, setDataVisible] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1);
  const [detail, setDetail] = useState<Partial<ITodoRemindData>>({});

  const [todoList, getTodoList] = useAsyncFn(async (page: number = current) => {
    setCurrent(page);
    return await todoRemindList({ limit: PAGE_SIZE, page });
  }, []);

  useEffectOnce(() => {
    getTodoList();
  });

  const columns = useMemo(() => {
    return [
      {
        title: "标题",
        dataIndex: "title",
        key: "title",
        render: (title: string) => (
          <div className={Indexstyles.ellipsisText} style={{ maxWidth: 284 }}>
            {title}
          </div>
        ),
      },
      {
        title: "通知内容",
        dataIndex: "content",
        key: "content",
        render: (content: string) => (
          <div className={Indexstyles.ellipsisText} style={{ maxWidth: 284 }}>
            {content}
          </div>
        ),
      },
      {
        title: "业务平台",
        dataIndex: "appName",
        key: "appName",
        render: (appName: string) => (
          <div className={Indexstyles.ellipsisText} style={{ maxWidth: 200 }}>
            {appName}
          </div>
        ),
      },
      {
        title: "时间",
        dataIndex: "insertTime",
        key: "insertTime",
      },
      {
        title: "操作",
        key: "actions",
        render: (record: ITodoRemindData) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={() => {
              setDetail(record);
              setDataVisible(true);
            }}
          >
            查看详情
          </a>
        ),
      },
    ];
  }, []);

  return (
    <Card className={styles.toDoCardContainer} title="待办事项" loading={todoList.loading}>
      <Table columns={columns} dataSource={todoList.value?.list || []} size="small" rowKey="key" pagination={false} />
      {todoList.value?.count! > 0 && (
        <div className={styles.pagesCont}>
          <Pagination
            showSizeChanger
            onShowSizeChange={(_, size) => {
              PAGE_SIZE = size;
              getTodoList();
            }}
            pageSizeOptions={pageSizeOptions}
            current={current}
            total={todoList.value?.count ?? 1}
            onChange={(page) => {
              getTodoList(page);
            }}
            pageSize={PAGE_SIZE}
          />
        </div>
      )}
      <ToDoModal
        visible={detailVisible}
        updateVisible={setDataVisible}
        detail={detail}
        getList={() => {
          getTodoList(todoList.value?.list.length === 1 && current !== 1 ? current - 1 : current);
        }}
      />
    </Card>
  );
};
