import React, { FC, useState } from "react";
import { AuthingJoyride } from "./AuthingJoyride";
import { steps } from "./steps";
import { exitNovice, isGuiding, useNoviceEvent } from "./utils";

const defaultStepIndex = 5;

const useStepIndex = () => {
  const [stepIndex, set] = useState(Number(localStorage.getItem("NOVICE_GUID_STEP_INDEX") || defaultStepIndex));

  const setStepIndex = (curr: number) => {
    set(curr);
    localStorage.setItem("NOVICE_GUID_STEP_INDEX", String(curr));
  };

  const nextStep = () => {
    setStepIndex(getCurrStepIndex() + 1);
  };

  const getCurrStepIndex = () => {
    return Number(localStorage.getItem("NOVICE_GUID_STEP_INDEX") || defaultStepIndex);
  };

  return {
    stepIndex,
    setStepIndex,
    getCurrStepIndex,
    nextStep,
  };
};

export const Guidance: FC = () => {
  const { stepIndex, nextStep } = useStepIndex();
  const [start, setStart] = useState(isGuiding());

  useNoviceEvent("next", nextStep);
  useNoviceEvent("exitNovice", () => {
    exitNovice();
    setStart(false);
  });

  return <AuthingJoyride steps={steps} start={start} stepIndex={stepIndex}></AuthingJoyride>;
};
