import React, { FC, useState, useMemo, useEffect } from "react";
import { Space, Checkbox } from "antd";
import styles from "./styles.module.less";
import classnames from "classnames";
import { Agreement } from "@/config";

export interface ReadAndAcceptProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
  style?: React.CSSProperties;
  agreements: Agreement[];
  showError?: boolean;
}

export const ReadAndAccept: FC<ReadAndAcceptProps> = ({ showError, onChange = () => {}, style, agreements }) => {
  const [acceptList, setAcceptList] = useState<(string | number)[]>([]);

  const toggleItemCheck = (id: string | number) => {
    setAcceptList((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  /** 是否可以注册 */
  const isAccept = useMemo(() => {
    /** 所有必须勾选的都勾了就可以通过 */
    return agreements.filter((item) => item.required).every((item) => acceptList.includes(item.id));
  }, [acceptList, agreements]);

  useEffect(() => {
    onChange(isAccept);
  }, [isAccept, onChange]);

  return (
    <div
      className={classnames(styles.readAndAcceptList, {
        [styles.showError]: showError,
      })}
    >
      {agreements.map((item) => {
        return (
          <div
            key={item.id}
            style={{ ...style }}
            className={classnames(styles.readAndAccept, "authing-accept-agreement", {
              [styles.invalid]: item.required && !acceptList.includes(item.id),
            })}
            onClick={() => toggleItemCheck(item.id)}
          >
            <Space align="start" size={5}>
              <Checkbox className={styles.smallCheckbox} checked={acceptList.includes(item.id)} />
              <div
                dangerouslySetInnerHTML={{
                  __html: item.title,
                }}
                style={{
                  lineHeight: "23px",
                }}
              ></div>
            </Space>
          </div>
        );
      })}
    </div>
  );
};
