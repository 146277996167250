import React, { FC } from "react";
import styles from "../style.module.less";
import { ShowCard } from "../../components/ShowCard";
import { getImgSrc } from "@/utils";
import { Button } from "antd";
import { authStepMap } from "../constants";

interface ISuccessAuthProps {
  updateAuthStatus: (a: string) => void;
}

export const SuccessAuth: FC<ISuccessAuthProps> = ({ updateAuthStatus }) => {
  return (
    <div className={styles.successAuthContainer}>
      <ShowCard
        firstTitle="实名认证成功！"
        secondTitle="你已成功实名认证"
        imgProps={{
          src: getImgSrc("/langxin/undraw_Order_confirmed_re_g0if.svg"),
          style: {
            height: 111,
          },
        }}
        actionButton={
          <Button
            style={{ width: 120 }}
            type="primary"
            onClick={() => {
              updateAuthStatus(authStepMap.PROCESSING_AUTH);
            }}
          >
            重新认证
          </Button>
        }
      />
    </div>
  );
};
