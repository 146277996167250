import React, { FC, useEffect, useState, useCallback } from "react";
import { Button, Spin, message, Steps, Modal, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Checkbox } from "antd";
import styles from "./styles.module.less";
import Axios from "axios";
import { VerifyCodeInput } from "../../../components/VerifyCodeInput";
import { getImgSrc } from "../../../utils";
import { useTranslation } from "react-i18next";
import { loginSuccessRedirect } from "@/utils/loginSuccess";
import querystring from "querystring";
import { User } from "authing-js-sdk";
import { useAsyncFn, useEffectOnce } from "react-use";
import { LangxinIconFont } from "@/components/LangxinIconFont";
import { getDownloadUrl } from "@/api/user";
import { downloadAppText } from "../constants";

const { Paragraph } = Typography;

export enum TotpSource {
  SELF = "SELF",
  APPLICATION = "APPLICATION",
}

const { Step } = Steps;

const AppDownload: FC = () => {
  const [download, setDownload] = useState<any>({});

  useEffectOnce(() => {
    console.log(download);
    getDownloadUrl().then((res) => {
      setDownload(res);
    });
  });
  return (
    <div className={styles.appDownload}>
      <div className={styles.authStepContainer}>
        <div className={styles.left}>
          <h3 className={styles.label}>
            <LangxinIconFont type="langxin-iconquestion-fill" style={{ color: "#DD8050", marginRight: "4px" }} />
            MFA 帮助指南
          </h3>
          <Steps direction="vertical" size="small" current={-1} style={{ height: 250 }}>
            <Step
              title="打开“我爱崖州湾” APP"
              description={
                <>
                  如果未下载应用，可以 <span style={{ color: "#DD8050" }}>在右侧进行扫码下载</span>
                </>
              }
            />
            <Step title="在界面底部点击“我的” " />
            <Step title="选择“动态密码”" />
          </Steps>
        </div>
        <div className={styles.right}>
          <h3>下载地址</h3>
          <div className={styles.middle}>
            <div className={styles.qrcode2} style={{ marginRight: "36px" }}>
              <img src={downloadAppText.IOS_QRCODE_URL} alt="" />
              <a className={styles.qrcodeTips} href={downloadAppText.IOS_URL} target="_blank" rel="noopener noreferrer">
                我爱崖州湾 iOS 版
              </a>
            </div>
            <div className={styles.qrcode2}>
              <img src={downloadAppText.ANDROID_QRCODE_URL} alt="" />
              <a
                className={styles.qrcodeTips}
                href={downloadAppText.ANDROID_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                我爱崖州湾 Android 版
              </a>
            </div>
          </div>
          {/* <h4>应用宝下载地址</h4>
          <a href={download?.marketAddr} target="_blank" rel="noopener noreferrer">
            {download?.marketAddr ?? "-"}
          </a> */}
        </div>
      </div>
    </div>
  );
};

const ScanQrcode: FC<{ qrcode: string }> = ({ qrcode }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className={styles.subtitle}>{t("user.scanQrcode")}</h4>
      <p className={styles.desc}>请在“我爱崖州湾”APP 中点击“用户动态密码绑定”，然后点击“扫描二维码”</p>

      <img className={styles.qrcode} src={qrcode} alt="qrcode" />
    </div>
  );
};

export const InputSaftyCode: FC<{
  saftyCode: string[];
  setSaftyCode: (code: string[]) => void;
}> = ({ saftyCode, setSaftyCode }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className={styles.subtitle}>{t("user.inputSafteyCode")}</h4>
      <p className={styles.desc}>{t("user.viewInputSixCode")}</p>

      <VerifyCodeInput className={styles.saftyCodeInput} verifyCode={saftyCode} setVerifyCode={setSaftyCode} />

      <p className={styles.saftyCodeTip}>{t("user.numberSafteyCode")}</p>
    </div>
  );
};

const SaveSecretKey: FC<{
  setIsSaved: Function;
  isSaved: boolean;
  secret: string;
}> = ({ secret, isSaved, setIsSaved }) => {
  const { t } = useTranslation();
  const handleChange = (val: boolean) => {
    setIsSaved(val);
  };
  return (
    <div>
      <h4 className={styles.subtitle}>{t("user.saveSecret")}</h4>
      <p className={styles.desc}>{t("user.resetCodeDoWhat")}</p>

      <Paragraph className={styles.secretParagraph} copyable>
        {secret}
      </Paragraph>

      <Checkbox onChange={(evt) => handleChange(evt.target.checked)} checked={isSaved}>
        {t("login.rememberedSecret")}
      </Checkbox>
    </div>
  );
};

const BindSuccess: FC<{
  totpSource?: TotpSource;
  user?: User;
}> = ({ totpSource = TotpSource.SELF, user }) => {
  const { t } = useTranslation();

  const [onBind, onBindFn] = useAsyncFn(async () => {
    if (user && totpSource === TotpSource.APPLICATION) {
      await window.__authing__.setCurrentUser(user);

      setTimeout(() => {
        loginSuccessRedirect(user);
      }, 1000);
    }
  }, [totpSource, user]);

  useEffect(() => {
    onBindFn();
  }, [onBindFn]);
  return (
    <Spin spinning={onBind.loading}>
      <div>
        <img
          width="148"
          height="148"
          style={{
            marginBottom: "34px",
          }}
          src={getImgSrc("/mfa-bind-success.png")}
          alt="bind success"
        />
        <h4 className={styles.subtitle}>{t("user.bindSuccess")}</h4>
        {totpSource === TotpSource.APPLICATION ? (
          <p className={styles.desc}>{t("common.bindAppTotp")}</p>
        ) : (
          <p className={styles.desc}>{t("user.bindedMfa")}</p>
        )}
      </div>
    </Spin>
  );
};

export interface UserMfaProps {
  totpSource?: TotpSource;
  MFAToken?: string;
}

export const UserMfa: FC<UserMfaProps> = ({ totpSource = TotpSource.SELF, MFAToken }) => {
  const getInitSaftyCode = () => new Array(6).fill("");
  const [saftyCode, setSaftyCode] = useState<string[]>(getInitSaftyCode());
  const [isSaved, setIsSaved] = useState(false);
  const [secret, setSecret] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [user, setUser] = useState<User>();
  const [btnLoading, setBtnLoading] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const { t } = useTranslation();

  const handleBind = async () => {
    setBtnLoading(true);
    try {
      if (totpSource === TotpSource.SELF) {
        await bindSelfTotp();
      } else {
        await bindApplcationTotp();
      }
    } catch (e) {
      message.error(t("user.bindFail"));
    } finally {
      setBtnLoading(false);
    }
  };

  const bindApplcationTotp = async () => {
    const data: any = await Axios.post(
      "/api/v2/mfa/totp/associate/confirm",
      {
        authenticator_type: "totp",
        totp: saftyCode.join(""),
        source: totpSource,
      },
      {
        headers: {
          authorization: MFAToken,
        },
      }
    );

    if (data.code !== 200) {
      message.error(data.message);
    } else {
      const user = data?.data;
      message.success(t("user.bindSuccess"));
      setCurrentStep((currentStep + 1) as keyof typeof STEP_MAP);
      setSaftyCode(getInitSaftyCode());
      setBtnLoading(false);

      user && setUser(user);
    }
  };

  const bindSelfTotp = async () => {
    const data: any = await Axios.post("/api/v2/mfa/totp/associate/confirm", {
      authenticator_type: "totp",
      totp: saftyCode.join(""),
      source: totpSource,
    });

    if (data.code !== 200) {
      message.error(data.message);
    } else {
      message.success(t("user.bindSuccess"));
      setCurrentStep((currentStep + 1) as keyof typeof STEP_MAP);
      setSaftyCode(getInitSaftyCode());
    }
    setBtnLoading(false);
  };

  const handleUnbind = async () => {
    Modal.confirm({
      title: t("user.unbindMfa"),
      icon: <ExclamationCircleOutlined />,
      content: t("user.confirmUnbindMfa"),
      okText: t("common.confirm"),
      cancelText: t("common.cancel"),
      async onOk() {
        setCanceling(true);
        try {
          const data: any = await Axios.delete("/api/v2/mfa/totp/associate");

          if (data.code !== 200) {
            message.error(data.message);
          } else {
            message.success(t("user.unbindSuccess"));
            fetchBindInfo();
            setCurrentStep(0);
          }
          setCanceling(false);
        } catch (e) {
          message.error(t("user.unbindFail"));
          setCanceling(false);
        }
      },
    });
  };

  const fetchBindInfo = useCallback(async () => {
    setIsSpinning(true);
    try {
      const config: {
        headers?: any;
      } = {};
      if (totpSource === TotpSource.APPLICATION) {
        config.headers = {
          authorization: MFAToken,
        };
      }
      const data: any = await Axios.post(
        "/api/v2/mfa/totp/associate",
        {
          authenticator_type: "totp",
          source: totpSource,
        },
        config
      );
      setSecret(data.data.recovery_code);
      setQrcode(data.data.qrcode_data_url);
      setIsSpinning(false);
    } catch (e) {
      message.error(t("user.bindInfoFetchFail"));
      setIsSpinning(false);
    }
  }, [MFAToken, t, totpSource]);

  useEffect(() => {
    (async () => {
      setIsSpinning(true);
      try {
        const config: {
          headers?: any;
        } = {};
        if (totpSource === TotpSource.APPLICATION) {
          config.headers = {
            authorization: MFAToken,
          };
        }
        const query = {
          type: "totp",
          source: totpSource,
        };
        const data: any = await Axios.get(`/api/v2/mfa/authenticator?${querystring.stringify(query)}`, config);
        if (data.data.some((item: any) => item.enable)) {
          setCurrentStep(4);
          setIsSpinning(false);
          return;
        }
        fetchBindInfo();
      } catch (e) {
        setIsSpinning(false);
      }
    })();
  }, [MFAToken, fetchBindInfo, totpSource]);

  const STEP_MAP = {
    0: <AppDownload />,
    1: <ScanQrcode qrcode={qrcode} />,
    2: <InputSaftyCode {...{ saftyCode, setSaftyCode }} />,
    3: <SaveSecretKey {...{ secret, setIsSaved, isSaved }} />,
    4: <BindSuccess totpSource={totpSource} user={user} />,
  };

  const DISABLE_MAP = {
    0: () => false,
    1: () => false,
    2: () => saftyCode.some((item) => !item),
    3: () => !isSaved,
    4: () => false,
  };

  const [currentStep, setCurrentStep] = useState<keyof typeof STEP_MAP>(0);
  const handleNextStep = async () => {
    if (currentStep === 2) {
      await handleBind();
    } else {
      setCurrentStep((currentStep + 1) as keyof typeof STEP_MAP);
    }
  };
  const handlePrevStep = async () => {
    setCurrentStep((currentStep - 1) as keyof typeof STEP_MAP);
  };

  return (
    <Spin spinning={isSpinning}>
      <div className={styles.mfaWrapper}>
        <div className={styles.header}>
          <h3 className={styles.mfaTitle}>{t("user.mfaBind")}</h3>
          {currentStep === 4 && totpSource !== TotpSource.APPLICATION && (
            <Button className={styles.btnItem} type="link" size={"large"} loading={canceling} onClick={handleUnbind}>
              {t("login.releaseBind")}
            </Button>
          )}
        </div>

        <div className={styles.mainContent}>
          <Steps size="small" current={currentStep}>
            <Step title={"下载“我爱崖州湾”"} />
            <Step title={t("user.addMfa")} />
            <Step title={t("user.inputSafteyCode")} />
            <Step title={t("user.saveRecoverCode")} />
            <Step title={t("user.bindFinish")} />
          </Steps>

          <div className={styles.stepContent}>{STEP_MAP[currentStep]}</div>

          {(currentStep === 1 || currentStep === 2) && (
            <Button className={styles.btnItem} loading={btnLoading} size="large" onClick={handlePrevStep}>
              {t("user.prevStep")}
            </Button>
          )}
          {currentStep !== 4 && (
            <Button
              className={styles.btnItem}
              loading={btnLoading}
              disabled={DISABLE_MAP[currentStep]()}
              size="large"
              type="primary"
              onClick={handleNextStep}
            >
              {t("user.nextStep")}
            </Button>
          )}
        </div>
      </div>
    </Spin>
  );
};
