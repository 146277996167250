export const logTypeText = {
  ALL: "all",
  USER_ACTION: "user_action_log",
  BUSINESS_ACTION: "business_action_log",
  LOGIN: "login",
  LOGOUT: "logout",
  REGISTER: "register",
  UPDATE_PASSWORD: "updatePassword",
  BIND_MFA: "bindMfa",
  VERIFY_MFA: "verifyMfa",
};

export const logType = [
  {
    key: logTypeText.ALL,
    value: 0,
    text: "全部",
  },
  {
    key: logTypeText.USER_ACTION,
    value: 1,
    text: "用户行为日志",
  },
  {
    key: logTypeText.BUSINESS_ACTION,
    value: 2,
    text: "业务行为日志",
  },
];

export const actionType = [
  {
    key: logTypeText.ALL,
    value: "",
    text: "全部",
  },
  {
    key: logTypeText.LOGIN,
    value: logTypeText.LOGIN,
    text: "登录操作",
  },
  {
    key: logTypeText.LOGOUT,
    value: logTypeText.LOGOUT,
    text: "登出操作",
  },
  {
    key: logTypeText.REGISTER,
    value: logTypeText.REGISTER,
    text: "注册操作",
  },
  {
    key: logTypeText.UPDATE_PASSWORD,
    value: logTypeText.UPDATE_PASSWORD,
    text: "修改密码",
  },
  {
    key: logTypeText.BIND_MFA,
    value: logTypeText.USER_ACTION,
    text: "绑定 MFA",
  },
  {
    key: logTypeText.VERIFY_MFA,
    value: logTypeText.VERIFY_MFA,
    text: "验证 MFA",
  },
];
