import React, { FC } from "react";
import { Layout } from "antd";
import styles from "./styles.module.less";
import classnames from "classnames";

export interface BackgroundProps extends React.HTMLAttributes<HTMLDivElement> {}

export const Background: FC<BackgroundProps> = ({ children }) => {
  return <Layout className={classnames(styles.layout, "authing-guard-layout")}>{children}</Layout>;
};
