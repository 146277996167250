import Axios from "axios";
import React, { useState } from "react";
import { FC } from "react";
import { CapturePhoto } from "../CapturePhoto";

export const FaceRecognition: FC<{
  onFinish: any;
}> = ({ onFinish }) => {
  const [errorMes, setErrorMes] = useState("");

  const onSeize = async (blob: Blob) => {
    const formData = new FormData();

    formData.append("folder", "photos");
    formData.append("file", blob, "personal.jpeg");

    const {
      data: { key },
    } = await Axios.post("/api/v2/upload?folder=photos&private=true", formData);

    const res: any = await onFinish({ photo: key });

    switch (res.code) {
      case 200:
        setErrorMes("");
        return false;

      default:
        setErrorMes(res.message);
        return false;
    }
  };

  return <CapturePhoto onSeize={onSeize} errorMes={errorMes} isVideoStop={true} />;
};
