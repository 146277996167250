import React, { FC } from "react";
import styles from "./style.module.less";
import { CustomNavCard } from "./CustomNavCard";
import { AppPanelCard } from "./AppPanelCard";
import { ToDoCard } from "./ToDoCard";
import { BusinessLogCard } from ".//BusinessActionLogCard";
import { UserActionLogCard } from "./UserActionLogCard";
import { MessageCenterCard } from "./MessageCenterCard";

interface IWorkBenchProps {}

export const WorkBench: FC<IWorkBenchProps> = (props) => {
  return (
    <div className={styles.workbenchContainer}>
      <div className={styles.workoneContainer}>
        <CustomNavCard />
        <AppPanelCard />
      </div>
      <div className={styles.workoneContainer}>
        <BusinessLogCard />
        <UserActionLogCard />
      </div>
      <ToDoCard />
      <MessageCenterCard />
    </div>
  );
};
