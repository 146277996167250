import React, { FC, ReactNode } from "react";
import styles from "./style.module.less";
interface IFormModuleProps {
  title: string;
  children: ReactNode;
}

export const FormModule: FC<IFormModuleProps> = ({ title, children }) => {
  return (
    <div className={styles.formModuleContainer}>
      <div className={styles.labelTitle}>
        <div className={styles.leftBorder}></div>
        {title}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
