import React, { FC, useEffect, useMemo, useContext } from "react";
import styles from "./styles.module.less";
import { Menu, Tooltip } from "antd";
import { useTitle } from "../../hooks/useTitle";
import { UserPageBasic } from "./Basic";
import { UserPagePassword } from "./Password";
import { UserPageConnection } from "./Connection";
import { UserMfa } from "./Mfa";
import { UserSecurity } from "./Security";
import { WorkBench } from "./Workbench";
import { EnterpriseCert } from "./EnterpriseCert";
import { EmployeeCert } from "./EmployeeCert";
import { RealNameAuth } from "./RealNameAuth";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import { useLocation, useNavigate } from "react-router";
import { UserLayout } from "@/components/UserLayout";
import { baseMenus, baseMenusText } from "./constants";
import { LangxinIconFont } from "@/components/LangxinIconFont";
import { UserContext } from "@/context/userContext";
import { User } from "authing-js-sdk";
import _ from "lodash";
import { getImgSrc } from "@/utils";
import { UserLogs } from "./UserLogs";

export const LangXinUser: FC = () => {
  const { user, updateUser } = useContext(UserContext);
  const { t } = useTranslation();

  useTitle(t("common.personalCenter"), "");

  let menus = [...baseMenus];

  const location = useLocation();
  const navigate = useNavigate();

  const pages: { [key: string]: any } = {
    [baseMenusText.BASIC]: (
      <UserPageBasic
        onUpdate={() => {
          updateUser(window.__user__ as User);
        }}
      />
    ),
    [baseMenusText.WORKBENCH]: <WorkBench />,
    [baseMenusText.PASSWORD]: <UserPagePassword />,
    [baseMenusText.CONNECTION]: <UserPageConnection />,
    [baseMenusText.MFA]: <UserMfa />,
    [baseMenusText.SECURITY_CENTER]: <UserSecurity />,
    [baseMenusText.ENTERPRISE_AUTH]: <EnterpriseCert />,
    [baseMenusText.EMPLOYEE_AUTH]: <EmployeeCert />,
    [baseMenusText.REAL_NAME_AUTH]: <RealNameAuth />,
    [baseMenusText.USER_LOGS]: <UserLogs />,
  };

  const current = useMemo(() => {
    return (qs.parse(location.search).user_active_tab as string) || baseMenus[0].key;
  }, [location.search]);

  useEffect(() => {
    if (!window.__user__) {
      window.location.href = "/login";
      return;
    } else {
      !user?.id && updateUser(_.clone(window.__user__));
    }
  }, [updateUser, user]);

  return (
    <UserLayout
      sider={
        <>
          <div className={styles.menusPhoto}>
            <div className={styles.avatarWrap}>
              <img src={user?.photo ?? ""} style={{ width: 40, height: 40, borderRadius: "50%" }} alt="" />
              <img src={getImgSrc("/langxin/login_active.png")} alt="" className={styles.activeStatus} />
            </div>
            <Tooltip title={user?.username || ""} placement="bottomLeft">
              <span className={styles.userName}>{user?.username || "-"}</span>
            </Tooltip>
          </div>
          <Menu
            mode="inline"
            selectedKeys={[current]}
            style={{ borderRight: 0, paddingBottom: "24px" }}
            className={styles.menuNav}
          >
            {menus.map(({ key, label, icon, style = {} }) => (
              <Menu.Item
                style={style}
                key={key}
                icon={<LangxinIconFont type={icon} style={{ marginRight: "10px" }} />}
                onClick={() => {
                  navigate(
                    `/u?${qs.stringify({
                      ...qs.parse(location.search),
                      user_active_tab: key,
                    })}`
                  );
                }}
              >
                {label}
              </Menu.Item>
            ))}
          </Menu>
        </>
      }
    >
      {pages[current]}
    </UserLayout>
  );
};
