import { getEnv } from "@/utils/getEnv";
import { User, UserLogsInfo } from "authing-js-sdk";
import Axios from "axios";
import * as qs from "query-string";

// test //api.test.yzbays.com/user-portal-dev/api/v1
// prod //api.test.yzbays.com/user-portal/api/v1

const langXinEnv = getEnv();

const domain = {
  test: "//api.test.yzbays.com/user-portal-dev/api/v1",
  prod: "//api.test.yzbays.com/user-portal/api/v1",
};

// 设置默认过期时间
// Axios.defaults.timeout = 5000;
const LANGXIN_BASE_URL =
  process.env.NODE_ENV === "development" ? "http://console.authing.localhost:8000/api/v1" : domain[langXinEnv]; // production;
// process.env.NODE_ENV === "development" ? "//api.test.yzbays.com/user-portal-dev/api/v1" : domain[langXinEnv]; // production;

interface Pagination<T> {
  page: number;
  totalPage: number;
  count: number;
  list: Array<T>;
}
export interface ITodoRemindData {
  id: string;
  title: string;
  appName: string;
  content: string;
  status: number;
  insertTime: string;
  callbackUrl: string | null;
}
export interface IMessageDetail {
  id: string;
  title: string;
  type: string;
  businessId: number;
  businessName: string;
  content: string;
  status: number;
  insertTime: string;
}
export interface IAllCanAddApp {
  appId: string;
  appName: string;
  appLogo: string;
  exist?: false;
  uuid: string;
  identifier: string;
  protocol: string;
}

export interface ICertMapData {
  enterpriseName: string; // 单位名称
  creditCode: string; // 社会信用代码
  registerTime: string; // 成立日期
  legalPersonName: string; // 法人
  registerAddr: string; // 地址
}
export interface IUserOrg {
  children?: IUserOrg[];
  id: string;
  orgId?: string;
  name: string;
  nameI18n?: string;
  description?: string;
  descriptionI18n?: string;
  order?: number;
  code?: string;
  root?: boolean;
  depth?: number;
  path: string[];
  createdAt?: string;
  updatedAt?: string;
  key?: string;
  title?: string;
}
export interface IOrg {
  orgId: string;
  nodeId: string;
  name: string;
  description: string;
  code: string;
}

/* ------------------------------------------------------------------------------------------------
 * ===================================== 工作台 ====================================================
 * ------------------------------------------------------------------------------------------------
 */
// 获取自定义应用 list
export const customNavList = async () => {
  const res = await Axios.get<
    Array<{
      id: string;
      appName: string;
      appLogo: string;
      identifier: string;
      protocol: string;
    }>
  >(`${LANGXIN_BASE_URL}/application`);
  return res.data;
};

// 获取全部应用 list
export const getAllCustomList = async () => {
  const res = await Axios.get(`${LANGXIN_BASE_URL}/application/all`);
  return res.data;
};

// 自定义导航 添加弹窗 APP list
export const getAllCanAddApp = async (search: string = "") => {
  const res = await Axios.get<Array<IAllCanAddApp>>(
    `${LANGXIN_BASE_URL}/application/all?${qs.stringify({ search, limit: 0, page: 1 })}`
  );
  return res.data;
};

// 添加自定义应用
export const addCustomApp = async (appInfo: IAllCanAddApp) => {
  const res = await Axios.put(`${LANGXIN_BASE_URL}/application`, appInfo);
  return res.data;
};

// 删除自定义应用
export const delCustomApp = async (id: string) => {
  const res = await Axios.delete(`${LANGXIN_BASE_URL}/application/${id}`);
  return res.data;
};

// 待办事项 list
export const todoRemindList = async ({ page = 1, limit = 10 }) => {
  const res = await Axios.get<Pagination<ITodoRemindData>>(
    `${LANGXIN_BASE_URL}/remind?${qs.stringify({ page, limit })}`
  );
  return res.data;
};

// 待办已读
export const todoRemindRead = async (id: string) => {
  const res = await Axios.post<{ generatedMaps: any[]; raw: any[]; affected: number }>(
    `${LANGXIN_BASE_URL}/remind/read/${id}`
  );
  return res.data;
};

// 消息中心 list
export const getMessageList = async ({ page = 1, limit = 10 }) => {
  const res = await Axios.get<Pagination<IMessageDetail>>(
    `${LANGXIN_BASE_URL}/message?${qs.stringify({ page, limit })}`
  );
  return res.data;
};

// 消息统计
export const getMessageStatistics = async () => {
  const res = await Axios.get<Array<{ name: string; num: number; type: string }>>(
    `${LANGXIN_BASE_URL}/message/statistics`
  );
  return res.data;
};

// 已读消息
export const updateToReaded = async (id: string) => {
  const res = await Axios.post(`${LANGXIN_BASE_URL}/message/read/${id}`);
  return res.data;
};

// 用户行为日志
export const getUserActionLogs = async ({ page = 1, limit = 10 }) => {
  const res = await Axios.get<Pagination<UserLogsInfo[]>>(
    `${LANGXIN_BASE_URL}/useraction/operation?${qs.stringify({ page, limit })}`
  );
  return res.data;
};

// 用户行为业务日志
export const getUserBusinessLogs = async ({ page = 1, limit = 10 }) => {
  const res = await Axios.get<Pagination<UserLogsInfo[]>>(
    `${LANGXIN_BASE_URL}/useraction?${qs.stringify({ page, limit })}`
  );
  return res.data;
};

// 顶部 header 提示
export const getNotice = async () => {
  const res = await Axios.get<Array<{ content: string }>>(`${LANGXIN_BASE_URL}/notice`);
  return res.data;
};

/* ----------------------------------------------------------------------------------------------------------
 * ============================================ 企业认证 =====================================================
 * ----------------------------------------------------------------------------------------------------------
 */
// 上传证书，返回证书信息
export const uploadCert = async (data: { url: string }) => {
  const res = await Axios.post<ICertMapData>(`${LANGXIN_BASE_URL}/ocr`, data);
  return res.data;
};

// 提交企业认证
export const submitCertQYAuth = async () => {
  const res = await Axios.post(`${LANGXIN_BASE_URL}/testing/addOrUpdAuthention`);
  return res.data;
};

// 获取组织机构
export const getUserOrg = async () => {
  const res = await Axios.get<IUserOrg>(`${LANGXIN_BASE_URL}/organization/tree`);
  return res.data;
};

// 添加部门
export const addOrgUserNode = async (data: { orgId: string; parentNodeId: string; departName: string }) => {
  const res = await Axios.put<IUserOrg>(`${LANGXIN_BASE_URL}/organization/depart/add`, data);
  return res.data;
};

// 删除部门
export const delOrgNode = async (data: { orgId: string; nodeId: string }) => {
  const res = await Axios.delete(`${LANGXIN_BASE_URL}/organization/depart/del?${qs.stringify(data)}`);
  return res;
};

// 更新部门节点
export const updateOrgNode = async (data: { nodeId: string; name: string; code: string; description: string }) => {
  const res = await Axios.post(`${LANGXIN_BASE_URL}/organization/depart/update`, data);
  return res.data;
};

// 移动部门节点
export const moveOrgNode = async (data: { nodeId: string; orgId: string; targetId: string }) => {
  const res = await Axios.post(`${LANGXIN_BASE_URL}/organization/depart/move`, data);
  return res.data;
};

// 人员拖拽移动到部门
export const moveUserOrgNode = async (data: { userId: string; targetNodeId: string; sourceNodeId: string }) => {
  const res = await Axios.post<IUserOrg>(`${LANGXIN_BASE_URL}/organization/member/move`, data);
  return res.data;
};

// 按名称查询组织机构
export const searchOrgList = async (orgName: string = "") => {
  const res = await Axios.get<IOrg[]>(`${LANGXIN_BASE_URL}/organization/company?${qs.stringify({ orgName })}`);
  return res.data;
};

// 获取部门人员列表
export const getOrgUserList = async (departmentId: string, data: { page: number; limit: number }) => {
  const res = await Axios.get<{
    totalCount: number;
    list: Array<User>;
  }>(`${LANGXIN_BASE_URL}/organization/member/${departmentId}?${qs.stringify(data)}`);
  return res.data;
};

/* --------------------------------------------------------------------------------------------------------
 * ========================================= 员工认证 ======================================================
 * --------------------------------------------------------------------------------------------------------
 */
// 提交员工认证
export const submitCertYGAuth = async (data: any) => {
  const res = await Axios.post(`${LANGXIN_BASE_URL}/testing/user/addOrUpdAuthention`, data);
  return res.data;
};

// oss 上传图片
export const uploadOSS = async (data: any) => {
  const res = await Axios.post(`${LANGXIN_BASE_URL}/oss`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

// 查询公司成员
export const fetchUsers = async (data: { nodeId: string; search: string; page: number; limit: number }) => {
  const res = await Axios.get<Pagination<User>>(`${LANGXIN_BASE_URL}/organization/search/member?${qs.stringify(data)}`);
  return res.data;
};

/* ----------------------------------------------------------------------------------------------------------
 * ============================================ 用户日志 =====================================================
 * ----------------------------------------------------------------------------------------------------------
 */
export const getActionAppList = async (search: string) => {
  const res = await Axios.get<
    Array<{
      appId: string;
      appName: string;
    }>
  >(`${LANGXIN_BASE_URL}/useraction/getApp?${qs.stringify({ search })}`);
  return res.data;
};

// 检索日志列表
export const getSearchLogsList = async (data: any) => {
  const res = await Axios.get<
    Pagination<{
      userpoolId: string;
      userId: string;
      username: string;
      operationDesc: string;
      operationName: string;
      timestamp: string;
      appId: string;
      appName: string;
    }>
  >(`${LANGXIN_BASE_URL}/useraction/all`, { params: data });
  return res.data;
};

// 获取下载二维码
export const getDownloadUrl = async () => {
  const res = await Axios.get(`${LANGXIN_BASE_URL}/mfa`);
  return res.data;
};
