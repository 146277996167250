import React from "react";
import { TipPageLayout } from "../../../components/TipPageLayout";
import { getImgSrc } from "../../../utils";
import { useTranslation } from "react-i18next";

export const Error404Page = () => {
  const { t } = useTranslation();

  return <TipPageLayout tip={t("common.appNotFound")} imgAlt="App 404" img={getImgSrc("/app-404.png")} />;
};
