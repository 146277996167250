import React, { FC } from "react";

export interface SizeBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: number | string;
  height?: number | string;
  fill?: boolean;
}

/**
 * 充当间隔的组件
 * @param param0
 */
export const SizeBox: FC<SizeBoxProps> = ({ width, height, fill, style }) => {
  return <div style={{ width, height, flex: fill ? 1 : "auto", ...style }}></div>;
};
