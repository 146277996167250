import { useLocation, useNavigate } from "react-router";
import { useMemo, useCallback } from "react";
import qs from "query-string";
import { isUndefined } from "lodash";

const qsOpts: qs.ParseOptions = {
  arrayFormat: "bracket",
};

/**
 * @description 获取 or 更新路由中的 search
 *
 * @param defaultVal 默认值
 */
export const useQuery = <T extends qs.ParsedQuery = Record<string, any>>(defaultVal?: T) => {
  const location = useLocation();
  const navigate = useNavigate();

  const query = useMemo<T>(() => {
    return (location.search ? (qs.parse(location.search, qsOpts) as T) : defaultVal) || ({} as T);
  }, [defaultVal, location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  const setQuery = useCallback(
    (val: ((prev?: T) => T) | T) => {
      const nextVal = typeof val === "function" ? val(query) : val;
      navigate(`?${qs.stringify(nextVal, qsOpts)}`);
    },
    [navigate, query] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return [query, setQuery] as const;
};

/**
 * @description 适用于只读取 or 更新指定 key，如 tabs 中的 activeTab 变换
 *
 * @param key query 中的 key
 * @param defaultVal 默认值
 * @param replaceAll 更新时，是否替换所有 query
 */
export const useQueryWithKey = <T extends any = any>(key: string, defaultVal?: T, replaceAll: boolean = true) => {
  const [query, setQuery] = useQuery(
    isUndefined(defaultVal)
      ? {}
      : {
          [key]: defaultVal as any,
        }
  );

  const queryValue = useMemo<T>(() => {
    return query[key];
  }, [key, query]); // eslint-disable-line react-hooks/exhaustive-deps

  const setKeyQuery = useCallback(
    (val: T, fnReplaceAll: boolean = replaceAll) => {
      setQuery((prev: any) => {
        return fnReplaceAll
          ? {
              [key]: val,
            }
          : {
              ...prev,
              [key]: val,
            };
      });
    },
    [key, replaceAll, setQuery] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return [queryValue, setKeyQuery] as const;
};
