import "./utils/ioSetup";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RegisterPage } from "./routes/Register";
import { LoginPage } from "./routes/Login";
import { Background } from "./components/Background";
import { ChooseRolePage } from "./routes/ChooseRole/index";
import { ResetPasswordPage } from "./routes/ResetPassword/index";
import { AuthorizePage } from "./routes/Authorize/index";
import { RegisterCompletePage } from "./routes/RegisterComplete/index";
import { LangXinUser } from "./routes/LangXinUser/index";
import { LaunchpadPage } from "./routes/Launchpad/index";
import { ResetMFA } from "./routes/ResetMFA";
import { Error404Page } from "./routes/Error/404";
import { ProblemPage } from "./routes/Problem";
import { Guidance } from "./components/Guidance";
import { SubAccountPage } from "./routes/SubAccount";
import { AgreementPage } from "./routes/Agreement";
import { FeedbackSuccessPage } from "./routes/FeedbackSuccess";
import { BindFace } from "./routes/BindFaceMFA";
import { BindTotpMFA } from "./routes/BindTotpMFA";
import { UserContextProvider } from "@/context/userContext";

export const App = () => {
  const config = window.__config__;
  const registerDisabled = config?.ssoPageComponentDisplay?.autoRegisterThenLoginHintInfo;

  return (
    <Background>
      <Guidance />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/sub-account" element={<SubAccountPage />} />
          {!registerDisabled && <Route path="/register" element={<RegisterPage />} />}
          <Route path="/register-complete" element={<RegisterCompletePage />} />
          <Route path="/choose-role" element={<ChooseRolePage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/authz" element={<AuthorizePage />} />
          <UserContextProvider>
            <Route path="/u" element={<LangXinUser />} />
          </UserContextProvider>
          <Route path="/login/profile" element={<LangXinUser />} />
          <Route path="/launchpad" element={<LaunchpadPage />} />
          <Route path="/reset-mfa" element={<ResetMFA />} />
          <Route path="/bind-face" element={<BindFace />} />
          <Route path="/bind-totp" element={<BindTotpMFA />} />
          <Route path="/error/404" element={<Error404Page />} />
          <Route path="/feedback" element={<ProblemPage />} />
          <Route path="/feedback-success" element={<FeedbackSuccessPage />} />
          <Route path="/agreement" element={<AgreementPage />} />
        </Routes>
      </BrowserRouter>
    </Background>
  );
};
