import React, { FC } from "react";
import { Link, LinkProps } from "react-router-dom";

export interface RouterLinkProps extends LinkProps {}

export const RouterLink: FC<RouterLinkProps> = (props) => {
  if (typeof props.to === "string") {
    props = {
      ...props,
      to: {
        pathname: props.to,
        search: window.location.search,
      },
    };
  }
  return <Link {...props}></Link>;
};
