import { ITodoRemindData, todoRemindRead } from "@/api/user";
import { formatTime } from "@/utils/formRules";
import { Button, message, Modal } from "antd";
import React, { FC } from "react";
import styles from "./style.module.less";

interface IToDoModalProps {
  visible: boolean;
  updateVisible: (bool: boolean) => void;
  detail: Partial<ITodoRemindData>;
  getList: () => void;
}

export const ToDoModal: FC<IToDoModalProps> = ({ visible, updateVisible, detail, getList }) => {
  return (
    <Modal
      title={detail.title}
      visible={visible}
      onCancel={() => updateVisible(false)}
      style={{
        width: 486,
        height: 406,
      }}
      className={styles.userToDoModal}
      footer={
        <>
          <Button onClick={() => updateVisible(false)}>取消</Button>
          {detail.status === 0 && (
            <Button
              onClick={async () => {
                await todoRemindRead(detail.id as string);
                message.success("已处理成功");
                updateVisible(false);
                getList();
              }}
            >
              已处理
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              detail.callbackUrl && window.open(detail.callbackUrl);
            }}
            disabled={!detail.callbackUrl}
          >
            去处理
          </Button>
        </>
      }
    >
      <div className={styles.time}>{formatTime(detail.insertTime as string, 2)}</div>
      <div className={styles.content}>{detail.content}</div>
    </Modal>
  );
};
