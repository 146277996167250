import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { Input, Form } from "antd";
import { UserOutlined, LockOutlined, SafetyOutlined } from "@ant-design/icons";
import { SendPhoneCode } from "../../../SendPhoneCode";
import { FormInstance } from "../../../../hooks/createForm";
import { useTranslation } from "react-i18next";
import { RegisterInfo } from "@/types/user";
import { getPasswordValidate } from "@/utils/formRules";
import { PASSWORD_STRENGTH_TEXT_MAP } from "@/constants/enum";

export interface PhoneRegisterFormProps extends React.HTMLAttributes<HTMLDivElement> {
  onSubmit?: () => void;
  onSuccess?: (user: RegisterInfo) => void;
  onFail?: (error: any) => void;
}

const InternalPhoneRegisterForm: ForwardRefRenderFunction<FormInstance, PhoneRegisterFormProps> = (
  { onSubmit, onSuccess, onFail },
  ref
) => {
  const [rawForm] = Form.useForm();
  const [phone, setPhone] = useState("");
  const { t } = useTranslation();

  const passwordStrength = window.__config__.passwordStrength;

  const onFinish = async (values: any) => {
    try {
      // const authenticationClient = window.__authing__;
      await rawForm.validateFields();
      const { phone, code, password } = values;
      // const user = await authenticationClient.registerByPhoneCode(
      //   phone,
      //   code,
      //   password,
      //   {
      //     browser: navigator.userAgent,
      //     device: getOS(),
      //   },
      //   {
      //     generateToken: true,
      //   }
      // );
      onSuccess &&
        onSuccess({
          phone,
          code,
          password,
        });
    } catch (error) {
      // onFail && onFail(error);
    }
  };

  useImperativeHandle(ref, () => ({
    submit: async () => {
      try {
        onSubmit && onSubmit();
        await rawForm.validateFields();
        rawForm.submit();
      } catch (error) {
        onFail && onFail(error);
      }
    },
  }));

  return (
    <Form form={rawForm} onSubmitCapture={onSubmit} onFinish={onFinish}>
      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: t("login.inputPhone"),
          },
        ]}
      >
        <Input
          autoComplete="tel"
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          size="large"
          placeholder={t("login.inputPhone")}
          prefix={<UserOutlined style={{ color: "#ddd" }} />}
        />
      </Form.Item>
      <Form.Item name="password" rules={getPasswordValidate(passwordStrength)}>
        <Input.Password
          autoComplete="new-password"
          size="large"
          visibilityToggle={false}
          placeholder={PASSWORD_STRENGTH_TEXT_MAP[passwordStrength].placeholder}
          prefix={<LockOutlined style={{ color: "#ddd" }} />}
        />
      </Form.Item>
      <Form.Item
        name="new-password"
        rules={[
          {
            required: true,
            message: t("login.inputConfirmPwd"),
          },
          {
            validator: (rule, value, callback) => {
              if (value !== rawForm.getFieldValue("password")) {
                callback(t("login.mustEqualToPwd"));
              } else {
                callback();
              }
            },
          },
        ]}
      >
        <Input.Password
          autoComplete="new-password"
          size="large"
          visibilityToggle={false}
          placeholder={t("login.inputPwdAgain")}
          prefix={<LockOutlined style={{ color: "#ddd" }} />}
        />
      </Form.Item>
      <Form.Item
        style={{
          marginBottom: 0,
        }}
        name="code"
        rules={[
          {
            required: true,
            message: t("login.inputFourMsgVerifyCode", {
              length: window.__config__.verifyCodeLength,
            }),
          },
        ]}
      >
        <Input
          className={"authing-verify-code-input"}
          autoComplete="one-time-code"
          size="large"
          placeholder={t("login.inputFourMsgVerifyCode", {
            length: window.__config__.verifyCodeLength,
          })}
          prefix={<SafetyOutlined style={{ color: "#ddd" }} />}
          addonAfter={<SendPhoneCode phone={phone} />}
        />
      </Form.Item>
    </Form>
  );
};

export const PhoneRegisterForm = forwardRef<FormInstance, PhoneRegisterFormProps>(InternalPhoneRegisterForm);
