import React, { FC } from "react";
import styles from "./style.module.less";
import { ShowCard } from "../../components/ShowCard";
import { Org } from "./Org";
import { Button, Tag } from "antd";
import { getImgSrc } from "@/utils";
import { authCheckMap, enterpriseAuthStatusMap } from "../constants";

interface IYesCertProps {
  enterpriseAuth: any;
  userAuth: any;
  setIsBeginAuth: (bool: boolean) => void;
}

export const YesCert: FC<IYesCertProps> = ({ enterpriseAuth, userAuth, setIsBeginAuth }) => {
  return (
    <div className={styles.yesCertContainer}>
      <ShowCard
        showActionBtn={
          enterpriseAuth?.status === authCheckMap.AUTH_FAIL || enterpriseAuth?.status === authCheckMap.AUTH_SUCESS
        }
        firstTitle={
          <div className={styles.enterpriseTitle}>
            <div className={styles.title}>{enterpriseAuth?.enterpriseName ?? userAuth?.orgName ?? "-"}</div>
            <Tag
              color={
                enterpriseAuth?.status >= 0
                  ? enterpriseAuthStatusMap[enterpriseAuth?.status as 0 | 1 | 2].type
                  : userAuth?.orgId
                  ? enterpriseAuthStatusMap[1].type
                  : "default"
              }
            >
              {enterpriseAuth?.status >= 0
                ? enterpriseAuthStatusMap[enterpriseAuth?.status as 0 | 1 | 2].text
                : userAuth?.orgId
                ? enterpriseAuthStatusMap[1].text
                : "未认证"}
            </Tag>
          </div>
        }
        secondTitle={
          enterpriseAuth?.status === authCheckMap.AUTH_FAIL
            ? enterpriseAuth?.reason
            : enterpriseAuth?.registerAddr ?? "-"
        }
        imgProps={{
          src: getImgSrc("/langxin/undraw_quite_town_mg2q.svg"),
          className: styles.topBackImg,
        }}
        actionButton={
          <Button
            type="primary"
            style={{ width: 120 }}
            onClick={() => {
              setIsBeginAuth(true);
            }}
          >
            重新认证
          </Button>
        }
      />
      {enterpriseAuth?.status === authCheckMap.AUTH_SUCESS || userAuth?.status === authCheckMap.AUTH_SUCESS ? (
        <Org />
      ) : null}
    </div>
  );
};
