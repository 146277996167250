import { getImgSrc } from "@/utils";
import { Button, message } from "antd";
import React, { FC } from "react";
import { authStatusMap, certificationStatusMap } from "../constants";
import styles from "./style.module.less";
interface INoCertProps {
  onUpdate: (type: string) => void;
  userAuthMap: any;
}

export const NoCert: FC<INoCertProps> = ({ onUpdate, userAuthMap }) => {
  return (
    <div className={styles.noCertContainer}>
      <img src={getImgSrc("/langxin/undraw_Updated_resume_re_q1or.svg")} alt="" className={styles.certImg} />
      <span className={styles.tipsOne}>你的企业还未认证</span>
      <span className={styles.tipsTwo}>为了不影响你的操作，请尽快完成企业认证</span>
      <Button
        type="primary"
        style={{ width: 120 }}
        onClick={() => {
          const flag = sessionStorage.getItem("__FLAG_AUTH_WARHING_TEXT__");
          if (userAuthMap?.isVerified === authStatusMap.AUTH_Yes || flag === "__action_ok__") {
            onUpdate(certificationStatusMap.PROCESSING_CERTIFICATION);
          } else {
            message.warn("你还未进行实名认证，不能进行企业认证操作！");
          }
          // onUpdate(certificationStatusMap.PROCESSING_CERTIFICATION);
        }}
      >
        立即认证
      </Button>
    </div>
  );
};
