import jsCookie from "js-cookie";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import qs from "query-string";

import * as enUsTrans from "./en";
import * as zhCnTrans from "./zh";

export const LANG_STORAGE_KEY = "_authing_lang";
export const topLevelDomain = window.location.hostname.split(".").slice(-2).join(".");

export enum Lang {
  zhCn = "zh-CN",
  enUs = "en-US",
}

export const LANG_MAP = [
  {
    label: "中文",
    value: Lang.zhCn,
  },
  {
    label: "English",
    value: Lang.enUs,
  },
];

export const changeLang = (lang: Lang) => {
  jsCookie.set(LANG_STORAGE_KEY, lang, {
    domain: topLevelDomain,
    path: "/",
  });

  const query = qs.parse(window.location.search);
  if (query[LANG_STORAGE_KEY]) {
    /**
     * query 中记录的语言
     */
    window.location.href = window.location.href.replace(
      window.location.search,
      `?${qs.stringify({
        ...query,
        [LANG_STORAGE_KEY]: lang,
      })}`
    );
  } else {
    window.location.reload();
  }
};

export const LANGS = Object.values(Lang);

i18n
  .use(LanguageDetector) // 监测当前浏览器语言
  .use(initReactI18next) // 初始化 i18n
  .init({
    detection: {
      order: ["querystring", "cookie"],
      lookupCookie: LANG_STORAGE_KEY,
      cookieDomain: topLevelDomain,
      lookupQuerystring: LANG_STORAGE_KEY,
      cookieOptions: {
        path: "/",
      },
    },
    resources: {
      [Lang.enUs]: {
        translation: enUsTrans,
      },
      [Lang.zhCn]: {
        translation: zhCnTrans,
      },
      //   "ja-JP": {
      //     translation: jaTrans,
      //   },
    },
    fallbackLng: Lang.zhCn,
    debug: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export function getInitLang() {
  const cookieLang = jsCookie.get(LANG_STORAGE_KEY) as Lang;
  if (cookieLang) {
    return cookieLang;
  }

  const computerLang = navigator.language;
  const matched = LANGS.find((item) => item.toLocaleLowerCase().includes(computerLang));

  return matched || Lang.zhCn;
}

export { i18n };
