import React, { FC, useState, useMemo } from "react";
import styles from "./styles.module.less";
import { useTranslation } from "react-i18next";
import { Dashboard } from "./dashboard";
// import { BindPhone } from "../Connection/BindPhone";
import { UpdatePasswordModal } from "../Password/UpdatePassword";
import { User } from "authing-js-sdk";
// import { UpdatePhone } from "../Connection/UpdatePhone";
// import { BindEmail } from "../Connection/BindEmail";
// import { UpdateEmail } from "../Connection/UpdateEmail";
import { useAsyncRetry } from "react-use";
import Axios from "axios";
// import qs from "query-string";
// import { useNavigate, useLocation } from "react-router";
import { PasswordSecurityLevel, ACCOUNT_SCORE_LEVEL_MAP } from "../../../constants/enum";
import { Spin } from "antd";
import { PasswordLevel } from "@/components/PasswordLevel";
import { useLocation, useNavigate } from "react-router-dom";
import { baseMenusText } from "../constants";
import qs from "query-string";

interface SecurityLevel {
  email: boolean;
  mfa: boolean;
  password: boolean;
  phone: boolean;
  passwordSecurityLevel: PasswordSecurityLevel | null;
  score: number;
}

const getAccountSecurityLevelText = (score: number = 60) => {
  return ACCOUNT_SCORE_LEVEL_MAP.find((item) => {
    const {
      range: [left, right],
    } = item;
    return score >= left && score <= right;
  })?.text;
};

export const Item = (props: any) => {
  const { title, description, operation, children } = props;
  return (
    <div className={styles.item}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{description}</div>
      </div>
      <div>{operation}</div>
      {children}
    </div>
  );
};

const getIconClassnames = (val?: string | null | boolean) => {
  return val ? "authing-icon authing-wancheng" : "authing-icon authing-warning";
};

export const UserSecurity: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState(window.__user__);
  const updateUser = (newUser: User) => {
    window.__user__ = newUser;
    setUser(newUser);
  };

  const [passModalVisible, setPassModalVisible] = useState(false);
  // const [phoneModalVisible, setPhoneModalVisible] = useState(false);
  // const [emailModalVisible, setEmailModalVisible] = useState(false);

  const securityLevel = useAsyncRetry(async () => {
    const { data } = await Axios.get<SecurityLevel>("/api/v2/users/me/security-level");
    return data;
  }, [user]);
  const passwordLevel = useMemo(() => {
    return securityLevel.value?.passwordSecurityLevel || PasswordSecurityLevel.LOW;
  }, [securityLevel.value]);

  const passwordTips = useMemo(() => {
    if (securityLevel.value?.passwordSecurityLevel === null) {
      return (
        <div
          style={{
            color: "#E02020",
          }}
        >
          {t("user.longTimeNoModifyPwd")}
        </div>
      );
    }
    if (securityLevel.value?.password === false) {
      return (
        <div
          style={{
            color: "#E02020",
          }}
        >
          {t("user.noPwdDanger")}
        </div>
      );
    }

    return t("user.pwdSecurityInfo");
  }, [securityLevel.value, t]);

  return (
    <Spin spinning={securityLevel.loading}>
      <div className={styles.security}>
        <h3>{t("user.securityRank")}</h3>
        <div className={styles.chartRank}>
          <div>
            <div className={styles.title}>{t("user.currSecurityLevel")}</div>
            <div>
              <Dashboard score={securityLevel.value?.score || 60} />
            </div>
            <div className={styles.tip}>
              <span style={{ color: "#999" }}>{t("user.securityLevel")}</span>
              <span>{getAccountSecurityLevelText(securityLevel.value?.score)}</span>
            </div>
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.content}>
            <div className={styles.title}>{t("user.pwdStrang")}</div>
            <PasswordLevel level={passwordLevel} />
            <div className={styles.desc}>{passwordTips}</div>
          </div>
          <div className={styles.operation}>
            <i className={getIconClassnames(user?.password)}></i>
            <div>{securityLevel.value?.password ? t("user.setted") : t("user.unSet")}</div>
            <span className={styles.line}>|</span>
            <div
              onClick={() => {
                navigate(
                  `/u?${qs.stringify({
                    ...qs.parse(location.search),
                    user_active_tab: baseMenusText.PASSWORD,
                  })}`
                );
              }}
              className={styles.bind}
            >
              {securityLevel.value?.password ? t("user.modifyImmidate") : t("user.configImmediate")}
            </div>
          </div>
          <UpdatePasswordModal
            showPwdLevel
            visible={passModalVisible}
            setVisible={setPassModalVisible}
            onSuccess={updateUser}
          />
        </div>
        <Item
          title={securityLevel.value?.phone ? `${t("user.phoneBind")}：${user!.phone}` : t("user.phoneBind")}
          description={t("user.phoneBindTips")}
          operation={
            <div className={styles.operation}>
              <i className={getIconClassnames(securityLevel.value?.phone)}></i>
              <div>{securityLevel.value?.phone ? t("user.binded") : t("user.unBind")}</div>
              <span className={styles.line}>|</span>
              <div
                onClick={() => {
                  navigate(
                    `/u?${qs.stringify({
                      ...qs.parse(location.search),
                      user_active_tab: baseMenusText.CONNECTION,
                    })}`
                  );
                }}
                className={styles.bind}
              >
                {securityLevel.value?.phone ? t("user.modifyImmidate") : t("user.bindImmediate")}
              </div>
            </div>
          }
        >
          {/* {securityLevel.value?.phone ? (
            <UpdatePhone
              user={user!}
              visible={phoneModalVisible}
              onCancel={() => setPhoneModalVisible(false)}
              onOk={(u) => {
                setPhoneModalVisible(false);
                updateUser(u);
              }}
            />
          ) : (
            <BindPhone
              visible={phoneModalVisible}
              onCancel={() => setPhoneModalVisible(false)}
              onOk={(u) => {
                setPhoneModalVisible(false);
                updateUser(u);
              }}
            />
          )} */}
        </Item>
        <Item
          title={securityLevel.value?.email ? `${t("user.emailBind")}：${user!.email}` : t("user.emailBind")}
          description={t("user.bindEmailTips")}
          operation={
            <div className={styles.operation}>
              <i className={getIconClassnames(securityLevel.value?.email)}></i>
              <div>{securityLevel.value?.email ? t("user.binded") : t("user.unBind")}</div>
              <span className={styles.line}>|</span>
              <div
                onClick={() => {
                  navigate(
                    `/u?${qs.stringify({
                      ...qs.parse(location.search),
                      user_active_tab: baseMenusText.CONNECTION,
                    })}`
                  );
                }}
                className={styles.bind}
              >
                {securityLevel.value?.email ? t("user.modifyImmidate") : t("user.bindImmediate")}
              </div>
            </div>
          }
        >
          {/* {securityLevel.value?.email ? (
            <UpdateEmail
              user={user!}
              visible={emailModalVisible}
              onCancel={() => setEmailModalVisible(false)}
              onOk={(u) => {
                setEmailModalVisible(false);
                updateUser(u);
              }}
            />
          ) : (
            <BindEmail
              visible={emailModalVisible}
              onCancel={() => setEmailModalVisible(false)}
              onOk={(u) => {
                setEmailModalVisible(false);
                updateUser(u);
              }}
            />
          )} */}
        </Item>
        <Item
          title={t("user.personalMfa")}
          description={t("user.personalMfaTips")}
          operation={
            <div className={styles.operation}>
              <i className={getIconClassnames(securityLevel.value?.mfa)}></i>
              <div>{securityLevel.value?.mfa ? t("user.binded") : t("user.unBind")}</div>
              <span className={styles.line}>|</span>
              <div
                onClick={() => {
                  navigate(
                    `/u?${qs.stringify({
                      ...qs.parse(location.search),
                      user_active_tab: baseMenusText.MFA,
                    })}`
                  );
                }}
                className={styles.bind}
              >
                {securityLevel.value?.mfa ? t("user.modifyImmidate") : t("user.bindImmediate")}
              </div>
            </div>
          }
        ></Item>
      </div>
    </Spin>
  );
};
