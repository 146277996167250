import { Steps } from "antd";
import React, { FC, useMemo, useState } from "react";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";
import styles from "./style.module.less";
import { useAsyncFn } from "react-use";
import { uploadCert } from "@/api/user";
import { getImgSrc } from "@/utils";

const { Step } = Steps;

interface IProcessingCertProps {
  onUpdate: (type: string) => void;
  enterpriseAuth: any;
  fetchAuthInfo: () => void;
}

export const ProcessingCert: FC<IProcessingCertProps> = ({ onUpdate, enterpriseAuth, fetchAuthInfo }) => {
  const [stepCurrent, setStepCurrent] = useState<number>(0);
  const [certImg, setCertImg] = useState<string>(getImgSrc("/langxin/yingye.png"));

  const [certMapData, uploadFile] = useAsyncFn(async (url: string) => {
    return await uploadCert({ url });
  });

  const stepCom = useMemo(() => {
    return [
      <StepOne
        updateStepCurrent={setStepCurrent}
        uploadFile={uploadFile}
        loading={certMapData.loading}
        onUpdate={onUpdate}
        certImg={certImg}
        setCertImg={setCertImg}
      />,
      <StepTwo
        updateStepCurrent={setStepCurrent}
        certMapData={certMapData.value}
        enterpriseAuth={enterpriseAuth}
        certImg={certImg}
      />,
      <StepThree onUpdate={onUpdate} fetchAuthInfo={fetchAuthInfo} />,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadFile, certMapData.loading, certMapData.value, enterpriseAuth]);

  return (
    <div className={styles.processingCertContainer}>
      <Steps current={stepCurrent} className={styles.stepWrap}>
        <Step title="上传营业执照" />
        <Step title="填写企业信息" />
        <Step title="提交企业认证" />
      </Steps>
      {stepCom[stepCurrent]}
    </div>
  );
};
