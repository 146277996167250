import { submitUserAuth } from "@/api/langxinApp";
import { walker } from "@/common/tree";
import { Cascader, Form, Input, message, Modal, Row } from "antd";
import _ from "lodash";
import React, { FC, useCallback } from "react";
import styles from "../style.module.less";

interface IApplyAuthModalProps {
  visible: boolean;
  setVisible: (bool: boolean) => void;
  selectTree: any;
  selectedDepart: any;
}

export const ApplyAuthModal: FC<IApplyAuthModalProps> = ({ visible, setVisible, selectTree, selectedDepart }) => {
  const [form] = Form.useForm();

  const onFilish = async () => {
    const formData = form.getFieldsValue();
    console.log(formData);
    const { deptName } = formData;
    const deptInfo = JSON.parse(deptName[deptName?.length - 1]) || {};
    await submitUserAuth({
      ...formData,
      orgId: selectedDepart.orgId,
      orgName: selectedDepart.name,
      deptId: deptInfo.id,
      deptName: deptInfo.name,
    });
    message.success("提交认证成功");
    setVisible(false);
  };

  const sleectedTree = useCallback(() => {
    return walker(_.cloneDeep(selectTree?.tree || []), (leaf: any) => {
      return {
        ...leaf,
        label: leaf.name,
        value: JSON.stringify(leaf),
        children: leaf.children,
        name: leaf.name,
      };
    });
  }, [selectTree]);

  return (
    <Modal
      title="申请认证"
      visible={visible}
      onOk={() => form.submit()}
      onCancel={() => setVisible(false)}
      className={styles.applyAuthModalContainer}
    >
      <Form
        form={form}
        labelAlign="left"
        labelCol={{
          span: 4,
        }}
        onFinish={onFilish}
      >
        <Row>
          <Form.Item label="选择部门" name="deptName" rules={[{ required: true }]}>
            <Cascader
              options={sleectedTree()}
              placeholder="请选择部门"
              style={{ width: "50%" }}
              onChange={(e) => {
                console.log(e);
              }}
              changeOnSelect
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label="员工姓名" name="realName" rules={[{ required: true, message: "请输入你的姓名" }]}>
            <Input placeholder="请输入你的姓名" style={{ width: "50%" }} />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label="联系方式" name="phone" rules={[{ required: true, message: "请输入手机号码" }]}>
            <Input placeholder="请输入手机号码" />
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
