import React, { forwardRef, ForwardRefRenderFunction, useEffect, useState } from 'react';
// 注意 FormInstance 这里的别引入错了，要用当前项目里的
import { FormInstance } from '../../../../hooks/createForm';
import { User } from 'authing-js-sdk';
import styles from './styles.module.less';
import { Spin } from 'antd';
import classnames from 'classnames';
import { getUserRegisterParams } from '../../../../utils';

// 表单参数
export interface QrCodeFormProps extends React.HTMLAttributes<HTMLDivElement> {
	onSuccess?: (user: User) => void;
	onFail?: (error: any) => void;
	type: 'wechat-miniprogram-qrcode' | 'app-qrcode';
}

const InternalQrCodeForm: ForwardRefRenderFunction<FormInstance, QrCodeFormProps> = (
	{ onSuccess, onFail, type },
	ref
) => {
	const [started, setStarted] = useState(false);
	const authenticationClient = window.__authing__;

	const client = type === 'wechat-miniprogram-qrcode' ? authenticationClient.wxqrcode : authenticationClient.qrcode;
	useEffect(() => {
		if (started) return;
		setStarted(true);

		const onScanningSuccess = async (userInfo: any, ticket: string) => {
			const { token } = userInfo;
			if (!token) {
				// 轮询接口不会返回完整用户信息，需要使用 ticket 换取
				userInfo = await client.exchangeUserInfo(ticket);
			}
			onSuccess && onSuccess(userInfo);
		};

		client.startScanning('qrcode', {
			// onCodeShow:
			onSuccess: onScanningSuccess,
			onError: message => onFail && onFail(`${message}`),
			customData: getUserRegisterParams()
		});
	}, [onFail, onSuccess, started, client]);

	return (
		<div className={classnames(styles.container, 'authing-qrcode-login-container')}>
			<div className={styles.loading}>
				<Spin size="large" />
			</div>
			<div id="qrcode"></div>
		</div>
	);
};

export const QrCodeForm = forwardRef<FormInstance, QrCodeFormProps>(InternalQrCodeForm);
