import React, { FC } from "react";
import { List, Tag } from "antd";
import styles from "./styles.module.less";

export interface RoleListProps extends React.HTMLAttributes<HTMLDivElement> {
  roles: {
    role: string;
    org: string;
    name: string;
  }[];
}

export const RoleList: FC<RoleListProps> = ({ roles }) => {
  return (
    <div className={styles.roleList}>
      <div className={styles.roleListTitle}>请选择角色登录</div>
      <List
        itemLayout="horizontal"
        dataSource={roles}
        renderItem={(item) => (
          <div className={styles.listItem}>
            <div className={styles.listItemTag}>
              <Tag color="#396AFF">{item.role}</Tag>
            </div>
            <div className={styles.listItemContent}>
              <h3 className={styles.listItemContentTitle}>{item.name}</h3>
              <span className={styles.listItemContentSubTitle}>{item.org}</span>
            </div>
            <div className={styles.listItemAction}>
              <i className="authing-icon authing-jinru" />
            </div>
          </div>
        )}
      />
    </div>
  );
};
