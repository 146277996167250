import React, { FC } from "react";
import { Container } from "../../../components/Container";
import { Header } from "../../../components/Header";
import { SizeBox } from "../../../components/SizeBox";
import { Form, Input, Button } from "antd";
import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import { RouterLink } from "../../../components/RouterLink";
import { isPhone, isEmail } from "../../../utils/index";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

export interface ResetPasswordStep1Props {
  loading?: boolean;
  onFinish?: (type: string, value: string) => void;
}

export const ResetPasswordStep1: FC<ResetPasswordStep1Props> = ({ loading = false, onFinish = () => {} }) => {
  const config = window.__config__;
  const { t } = useTranslation();

  return (
    <Container>
      <Header title={t("login.getBackPwd")} logo={config.logo} large />

      <Form
        onFinish={(values) => {
          const value = values.phoneOrEmail;
          if (isEmail(value)) {
            onFinish("email", value);
          } else if (isPhone(value)) {
            onFinish("phone", value);
          }
        }}
      >
        <Form.Item
          name="phoneOrEmail"
          validateTrigger="onBlur"
          rules={[
            { required: true, message: t("login.noEmpty") },
            {
              validator: async (rule: RuleObject, value: StoreValue) => {
                if (!value) {
                  return;
                }
                if (isEmail(value) || isPhone(value)) {
                  return;
                } else {
                  throw new Error(t("login.inputCorrectPhone"));
                }
              },
            },
          ]}
        >
          <Input size="large" placeholder={t("login.inputPhoneOrEmail")} />
        </Form.Item>
        <SizeBox height={16} />
        <Form.Item style={{ marginBottom: 8 }}>
          <Button
            block
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
            className="authing-reset-password"
          >
            {t("login.resetPwd")}
          </Button>
        </Form.Item>
      </Form>

      <RouterLink
        style={{
          textAlign: "right",
        }}
        className={classnames("authing-link-item-after-btn authing-use-other-accounts")}
        to="/"
      >
        {t("login.otherAccLogin")}
      </RouterLink>
    </Container>
  );
};
