import { nets, TinyFaceDetectorOptions } from "face-api.js";

// tiny_face_detector options
let inputSize = 512;
let scoreThreshold = 0.5;

export function getFaceDetectorOptions() {
  return new TinyFaceDetectorOptions({ inputSize, scoreThreshold });
}

export function getCurrentFaceDetectionNet() {
  return nets.tinyFaceDetector;
}

export function isFaceDetectionModelLoaded() {
  return !!getCurrentFaceDetectionNet().params;
}
