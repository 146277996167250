import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { Input, Form, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { FormInstance } from '../../../../hooks/createForm';
import { isPhone, isEmail, getCaptchaUrl, getUserRegisterParams } from '../../../../utils/index';
import { User } from 'authing-js-sdk';
import { isGuiding, sessionAccountInfo } from '../../../../components/Guidance/utils';
import { useTranslation } from 'react-i18next';
import { SU_ZHOU_YUAN_QU } from '@/pocConfig';

export interface PasswordLoginFormProps extends React.HTMLAttributes<HTMLDivElement> {
	onSubmit?: () => void;
	onSuccess?: (user: User) => void;
	onFail?: (error: any) => void;
	onFinishFailed?: (error: any) => void;
}

const InternalPasswordLoginForm: ForwardRefRenderFunction<FormInstance, PasswordLoginFormProps> = (
	{ onSubmit, onSuccess, onFail, onFinishFailed },
	ref
) => {
	const [rawForm] = Form.useForm();
	const config = window.__config__;
	const [needVerifyCode, setNeedVerifyCode] = useState(false);
	const [verifyCodeUrl, setVerifyCodeUrl] = useState<string | null>(null);
	const { t } = useTranslation();

	const formInitValue = isGuiding() ? sessionAccountInfo.get() : {};

	// if (!formInitValue && isGuiding()) {
	//   noviceEventBus.emit("exitNovice");
	// }

	const onFinish = async (values: any) => {
		try {
			const authenticationClient = window.__authing__;
			const identity = values.identity && values.identity.trim();
			const password = values.password && values.password.trim();
			const captchaCode = values.captchaCode && values.captchaCode.trim();
			const autoRegister = config.ssoPageComponentDisplay.autoRegisterThenLoginHintInfo;

			const user = isPhone(identity)
				? await authenticationClient.loginByPhonePassword(identity, password, {
						autoRegister,
						captchaCode,
						customData: getUserRegisterParams()
				  })
				: isEmail(identity)
				? await authenticationClient.loginByEmail(identity, password, {
						autoRegister,
						captchaCode,
						customData: getUserRegisterParams()
				  })
				: await authenticationClient.loginByUsername(identity, password, {
						autoRegister,
						captchaCode,
						customData: getUserRegisterParams()
				  });

			sessionAccountInfo.remove();
			onSuccess && onSuccess(user);
		} catch (error) {
			onFail && onFail(error);
			console.log(error.code);

			if (error.code === 2000 && verifyCodeUrl === null) {
				setNeedVerifyCode(true);
				setVerifyCodeUrl(getCaptchaUrl());
			}
		}
	};

	useImperativeHandle(ref, () => ({
		submit: async () => {
			try {
				onSubmit && onSubmit();
				await rawForm.validateFields();
				rawForm.submit();
			} catch (error) {
				onFail && onFail(error);
			}
		}
	}));

	return (
		<Form
			initialValues={formInitValue}
			form={rawForm}
			onSubmitCapture={onSubmit}
			onFinishFailed={onFinishFailed}
			onFinish={onFinish}
		>
			<button type="submit" hidden></button>

			{config.ssoPageComponentDisplay.userPasswordInput && (
				<>
					{config.ssoPageComponentDisplay.autoRegisterThenLoginHintInfo && (
						<Alert
							message={
								SU_ZHOU_YUAN_QU.appIds.includes(config.id)
									? SU_ZHOU_YUAN_QU.pwdLoginTabAlert
									: t('login.autoRegister')
							}
							style={{ marginBottom: 24 }}
						/>
					)}
					<Form.Item
						name="identity"
						rules={[
							{
								required: true,
								message: t('login.inputEmailUsernamePhone')
							}
						]}
					>
						<Input
							autoComplete="email,username,tel"
							size="large"
							placeholder={t('login.inputEmailUsernamePhone')}
							prefix={<UserOutlined style={{ color: '#ddd' }} />}
						/>
					</Form.Item>
					<Form.Item
						name="password"
						rules={[
							{
								required: true,
								message: t('login.inputLoginPwd')
							}
						]}
					>
						<Input.Password
							autoComplete="current-password"
							size="large"
							visibilityToggle={false}
							placeholder={t('login.inputLoginPwd')}
							prefix={<LockOutlined style={{ color: '#ddd' }} />}
						/>
					</Form.Item>
					{needVerifyCode && (
						<Form.Item
							name="captchaCode"
							rules={[
								{
									required: true,
									message: t('login.inputCaptchaCode')
								}
							]}
						>
							<Input
								autoComplete="one-time-code"
								size="large"
								placeholder={t('login.inputCaptchaCode')}
								addonAfter={
									<img
										src={verifyCodeUrl ?? ''}
										alt={t('login.captchaCode')}
										style={{ height: '2em', cursor: 'pointer' }}
										onClick={() => setVerifyCodeUrl(getCaptchaUrl())}
									/>
								}
							/>
						</Form.Item>
					)}
				</>
			)}
		</Form>
	);
};

export const PasswordLoginForm = forwardRef<FormInstance, PasswordLoginFormProps>(InternalPasswordLoginForm);
