import React, { FC, useState, useRef, useEffect } from 'react';
import { RouterLink } from '../../components/RouterLink';
import { Header } from '../../components/Header';
import { Tabs, Button, message, Dropdown, Menu } from 'antd';
import { Container } from '../../components/Container';
import styles from './styles.module.less';
import { createForm, FormInstance } from '../../hooks/createForm';
import { PhoneCodeLoginForm } from '../../components/forms/login/PhoneCodeLoginForm';
import { AuthingTabs } from '../../components/AuthingTabs/index';
import { SocialLogin } from '../../components/SocialLogin/index';
import { QrCodeForm } from '../../components/forms/login/QrCodeForm/index';
import { User } from 'authing-js-sdk';
import { getDisplayName } from '../../utils';
import { Protocol } from '../../config/index';
import querystring from 'query-string';
import { PasswordLoginForm } from '../../components/forms/login/PasswordLoginForm/index';
import { useTitle } from '../../hooks/useTitle';
import { useNavigateWithSearch } from '../../hooks/useNavigateWithSearch';
import { MFALoginForm, MFAErrorData } from '../../components/forms/login/MFALoginForm';
import classnames from 'classnames';
import { noviceEventBus, isRegister } from '../../components/Guidance/utils';
import { LdapLoginForm } from '../../components/forms/login/LdapLoginForm';
import { ADLoginForm } from '../../components/forms/login/ADLoginForm';
import { useTranslation } from 'react-i18next';
// import { ToggleLang } from '@/components/ToggleLang';
import { RegisteredByCompanies } from '@/components/RegisteredByCompanies';
import { ApplicationMfaType, ErrorCodes, getLoginTabsMap, ScreenSize } from '@/constants/enum';
import { DownOutlined } from '@ant-design/icons';
import { useGotoRegisterComplete } from '../Register/registerUser';
import { useScreenSize } from '@/hooks/useScreenSize';
import { i18n } from '@/locales';

const { TabPane } = Tabs;

const getDefaultTab = (tab: string, tabs: string[]) => {
	return tabs.find(t => t === tab) || tabs[0];
};

const MFA_LABEL = {
	[ApplicationMfaType.EMAIL]: i18n.t('common.emailVerification'),
	[ApplicationMfaType.SMS]: i18n.t('common.SMSVerification'),
	[ApplicationMfaType.OTP]: i18n.t('common.OTPVerification'),
	[ApplicationMfaType.FACE]: i18n.t('common.faceVerification')
};

const getQueryMFAToken = () => {
	const queryVal = querystring.parse(window.location.search).mfa_token;
	if (typeof queryVal === 'string') {
		return queryVal;
	}
	if (Array.isArray(queryVal)) {
		return queryVal.filter(item => item)[0] || '';
	}
	return '';
};

export const LoginPage: FC = () => {
	const { t } = useTranslation();

	useTitle(t('common.login'));

	type FormRef = React.RefObject<FormInstance>;

	const config = window.__config__;
	const initMFAToken = getQueryMFAToken();

	const formsRef = useRef<{ [key: string]: FormRef }>({});
	const [activeTabKey, setActiveTabKey] = useState('');
	const [loading, setLoading] = useState(false);
	const [fields, setFields] = useState<{ [key: string]: string }>({});
	const navigate = useNavigateWithSearch();

	const [isMFA, setIsMFA] = useState(Boolean(initMFAToken));
	const [MFAInfo, setMFAInfo] = useState<MFAErrorData>();
	const [mfaType, setMfaType] = useState(ApplicationMfaType.OTP);

	const [screenSize] = useScreenSize();

	const mfaFormRef = createForm();

	const [needComplete, gotoRegisterComplete] = useGotoRegisterComplete();

	const [formHidden, setFormHidden] = useState({
		action: '',
		token: ''
	});
	const submitBtn = useRef<any>();

	useEffect(() => {
		const query = querystring.parse(window.location.search);
		const path = query['goto'];
		if (path) {
			navigate(path as string);
		}
		const tab = query['tab'] as string;
		setActiveTabKey(getDefaultTab(tab || config.loginTabs.default, config.loginTabs.list));
	}, [config.loginTabs.default, config.loginTabs.list, navigate]);

	const submit = async () => {
		if (loading) return;
		// 提交表单
		if (isMFA) {
			mfaFormRef.current?.submit();
			return;
		}
		formsRef.current[activeTabKey]?.current?.submit();
	};

	const onSuccess = async (user: User) => {
		setLoading(false);

		if (!user) {
			return;
		}
		await window.__authing__.setCurrentUser(user);

		message.success(
			t('common.loginSuccessWelcome', {
				name: getDisplayName(user)
			})
		);

		// 登录注册时间相同，表示是自动注册的，进入补充注册信息)
		if (isRegister(user) && needComplete) {
			gotoRegisterComplete();
			return;
		}

		setTimeout(() => {
			// TODO: 这段逻辑与 src/utils/loginSuccess.tsx 的 loginSuccessRedirect 一致，谁敢替换一下
			if (config.protocol === Protocol.OIDC) {
				const query = querystring.parse(window.location.search);
				setFormHidden({
					action: `/interaction/oidc/${query['uuid']}/login`,
					token: user.token ?? ''
				});
				submitBtn.current.click();
				return;
			}

			if (config.protocol === Protocol.SAML) {
				setFormHidden({
					action: `/interaction/saml-idp/login`,
					token: user.token ?? ''
				});
				submitBtn.current.click();
				return;
			}

			if (config.protocol === Protocol.CAS) {
				setFormHidden({
					action: `/interaction/cas-idp/login`,
					token: user.token ?? ''
				});
				submitBtn.current.click();
				return;
			}

			if (config.protocol === Protocol.OAUTH) {
				setFormHidden({
					action: `/interaction/oauth/login`,
					token: user.token ?? ''
				});
				setFields(querystring.parse(window.location.search) as any);
				submitBtn.current.click();
				return;
			}

			// protocol=none 时，直接带着用户信息跳转过去
			let redirectUrl = `${config.redirectUris && config.redirectUris[0]}/?data=${encodeURIComponent(
				JSON.stringify(user)
			)}`;
			window.location.href = redirectUrl;
		}, 500);
	};

	const onFail = (error: any) => {
		setLoading(false);
		// 个人版 MFA
		if (error.code === ErrorCodes.OTP_MFA_CODE) {
			setIsMFA(true);
			setMfaType(ApplicationMfaType.OTP);
			setMFAInfo({ ...error.data, errorCode: error.code });
			return;
		}

		// 企业版 MFA
		if (error.code === ErrorCodes.MSG_MFA_CODE) {
			const mfaData = error.data as MFAErrorData;

			const orderedMsgMfa = mfaData?.applicationMfa?.filter(item => item.status)?.sort((a, b) => a.sort - b.sort);

			setIsMFA(true);
			setMFAInfo({
				...mfaData,
				applicationMfa: orderedMsgMfa,
				errorCode: error.code
			});

			setMfaType(orderedMsgMfa![0].mfaPolicy);

			return;
		}
		console.log('登录失败:\n', error);
	};

	const formFactories: { [key: string]: (ref: FormRef) => JSX.Element } = {
		password: ref => (
			<PasswordLoginForm ref={ref} onSubmit={() => setLoading(true)} onSuccess={onSuccess} onFail={onFail} />
		),
		ldap: ref => (
			<LdapLoginForm ref={ref} onSubmit={() => setLoading(true)} onSuccess={onSuccess} onFail={onFail} />
		),
		ad: ref => <ADLoginForm ref={ref} onSubmit={() => setLoading(true)} onSuccess={onSuccess} onFail={onFail} />,
		'phone-code': ref => (
			<PhoneCodeLoginForm ref={ref} onSubmit={() => setLoading(true)} onSuccess={onSuccess} onFail={onFail} />
		),
		'wechat-miniprogram-qrcode': ref => (
			<QrCodeForm ref={ref} onSuccess={onSuccess} onFail={onFail} type="wechat-miniprogram-qrcode" />
		),
		MFA: ref => (
			<MFALoginForm
				ref={ref}
				MFAInfo={MFAInfo}
				MFAType={mfaType}
				onSubmit={() => setLoading(true)}
				onSuccess={onSuccess}
				onFail={onFail}
			/>
		),
		'app-qrcode': ref => <QrCodeForm ref={ref} onSuccess={onSuccess} onFail={onFail} type="app-qrcode" />
	};

	const tabs = config.loginTabs.list
		// 过滤掉不支持的方式
		.filter(tab => Object.keys(formFactories).includes(tab));
	const tabPanes = tabs.map(tab => {
		const title = getLoginTabsMap(window.__config__)[tab];
		const ref = (formsRef.current[tab] = createForm());
		const form = formFactories[tab](ref);
		return (
			<TabPane tab={title} key={tab}>
				{form}
			</TabPane>
		);
	});

	const noForm = !config.ssoPageComponentDisplay.loginMethodNav || tabPanes.length === 0;
	const showGotoRegisterButton = config.ssoPageComponentDisplay.registerBtn && !['ad', 'ldap'].includes(activeTabKey);

	const loginElements = !noForm && activeTabKey !== 'wechat-miniprogram-qrcode' && activeTabKey !== 'app-qrcode' && (
		<>
			{config.ssoPageComponentDisplay.loginBtn && (
				<Button
					data-novice-focus="7"
					data-novice-action="7"
					loading={loading}
					className={classnames(styles.registerBtn, 'authing-login-btn')}
					block
					size="large"
					type="primary"
					onClick={submit}
				>
					{t('common.login')}
				</Button>
			)}

			{/* 桌面端样式 */}
			<div className={classnames(styles.gotoRegister, 'authing-goto-register-container', 'hide-in-mobile')}>
				{config.ssoPageComponentDisplay.forgetPasswordBtn && !['ad', 'ldap'].includes(activeTabKey) && (
					<RouterLink to="/reset-password" className="__authing-forget-password">
						{t('common.hasForgotPwd')}
					</RouterLink>
				)}
				{showGotoRegisterButton && (
					<span>
						{t('common.noAccYet')}
						<RouterLink
							onClick={() => noviceEventBus.emit('next')}
							data-novice-focus="5"
							data-novice-action="5"
							to="/register"
						>
							{t('common.registerImmediate')}
						</RouterLink>
					</span>
				)}
			</div>
			{config.enableSubAccount && (
				<div
					className="authing-guard-form-actions authing-sub-acc-login-btn hide-in-mobile"
					style={{
						marginTop: showGotoRegisterButton ? 20 : 0,
						textAlign: 'right',
						color: '#396aff',
						fontFamily: 'PingFangSC-Medium'
					}}
				>
					<div className="authing-guard-tip-btn-comb">
						<RouterLink to="/sub-account">{t('login.subAccLogin')}</RouterLink>
					</div>
				</div>
			)}

			{/* 移动端样式 */}
			<div className={classnames(styles.mobileLoginActions, 'hide-in-desktop', 'authing-mobile-login-actions')}>
				<RouterLink onClick={() => noviceEventBus.emit('next')} to="/register">
					{t('common.registerImmediate')}
				</RouterLink>

				{config.enableSubAccount && (
					<>
						<div className={classnames(styles.actionSplit, 'authing-login-action-split')}></div>
						<RouterLink to="/sub-account">{t('login.subAccLogin')}</RouterLink>
					</>
				)}
				<>
					<div className={classnames(styles.actionSplit, 'authing-login-action-split')}></div>
					<div
						className={classnames(styles.problem, 'authing-guard-feedback-btn')}
						onClick={() => {
							navigate('/feedback');
						}}
					>
						{t('login.feedback')}
					</div>
				</>
				{config.ssoPageComponentDisplay.forgetPasswordBtn && !['ad', 'ldap'].includes(activeTabKey) && (
					<>
						<div className={classnames(styles.actionSplit, 'authing-login-action-split')}></div>
						<RouterLink to="/reset-password" className="__authing-forget-password">
							{t('login.forgetPwd')}
						</RouterLink>
					</>
				)}
			</div>

			<div style={{ marginBottom: 26 }}></div>

			<div style={{ flex: 1 }}></div>
		</>
	);

	const selectMFAType = (
		<>
			{(MFAInfo?.applicationMfa?.length || 0) > 1 && (
				<div className={classnames(styles.otherVerify, 'authing-use-other-mfa-btn')}>
					<Dropdown
						overlay={
							<Menu>
								{MFAInfo?.applicationMfa
									?.filter(i => i.mfaPolicy !== mfaType)
									.map(item => (
										<Menu.Item onClick={() => setMfaType(item.mfaPolicy)} key={item.mfaPolicy}>
											{MFA_LABEL[item.mfaPolicy]}
										</Menu.Item>
									))}
							</Menu>
						}
					>
						<span
							style={{
								cursor: 'pointer',
								color: '#999999'
							}}
						>
							{t('login.otherVerifyWay')} <DownOutlined />
						</span>
					</Dropdown>
				</div>
			)}
		</>
	);

	const MFAOptElements = (
		<>
			<Button
				loading={loading}
				className={classnames(styles.mfaLoginBtn, styles.registerBtn, 'authing-mfa-login-btn')}
				block
				size="large"
				type="primary"
				onClick={submit}
			>
				{MFAInfo?.totpMfaEnabled ? t('common.sure') : t('common.TotpBindBtn')}
			</Button>

			{MFAInfo?.totpMfaEnabled && (
				<span className={classnames(styles.mfaForgetTips, 'authing-use-mfa-recover-code-btn')}>
					{t('common.hasLooseSaftyCode')}
					<RouterLink
						to={{
							pathname: '/reset-mfa?',
							search: querystring.stringify(
								Object.assign(querystring.parse(window.location.search), {
									mfa_token: MFAInfo?.mfaToken
								})
							)
						}}
						className="__authing-forget-password"
					>
						{t('common.useRecoverCode')}
					</RouterLink>
				</span>
			)}

			{selectMFAType}
		</>
	);

	const MFAMsgElements = (
		<>
			<Button
				loading={loading}
				className={classnames(styles.mfaLoginBtn, styles.registerBtn, 'authing-mfa-login-btn')}
				block
				size="large"
				type="primary"
				onClick={submit}
			>
				{t('common.sure')}
			</Button>

			{selectMFAType}
		</>
	);

	const MFAFaceElements = <>{selectMFAType}</>;

	const MFAElementMap = {
		[ApplicationMfaType.OTP]: MFAOptElements,
		[ApplicationMfaType.SMS]: MFAMsgElements,
		[ApplicationMfaType.EMAIL]: MFAMsgElements,
		[ApplicationMfaType.FACE]: MFAFaceElements
	};

	return (
		<Container minHeight={noForm ? 'auto' : undefined}>
			<Header title={config.name} logo={config.logo} />
			{config.ssoPageComponentDisplay.loginMethodNav && !isMFA && (
				<AuthingTabs centered activeKey={activeTabKey} onChange={setActiveTabKey}>
					{tabPanes}
				</AuthingTabs>
			)}
			{isMFA && formFactories.MFA(mfaFormRef)}
			{isMFA ? MFAElementMap[mfaType] : loginElements}
			{!isMFA && <SocialLogin noForm={noForm} onFail={onFail} onSuccess={onSuccess}></SocialLogin>}
			<form action={formHidden.action} method="post" style={{ display: 'none' }}>
				{Object.entries(fields).map(([k, v]) => (
					<input key={k} type="text" name={k} value={v} readOnly />
				))}
				<input type="text" name="token" value={formHidden.token} readOnly />
				<button ref={submitBtn} type="submit"></button>
			</form>

			<div className={classnames(styles.layoutFlex)}>
				{/* {screenSize !== ScreenSize.Mobile && <ToggleLang />} */}
				{screenSize !== ScreenSize.Mobile && <RegisteredByCompanies />}
				{isMFA && (
					<div
						className={classnames(styles.useAntherAcc, 'authing-use-other-accounts')}
						onClick={() => {
							setIsMFA(false);
						}}
						style={{
							textAlign: 'center'
						}}
					>
						{t('login.otherAccLogin')}
					</div>
				)}
				<div
					className={classnames(styles.problem, 'authing-guard-feedback-btn', 'hide-in-mobile')}
					onClick={() => {
						navigate('/feedback');
					}}
					style={{
						textAlign: 'right'
					}}
				>
					{t('common.quesitons')}
				</div>
			</div>
		</Container>
	);
};
