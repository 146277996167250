import { getImgSrc } from "@/utils";
import { Button, Space } from "antd";
import React, { FC } from "react";
import { certificationStatusMap } from "../../constants";
import styles from "./style.module.less";
interface IStepThreeProps {
  onUpdate: (type: string) => void;
  fetchAuthInfo: () => void;
}

export const StepThree: FC<IStepThreeProps> = ({ onUpdate, fetchAuthInfo }) => {
  return (
    <div className={styles.stepThreeContainer}>
      <img src={getImgSrc("/langxin/Lark20210514-114230.png")} alt="" className={styles.imgThree} />
      <p className={styles.tips}>恭喜你！已成功提交企业认证！</p>
      <div className={styles.btns}>
        <Space size="middle">
          <Button
            type="primary"
            style={{ width: 120 }}
            onClick={() => {
              onUpdate(certificationStatusMap.YES_CERTIFICATION);
              fetchAuthInfo();
            }}
          >
            确定
          </Button>
        </Space>
      </div>
    </div>
  );
};
