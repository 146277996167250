import React, { FC } from "react";
import { PasswordSecurityLevel, PASSWORD_SECURITY_LEVEL_CONFIG } from "@/constants/enum";

import styles from "./styles.module.less";
import { useTranslation } from "react-i18next";

export const PasswordLevel: FC<{
  level: PasswordSecurityLevel;
}> = ({ level }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.rankwarp}>
      <div className={styles.rank}>
        <div className={styles.label}>{t("common.weak")}</div>
        <div
          style={{
            background: PASSWORD_SECURITY_LEVEL_CONFIG[level].color,
          }}
          className={styles.rectangle}
        ></div>
      </div>
      <div className={styles.rank}>
        <div className={styles.label}>{t("common.middle")}</div>
        <div
          style={{
            background: level > PasswordSecurityLevel.LOW ? PASSWORD_SECURITY_LEVEL_CONFIG[level].color : "#dddddd",
          }}
          className={styles.rectangle}
        ></div>
      </div>
      <div className={styles.rank}>
        <div className={styles.label}>{t("common.strong")}</div>
        <div
          style={{
            background: level > PasswordSecurityLevel.MIDDLE ? PASSWORD_SECURITY_LEVEL_CONFIG[level].color : "#dddddd",
          }}
          className={styles.rectangle}
        ></div>
      </div>
    </div>
  );
};
