import React, { FC, useState, useRef, useEffect, Ref, CSSProperties } from "react";
import styles from "./styles.module.less";
import { useCounter } from "react-use";
import { useTranslation } from "react-i18next";

export interface SendCodeProps {
  beforeSend: () => Promise<boolean>;
  btnRef?: Ref<HTMLSpanElement>;
  style?: CSSProperties;
}

export const SendCode: FC<SendCodeProps> = ({ beforeSend, btnRef, style }) => {
  const [send, setSend] = useState(false);
  const [countDown, { get: getCountDown, dec: decCountDown, set: setCountDown }] = useCounter(60);
  const { t } = useTranslation();
  const [sending, setSending] = useState(false);

  const sendRef = useRef(send);
  sendRef.current = send;

  const timerRef = useRef<any>();

  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (sending) return;
    if (sendRef.current) return;
    setSending(true);

    let sendRes: any;
    try {
      sendRes = await beforeSend();
    } catch (e) {
      // do nothing
    } finally {
      setSending(false);
    }
    if (!sendRes) return;

    setSend(true);
    setCountDown(60);
    clearInterval(timerRef.current);

    timerRef.current = setInterval(() => {
      if (getCountDown() > 0) {
        decCountDown();
      } else {
        setSend(false);
        clearInterval(timerRef.current);
      }
    }, 1000);
  };

  const className = send ? `${styles.sendCodeBtn} ${styles.disabled}` : styles.sendCodeBtn;
  return (
    <span ref={btnRef} className={className} style={{ ...style }} onClick={onClick}>
      {send
        ? t("common.retryAfterTime", {
            time: countDown,
          })
        : t("common.sendVerifyCode")}
    </span>
  );
};
