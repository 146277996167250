import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.less";
import { FormModule } from "./FormModule";
import { Button, Form, Image, Input, message, Radio, Row, Space, Spin, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ICertMapData, uploadOSS } from "@/api/user";
import { submitEnterpriseAuth } from "@/api/langxinApp";

interface IStepTwoProps {
  updateStepCurrent: (curr: number) => void;
  certMapData: ICertMapData | undefined;
  enterpriseAuth: any;
  certImg: string;
}

export const StepTwo: FC<IStepTwoProps> = ({ updateStepCurrent, certMapData, enterpriseAuth, certImg }) => {
  const [infoForm] = Form.useForm();
  const [isIdentity, setIsIdentity] = useState<boolean>(true);
  const [imgsMap, setImgsMap] = useState({
    legalPersonCardPositive: "",
    legalPersonCardNegative: "",
    enterpriseAuthFile: "",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async () => {
    const data = { ...(certMapData || {}), ...infoForm.getFieldsValue() };
    data.legalPersonProveType = isIdentity ? 1 : 2;
    data.businessLicense = certImg;
    data.enterprisePrincipalEmail = "无";
    if (enterpriseAuth?.id) {
      data.id = enterpriseAuth.id;
    }

    const res = await submitEnterpriseAuth(data);
    res.success && updateStepCurrent(2);
  };

  useEffect(() => {
    infoForm.setFieldsValue(certMapData);
  }, [certMapData, infoForm]);

  const customRequest = async ({ file }: any, type: string) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    try {
      setLoading(true);
      const res = await uploadOSS(formData);
      if (res?.url) {
        infoForm.setFieldsValue({ [type]: res.url });
        setImgsMap({ ...imgsMap, [type]: res.url });
      } else {
        message.error("文件上传失败，请重试...");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.stepTwoContainer}>
      <Form
        onFinish={onFinish}
        form={infoForm}
        labelAlign="left"
        labelCol={{
          span: 5,
        }}
      >
        <FormModule title="企业信息">
          <Row>
            <Form.Item label="企业名称" name="enterpriseName">
              <Input placeholder="请输入企业名称" />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="信用代码" name="creditCode">
              <Input placeholder="请输入统一社会信用代码" />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="企业地址" name="registerAddr">
              <Input placeholder="请输入企业地址" />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="注册时间" name="registerTime">
              <Input placeholder="请输入企业注册时间" />
            </Form.Item>
          </Row>
        </FormModule>
        <FormModule title="法人信息">
          <Row>
            {/* <Col span={13}> */}
            <Form.Item label="姓名" name="legalPersonName">
              <Input placeholder="请输入法人姓名" style={{ width: "50%" }} />
            </Form.Item>
            {/* </Col> */}
          </Row>
          <Row>
            <Form.Item label="手机号" name="legalPersonPhone">
              <Input placeholder="请输入法人联系方式" />
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="身份证" name="legalPersonCardNum">
              <Input placeholder="请输入法人身份证号" />
            </Form.Item>
          </Row>
        </FormModule>
        <FormModule title="经营范围">
          <Row>
            <Form.Item name="businessScope">
              <Input.TextArea placeholder="请输入经营范围" rows={4} />
            </Form.Item>
          </Row>
        </FormModule>
        <FormModule title="常用联系人信息">
          <Row>
            {/* <Col span={13}> */}
            <Form.Item label="姓名" name="enterprisePrincipal">
              <Input placeholder="请输入联系人姓名" style={{ width: "50%" }} />
            </Form.Item>
            {/* </Col> */}
          </Row>
          <Row>
            <Form.Item label="手机号" name="enterprisePrincipalMobile">
              <Input placeholder="请输入联系方式" />
            </Form.Item>
          </Row>
        </FormModule>
        <FormModule title="上传认证材料">
          <Radio.Group
            onChange={(e) => {
              setIsIdentity(e.target.value);
            }}
            value={isIdentity}
            style={{ marginBottom: "16px" }}
          >
            <Radio value={true}>身份证</Radio>
            <Radio value={false}>企业委托书</Radio>
          </Radio.Group>
          <Spin spinning={loading}>
            {isIdentity ? (
              <>
                <div className={styles.imgsWrap}>
                  <Space>
                    {imgsMap.legalPersonCardPositive && (
                      <Image src={imgsMap.legalPersonCardPositive} className={styles.img} width={104} height={104} />
                    )}
                    <Form.Item
                      name="legalPersonCardPositive"
                      rules={[{ required: true, message: "请上传身份证正面图片" }]}
                    >
                      <Upload
                        name="logo"
                        listType="picture"
                        customRequest={(e) => customRequest(e, "legalPersonCardPositive")}
                        showUploadList={false}
                      >
                        <div className={styles.uploadWrap}>
                          <PlusOutlined className={styles.uploadIcon} />
                          <span className={styles.tipsImg}>
                            {imgsMap.legalPersonCardPositive ? "点击更换" : "上传正面"}
                          </span>
                        </div>
                      </Upload>
                    </Form.Item>
                  </Space>
                </div>
                <div className={styles.imgsWrap}>
                  <Space>
                    {imgsMap.legalPersonCardNegative && (
                      <Image src={imgsMap.legalPersonCardNegative} className={styles.img} width={104} height={104} />
                    )}
                    <Form.Item
                      rules={[{ required: true, message: "请上传身份证背面图片" }]}
                      name="legalPersonCardNegative"
                    >
                      <Upload
                        name="legalPersonCardNegative"
                        listType="picture"
                        customRequest={(e) => customRequest(e, "legalPersonCardNegative")}
                        showUploadList={false}
                      >
                        <div className={styles.uploadWrap}>
                          <PlusOutlined className={styles.uploadIcon} />
                          <span className={styles.tipsImg}>
                            {imgsMap.legalPersonCardNegative ? "点击更换" : "上传背面"}
                          </span>
                        </div>
                      </Upload>
                    </Form.Item>
                  </Space>
                </div>
              </>
            ) : (
              <div className={styles.imgsWrap}>
                <Space>
                  {imgsMap.enterpriseAuthFile && (
                    <Image src={imgsMap.enterpriseAuthFile} className={styles.img} width={104} height={104} />
                  )}
                  <Form.Item rules={[{ required: true, message: "请上传企业授权书图片" }]} name="enterpriseAuthFile">
                    <Upload
                      name="enterpriseAuthFile"
                      listType="picture"
                      customRequest={(e) => customRequest(e, "enterpriseAuthFile")}
                      showUploadList={false}
                    >
                      <div className={styles.uploadWrap}>
                        <PlusOutlined className={styles.uploadIcon} />
                        <span className={styles.tipsImg}>{imgsMap.enterpriseAuthFile ? "点击更换" : "上传图片"}</span>
                      </div>
                    </Upload>
                  </Form.Item>
                </Space>
              </div>
            )}
          </Spin>
        </FormModule>
        <div className={styles.btns}>
          <Button
            onClick={() => {
              updateStepCurrent(0);
            }}
          >
            上一步
          </Button>
          <Button type="primary" htmlType="submit" style={{ width: 120, marginLeft: "16px" }}>
            完成
          </Button>
        </div>
      </Form>
    </div>
  );
};
