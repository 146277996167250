import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.less";
import { Card } from "../Card";
// import { AddModal } from "./AddModal";
// import { Dropdown } from "antd";
import { useEffectOnce } from "react-use";
import { customNavList, delCustomApp, getAllCanAddApp } from "@/api/user";
import { Protocol } from "@/config";
import { isHost, isIp, lazyLoading } from "@/utils";
import { AddModalWrapper } from "./AddModalWrapper";
import { ListItem } from "./Item";

interface ICustomNavProps {}

export const CustomNavCard: FC<ICustomNavProps> = (props) => {
  const [allloading, setAllLoading] = useState<boolean>(false);
  const [addAppVisible, setAddAppVisible] = useState<boolean>(false);
  const [allList, setAllList] = useState<any[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [customLoading, setCustomLoading] = useState<boolean>(false);
  const [customList, setcustomList] = useState<any[]>([]);

  const getAllList = async (search: string = "") => {
    lazyLoading(async () => {
      const res = await getAllCanAddApp(search);
      setAllList([...res]);
    }, setAllLoading);
  };

  const getList = async () => {
    lazyLoading(async () => {
      const res = await customNavList();
      setcustomList(res);
    }, setCustomLoading);
  };

  const repleaceSecondLevelDomain = (target: string, source: string) => {
    console.log(allloading);
    if (!isIp(target) && isHost(target)) {
      const arr = target.split(".");
      arr[0] = source;
      return arr.join(".");
    }
    return target;
  };

  useEffectOnce(() => {
    getList();
  });

  // 占位 dom
  const createDivElement = () => {
    const divDom: HTMLElement = document.createElement("div");
    divDom.style.width = "96px";
    divDom.style.visibility = "hidden";
    return divDom;
  };

  useEffect(() => {
    const container = document.getElementById("custom-app-list");
    if (customList?.length > 0) {
      if (customList.length % 4 === 2) {
        container?.appendChild(createDivElement());
        container?.appendChild(createDivElement());
      }
      if (customList.length % 4 === 3) {
        container?.appendChild(createDivElement());
      }
    }
  }, [customList]);

  return (
    <Card
      title={"自定义导航"}
      style={{
        width: 484,
        height: 270,
      }}
      loading={customLoading}
      topRightComponent={
        // <Dropdown
        //   overlay={
        //     <AddModal loading={allloading} appList={allList || []} onSearch={getAllList} getCustomList={getList} />
        //   }
        //   placement="bottomLeft"
        // >
        //   <span className={styles.addNav}>
        //     <LangxinIconFont type="langxin-iconadd-circle-line" />
        //     <span className={styles.addText}>添加</span>
        //   </span>
        // </Dropdown>

        isEdit ? (
          <span className={styles.addNav}>
            <span className={styles.addText} onClick={() => setAddAppVisible(true)}>
              添加
            </span>
            <div className={styles.line}></div>
            <span className={styles.addText} onClick={() => setIsEdit(false)}>
              保存
            </span>
          </span>
        ) : (
          <span className={styles.editText} onClick={() => setIsEdit(true)}>
            编辑
          </span>
        )
      }
      className={styles.customNavCardWrap}
    >
      <div className={styles.list} id="custom-app-list">
        {customList &&
          customList.map(({ id, appLogo, appName, identifier, protocol }) => (
            <ListItem
              title={appName}
              img={appLogo}
              key={id}
              isEdit={isEdit}
              delHandle={async () => {
                await delCustomApp(id);
                getAllList();
                getList();
              }}
              onClick={() => {
                if (protocol === Protocol.SAML) {
                  window.open(
                    `${window.location.protocol}//` +
                      `${repleaceSecondLevelDomain(window.location.host, identifier)}` +
                      `/api/v2/saml-idp/${id}`
                  );
                } else {
                  // 跳转到此应用的域名下
                  window.open(
                    `${window.location.protocol}//` +
                      `${repleaceSecondLevelDomain(window.location.host, identifier)}` +
                      `/login?app_id=${id}`
                  );
                }
              }}
            />
          ))}
      </div>
      <AddModalWrapper
        visible={addAppVisible}
        updateVisible={setAddAppVisible}
        onSearch={getAllList}
        appList={allList}
        getCustomList={getList}
      />
    </Card>
  );
};
