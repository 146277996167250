import React, { FC } from "react";
import styles from "./style.module.less";
import { CloseCircleFilled } from "@ant-design/icons";
import { message } from "antd";

interface IListItemProps {
  title: string;
  img: string;
  delHandle: () => any;
  onClick: () => void;
  isEdit: boolean;
}

export const ListItem: FC<IListItemProps> = ({ title, img, delHandle, onClick = () => {}, isEdit }) => {
  return (
    <div className={styles.listItemContainer} onClick={onClick}>
      <div className={styles.logoWrap}>
        <img src={img} alt="" className={styles.logo} />
        {isEdit && (
          <CloseCircleFilled
            className={styles.delIcon}
            onClick={async (e) => {
              e.stopPropagation();
              await delHandle();
              message.success("删除成功");
            }}
          />
        )}
      </div>
      <div className={styles.appTit}>{title}</div>
    </div>
  );
};
