import { RegisterInfo } from "@/types/user";
import { getOS, getDisplayName } from "@/utils";
import { User, RegisterProfile } from "authing-js-sdk";
import { message } from "antd";
import { RegisterInfoSessionKey } from "@/constants/enum";
import { useNavigateWithSearch } from "@/hooks/useNavigateWithSearch";
import { getUserRegisterParams } from "../../utils";

export const useGotoRegisterComplete = () => {
  const navigate = useNavigateWithSearch();
  const needComplete = window.__config__.extendsFieldsEnabled && window.__config__.extendsFields.length > 0;

  return [
    needComplete,
    (registerInfo?: RegisterInfo) => {
      if (needComplete) {
        if (registerInfo) {
          sessionStorage.setItem(RegisterInfoSessionKey, JSON.stringify(registerInfo));
        }
        navigate("/register-complete");
      }
      return needComplete;
    },
  ] as const;
};

export const registerUser = async (
  registerInfo: RegisterInfo,
  {
    onSuccess,
    onFail,
    completeUserInfo,
    completeUdfsInfo,
  }: {
    onSuccess?: (user: User) => void;
    onFail?: (err: any) => void;
    /** 注册信息补全用户主字段 */
    completeUserInfo?: RegisterProfile;
    /** 注册信息补全用户自定义字段 */
    completeUdfsInfo?: Array<{
      key: string;
      value: any;
    }>;
  } = {}
) => {
  const authenticationClient = window.__authing__;
  const { phone, code, password, email } = registerInfo;

  const device = getOS();
  const browser = navigator.userAgent;

  let user: User;

  const customData = getUserRegisterParams() || {};
  if (completeUdfsInfo?.length) {
    completeUdfsInfo.forEach(({ key, value }) => {
      customData[key] = value;
    });
  }

  try {
    if (phone) {
      /** 有手机号，用手机号注册 */
      user = await authenticationClient.registerByPhoneCode(
        phone,
        code!,
        password,
        {
          browser,
          device,
          ...completeUserInfo,
        },
        {
          generateToken: true,
          customData,
        }
      );
    } else if (email) {
      /** 有邮箱，用邮箱注册 */
      user = await authenticationClient.registerByEmail(
        email,
        password!,
        {
          browser,
          device,
          ...completeUserInfo,
        },
        {
          generateToken: true,
          customData,
        }
      );
    }

    onSuccess?.(user!);
    message.success("注册成功，" + getDisplayName(user!));
  } catch (e) {
    onFail?.(e);
  }

  return user!;
};
