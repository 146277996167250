import React, { FC, useMemo } from "react";
import styles from "./styles.module.less";
import classnames from "classnames";
import { ACCOUNT_SCORE_LEVEL_MAP } from "@/constants/enum";

const getAccountSecurityLevelColor = (score: number = 60) => {
  return ACCOUNT_SCORE_LEVEL_MAP.find((item) => {
    const {
      range: [left, right],
    } = item;
    return score >= left && score <= right;
  })?.color;
};

export const Dashboard: FC<{
  score: number;
}> = ({ score = 0 }) => {
  // 分数为 0 时旋转角度
  const minDeg = -188;
  // 分数为 100 时旋转角度
  const maxDeg = 11;

  const rotateDeg = useMemo(() => {
    return ((maxDeg - minDeg) / 100) * score + minDeg;
  }, [minDeg, score]);

  const rotateStyles: React.CSSProperties = {
    transform: `rotate(${rotateDeg}deg)`,
  };

  const color = useMemo(() => {
    return getAccountSecurityLevelColor(score);
  }, [score]);

  return (
    <div
      style={{
        width: 220,
        height: 122,
        paddingTop: 2,
        overflow: "hidden",
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.arcBox}>
          <div
            className={styles.trackArc}
            style={{
              borderColor: color,
            }}
          >
            <div className={styles.label0}>0</div>
            <div className={styles.label25}>25</div>
            <div className={styles.label50}>50</div>
            <div className={styles.label75}>75</div>
            <div className={styles.label100}>100</div>
            <div
              style={{
                color: color,
              }}
              className={styles.text}
            >
              {score}
            </div>
          </div>
        </div>
        <div
          className={styles.roundBox}
          style={{
            ...rotateStyles,
            transform: `rotate(${Math.min(rotateDeg, -12)}deg)`, // 大于 12 度的要由另外一个去补充，不然会出现空白
          }}
        >
          <div
            className={classnames(styles.round, {
              [styles.noLeftTopBorder]: rotateDeg < -167,
            })}
            style={{
              borderColor: color,
            }}
          ></div>
        </div>
        <div
          className={classnames(styles.roundBox)}
          style={{
            ...rotateStyles,
          }}
        >
          <div
            className={classnames(styles.round, {
              [styles.noLeftTopBorder]: rotateDeg < -167,
            })}
            style={{
              borderColor: color,
            }}
          ></div>
        </div>
        <div
          className={styles.dotRbox}
          style={{
            ...rotateStyles,
          }}
        >
          <div
            style={{
              backgroundColor: color,
            }}
            className={styles.dotR}
          ></div>
        </div>
        <div className={styles.roundDefualtBox}>
          <div className={styles.roundDefault}></div>
        </div>
      </div>
    </div>
  );
};
