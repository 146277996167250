import { CapturePhoto } from "@/components/CapturePhoto";
import { Container } from "@/components/Container";
import { Header } from "@/components/Header";
import { SendCode } from "@/components/SendCode";
import { VerifyCodeInput } from "@/components/VerifyCodeInput";
import { ApplicationMfaType } from "@/constants/enum";
import { Button, Dropdown, Form, Menu, message as Message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { EmailScene, User } from "authing-js-sdk";
import Axios from "axios";
import classnames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.less";
import { DownOutlined } from "@ant-design/icons";
import { useAsyncFn } from "react-use";
import { Rule } from "antd/lib/form";
import { getDisplayName } from "@/utils";
import { loginSuccessRedirect } from "@/utils/loginSuccess";

enum AssociateCode {
  /** 用户未上传人脸照片 */
  NoFacePhoto = 1700,
  /** 人脸对比时，未上传需要对比的图片 */
  ComparePhotoRequired = 1701,
  /** 人脸校验错误 */
  ComparePhotoError = 1702,
  /** 未绑定邮箱或手机号，无法绑定人脸 */
  PhotoNeedEmailOrPhone = 1703,
  /** 绑定人脸时，手机验证失败 */
  PhotoPhoneVerifyFail = 1704,
  /** 绑定人脸时，邮箱验证失败 */
  PhotoEmailVerifyFail = 1705,
  /** 人脸已经绑定过 */
  FaceBound = 1706,
  /** 绑定人脸失败，具体原因未知 */
  FaceBoundError = 1707,
}
export const BindFace = () => {
  const config = window.__config__;
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);

  const [message, setMessage] = useState("");

  const [isCheck, setIsCheck] = useState(false);

  const MFAToken = new URL(window.location.href).searchParams.get("mfa_token");
  const phone = new URL(window.location.href).searchParams.get("phone");
  const email = new URL(window.location.href).searchParams.get("email");

  const [sentStatus, setSentStatus] = useState({
    [ApplicationMfaType.EMAIL]: false,
    [ApplicationMfaType.SMS]: false,
  });
  const [sending, setSending] = useState(false);

  const [type, setType] = useState<ApplicationMfaType | "">("");

  const [MFACode, setMFACode] = useState(new Array(4).fill(""));
  const [tip, setTip] = useState<string>();

  const [refForm] = useForm();

  const associateParameter = {
    photoA: "",
    photoB: "",
  };

  const onSeize = async (blob: Blob) => {
    const formData = new FormData();
    formData.append("folder", "face-photo");
    formData.append("file", blob, "personal.jpeg");

    setMessage("");
    setTip("Upload...");

    const {
      data: { key: photoUrl },
    } = await Axios.post("/api/v2/upload?folder=face-photo&private=true", formData);

    if (associateParameter.photoA === "") {
      associateParameter.photoA = photoUrl;
      setTip(t("common.uploadSueecss"));
      await new Promise((resolve) => {
        setTimeout(() => {
          setIsCheck(true);
          setTip("Loading...");
          resolve();
        }, 3000);
      });

      return true;
    } else {
      associateParameter.photoB = photoUrl;
      const { code, message, data: user } = await Axios.post("/api/v2/mfa/face/associate", associateParameter, {
        headers: {
          authorization: MFAToken,
        },
      });

      switch (code) {
        case 200:
          if (user) {
            bindSuccess(user);
          } else {
            setCurrentStep(1);
          }

          return false;
        case AssociateCode.ComparePhotoError:
        case AssociateCode.ComparePhotoRequired:
        case AssociateCode.NoFacePhoto:
          await new Promise((resolve) => {
            setTimeout(() => {
              setIsCheck(false);
              setMessage("识别核对不正确，请您重新核对");
              associateParameter.photoA = "";
              associateParameter.photoB = "";
              resolve();
            }, 3000);
          });
          return true;
        default:
          await new Promise((resolve) => {
            setTimeout(() => {
              setMessage(message);
              associateParameter.photoA = "";
              associateParameter.photoB = "";
              resolve();
            }, 3000);
          });
      }
      return false;
    }
  };

  const capturePhotoTip = useMemo(() => {
    if (isCheck) {
      return {
        title: t("common.bindFaceTitle-2"),
        tip: t("common.bindFaceTips"),
      };
    }
    return {
      title: t("common.bindFaceTitle"),
      tip: t("common.bindFaceTips"),
    };
  }, [isCheck, t]);

  const availableType = useMemo(() => {
    const data: { label: string; key: ApplicationMfaType }[] = [];
    if (phone) {
      data.push({
        label: t("短信验证码验证"),
        key: ApplicationMfaType.SMS,
      });
    }

    if (email) {
      data.push({
        label: t("电子邮箱验证"),
        key: ApplicationMfaType.EMAIL,
      });
    }

    return data;
  }, [email, phone, t]);

  const titleAndTip = useMemo(() => {
    if (type === ApplicationMfaType.SMS) {
      if (!sentStatus[ApplicationMfaType.SMS]) {
        return {
          title: t("login.inputPhoneCode"),
          tips: t("login.clickSent"),
        };
      }
      return {
        title: t("login.inputPhoneCode"),
        tips: sending ? t("login.sendingVerifyCode") : `${t("login.verifyCodeSended")} ${phone}`,
      };
    }
    if (type === ApplicationMfaType.EMAIL) {
      if (!sentStatus[ApplicationMfaType.EMAIL]) {
        return {
          title: t("login.inputEmailCode"),
          tips: t("login.clickSent"),
        };
      }
      return {
        title: t("login.inputEmailCode"),
        tips: sending ? t("login.sendingVerifyCode") : `${t("login.verifyCodeSended")} ${email}`,
      };
    }
  }, [email, phone, sending, sentStatus, t, type]);

  const sendSmsVerifyCode = async () => {
    try {
      setSending(true);

      setSentStatus((prev) => ({
        ...prev,
        [type!]: true,
      }));

      await window.__authing__.sendSmsCode(phone!);
      return true;
    } catch (e) {
      return false;
    } finally {
      setSending(false);
    }
  };

  const sendEmailVerifyCode = async () => {
    try {
      setSending(true);

      setSentStatus((prev) => ({
        ...prev,
        [type!]: true,
      }));
      await window.__authing__.sendEmail(email!, EmailScene.MfaVerify);
      return true;
    } catch (e) {
      return false;
    } finally {
      setSending(false);
    }
  };

  const [finish, onFinish] = useAsyncFn(async () => {
    try {
      await refForm.validateFields();
    } catch (error) {
      return;
    }

    const axiosProps: Record<string, { url: string; prop: any }> = {
      [ApplicationMfaType.SMS]: {
        url: "/api/v2/mfa/face/phone-verify",
        prop: {
          code: MFACode.join(""),
          phone: phone,
        },
      },
      [ApplicationMfaType.EMAIL]: {
        url: "/api/v2/mfa/face/email-verify",
        prop: {
          code: MFACode.join(""),
          email: email,
        },
      },
    };

    const { code, message, data: user } = await Axios.post(axiosProps[type]?.url, axiosProps[type]?.prop, {
      headers: {
        Authorization: MFAToken,
      },
    });

    switch (code) {
      case 200:
        bindSuccess(user);
        break;
      case AssociateCode.PhotoEmailVerifyFail:
      case AssociateCode.PhotoPhoneVerifyFail:
        Message.error(t("common.verifyCodeError"));
        break;
      default:
        Message.error(message);
        break;
    }
  }, [refForm, type, MFACode]);

  //   const onFinish = async () => {};
  const rules: Rule[] = [
    {
      validateTrigger: [],
      validator(r, v, cb) {
        if (MFACode.some((item) => !item)) {
          cb(t("login.inputFullMfaCode"));
          return;
        }
        cb();
      },
    },
  ];

  const bindSuccess = async (user: User) => {
    await window.__authing__.setCurrentUser(user);

    Message.success(
      t("common.bindFaceSuccess", {
        name: getDisplayName(user),
      })
    );

    setTimeout(() => {
      loginSuccessRedirect(user);
    }, 500);
  };

  const steps: Record<number, { component: JSX.Element }> = {
    0: {
      component: (
        <CapturePhoto
          onSeize={onSeize}
          headerNode={
            <>
              <h2 className={styles.title}>{capturePhotoTip.title}</h2>
              <p className={styles.tips}>{capturePhotoTip.tip}</p>
            </>
          }
          errorMes={message}
          tip={tip}
        />
      ),
    },
    1: {
      component: (
        <>
          <h3 className={styles.title}>{titleAndTip?.title}</h3>
          <p className={styles.tips}>{titleAndTip?.tips}</p>

          <Form form={refForm} onFinish={onFinish}>
            <Form.Item
              style={{
                textAlign: "center",
              }}
              name="mfaCode"
              rules={rules}
            >
              <VerifyCodeInput
                size="40px"
                gutter="18px"
                verifyCode={MFACode}
                setVerifyCode={setMFACode}
                onEenter={onFinish}
                length={4}
              />
            </Form.Item>
          </Form>

          <div
            style={{
              textAlign: "center",
            }}
          >
            <SendCode
              style={{
                display: type === ApplicationMfaType.SMS && phone ? "initial" : "none",
              }}
              beforeSend={sendSmsVerifyCode}
            />
            <SendCode
              style={{
                display: type === ApplicationMfaType.EMAIL && email ? "initial" : "none",
              }}
              beforeSend={sendEmailVerifyCode}
            />
          </div>

          <Button
            loading={finish.loading}
            className={"authing-mfa-login-btn"}
            style={{
              marginTop: 87,
            }}
            block
            size="large"
            type="primary"
            onClick={onFinish}
          >
            {t("common.sure")}
          </Button>

          {availableType.length > 1 && (
            <div className={classnames(styles.otherVerify, "authing-use-other-mfa-btn")}>
              <Dropdown
                overlay={
                  <Menu>
                    {availableType.map((item) => (
                      <Menu.Item
                        onClick={() => {
                          setMFACode(new Array(4).fill(""));
                          setType(item.key);
                          refForm.resetFields();
                        }}
                        key={item.key}
                      >
                        {item.label}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <span
                  style={{
                    cursor: "pointer",
                    color: "#999999",
                  }}
                >
                  {t("login.otherVerifyWay")}
                  <DownOutlined />
                </span>
              </Dropdown>
            </div>
          )}
        </>
      ),
    },
  };

  useEffect(() => {
    phone ? setType(ApplicationMfaType.SMS) : setType(ApplicationMfaType.EMAIL);
  }, [phone]);

  return (
    <Container>
      {/* <span
        onClick={() => {
          window.history.back();
        }}
      >
        {t("common.goBack")}
      </span> */}
      <Header title={config.name} logo={config.logo} />
      {steps[currentStep].component}
    </Container>
  );
};
