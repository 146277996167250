import { formatTime } from "@/utils/formRules";
import { Table } from "antd";
import React, { FC, useMemo } from "react";

interface ILogsTableProps {
  listData: {
    page: number;
    count: number;
    list: any[];
    pageSize: number;
  },
  getList: (page: number) => void;
}

export const LogsTable: FC<ILogsTableProps> = ({getList, listData}) => {

  const columns = useMemo(() => {
    return [
      {
        title: "应用名",
        dataIndex: "appName",
        key: "appName",
      },
      {
        title: "操作类型",
        dataIndex: "operationDesc",
        key: "operationDesc",
      },
      {
        title: "操作时间",
        dataIndex: "timestamp",
        key: "timestamp",
        render: (timestamp: string) => formatTime(timestamp, 2),
      },
    ];
  }, []);

  return (
    <Table
      columns={columns}
      dataSource={listData.list ?? []}
      rowKey={(_record, index) => `${index}`}
      pagination={{
        pageSize: listData.pageSize,
        current: listData.page,
        total: listData.count,
        onChange: (page) => {
          getList(page);
        },
      }}
    />
  );
};
