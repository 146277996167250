import React from "react";
import styles from "./styles.module.less";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { RouterLink } from "@/components/RouterLink";
import { useTranslation } from "react-i18next";

export const FeedbackSuccessPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.feedbackSuccessPage}>
      <CheckCircleOutlined
        style={{
          fontSize: 62,
          color: "#44D7B6",
        }}
      />
      <h3
        style={{
          margin: "16px 0 24px 0",
          textAlign: "center",
          fontSize: 18,
          color: "#333",
        }}
      >
        {t("common.problem.successTip")}
      </h3>
      <p
        style={{
          fontSize: 14,
          color: "#666666",
          textAlign: "center",
          maxWidth: 285,
        }}
      >
        {t("common.problem.successTipMsg")}
      </p>

      <RouterLink
        to="/login"
        style={{
          marginTop: 110,
          alignSelf: "stretch",
        }}
      >
        <Button size="large" type="primary" block>
          {t("common.backLoginPage")}
        </Button>
      </RouterLink>
    </div>
  );
};
