import React, { FC } from "react";
import { App } from "./App";
import { getImgSrc } from "./utils";
import { ToggleLang } from "@/components/ToggleLang";
import { useTranslation } from "react-i18next";
import { Row } from "antd";

import "./main.styles.less";
import { Beianhao } from "./components/Beianhao";
export enum ItemType {
  "Link" = "Link",
  "ReactNode" = "ReactNode",
  "Tag_Host" = "Tag_Host",
  "Tag_Text" = "Tag_Text",
}
interface Item {
  type: ItemType;
  name: string;
  path?: string;
  icon?: string;
  component?: React.ReactNode;
}
interface HeaderItem extends Item {}

interface FooterItem extends Item {}

type FooterType = FooterItem[][];

interface MainLayoutBannerProps {
  src: string;
}
interface MainLayoutHeaderProps {
  dataSource?: HeaderItem[];
}
interface MainLayoutFooterProps {
  dataSource?: FooterType;
}

const MainLayoutBanner: FC<MainLayoutBannerProps> = (props) => {
  return (
    <div className="main-layout-banner">
      <img src={props.src} alt="" />
    </div>
  );
};

const MainLayoutHeader: FC<MainLayoutHeaderProps> = (props) => {
  const { dataSource } = props;
  return (
    <div className="main-layout-header">
      {dataSource &&
        dataSource.map((item, idx) => {
          return (
            <div className="main-layout-header-item" key={`main-layout-header-item-${item.type}-${idx}`}>
              {item.type === "Link" && <a href={item.path ? item.path : "/"}>{item.name}</a>}
              {(item.type === "ReactNode" || item.type === "Tag_Host") && item.component}
              {item.type === "Tag_Text" && item.name}
            </div>
          );
        })}
    </div>
  );
};

const MainLayoutFooter: FC<MainLayoutFooterProps> = (props) => {
  const { dataSource } = props;
  return (
    <div className="main-layout-footer">
      <div className="main-layout-footer_contaioner">
        <div className="main-layout-footer_row">
          {dataSource &&
            dataSource.map((column, columnIdx) => {
              return (
                <div key={`${columnIdx}`} className="item">
                  {column.map((item, idx) => {
                    return (
                      <span className="main-layout-footer-item" key={`main-layout-footer-item-${columnIdx}-${idx}`}>
                        {item.type === "Link" && (
                          <a href={item.path ? item.path : "/"} style={{ display: "inline-block" }}>
                            {item.name}
                          </a>
                        )}
                        {(item.type === "ReactNode" || item.type === "Tag_Host") && item.component}
                        {item.type === "Tag_Text" && item.name}
                      </span>
                    );
                  })}
                </div>
              );
            })}
        </div>
        <Row>
          <Beianhao />
        </Row>
      </div>
    </div>
  );
};

export const MainLayout = (props: any) => {
  const { t } = useTranslation();

  // header
  const _dataSourceHeader: HeaderItem[] = [
    // { type: ItemType["Link"], name: t("common.shouye"), path: "/" },
    // {
    //   type: ItemType.ReactNode,
    //   component: (
    //     <Dropdown
    //       overlay={
    //         <Menu>
    //           {[
    //             { type: ItemType["Link"], name: t("common.newsIndex"), path: "/" },
    //             { type: ItemType["Link"], name: t("common.banshi"), path: "/" },
    //             { type: ItemType["Link"], name: t("common.qiye"), path: "/" },
    //             { type: ItemType["Link"], name: t("common.rencai"), path: "/" },
    //             { type: ItemType["Link"], name: t("common.keji"), path: "/" },
    //             { type: ItemType["Link"], name: t("common.chanquan"), path: "/" },
    //             { type: ItemType["Link"], name: t("common.yuanqu"), path: "/" },
    //           ].map((item) => {
    //             return (
    //               <Menu.Item key={item.name}>
    //                 <a href={item.path ? item.path : "/"} style={{ color: "#5c6560" }}>
    //                   {item.name}
    //                 </a>
    //               </Menu.Item>
    //             );
    //           })}
    //         </Menu>
    //       }
    //       trigger={["click"]}
    //     >
    //       <div
    //         onClick={(e) => {
    //           e.preventDefault();
    //         }}
    //         style={{ cursor: "pointer", color: "#5c6560" }}
    //       >
    //         更多
    //       </div>
    //     </Dropdown>
    //   ),
    //   name: "",
    // },
    { type: ItemType["ReactNode"], name: "多语言切换", path: "/", component: <ToggleLang /> },
  ];
  // footer
  const _dataSourceFooter: FooterType = [
    [
      { type: ItemType["Tag_Text"], name: t("common.footer01"), path: "/" },
      { type: ItemType["Tag_Text"], name: t("common.footer02"), path: "/" },
      { type: ItemType["Tag_Text"], name: t("common.footer03"), path: "/" },
      { type: ItemType["Tag_Text"], name: t("common.footer04"), path: "/" },
      { type: ItemType["Tag_Text"], name: t("common.footer05"), path: "/" },
    ],
    [
      { type: ItemType["Link"], name: t("common.footer06"), path: "/" },
      { type: ItemType["Link"], name: t("common.footer07"), path: "/" },
      { type: ItemType["Link"], name: t("common.footer08"), path: "/" },
      { type: ItemType["Link"], name: t("common.footer09"), path: "/" },
      { type: ItemType["Link"], name: t("common.footer10"), path: "/" },
      { type: ItemType["Link"], name: t("common.zhaopin"), path: "/" },
      { type: ItemType["Link"], name: t("common.gongzuo"), path: "/" },
    ],
    // [{ type: ItemType['ReactNode'], name: '', component: <div>33</div> }]
  ];

  const tag = ["/launchpad", "/u"].includes(window.location.pathname);

  return tag ? (
    <App />
  ) : (
    <div className="main-layout-container">
      <div className="main-layout-body">
        <MainLayoutBanner src={getImgSrc("/langxin-login-153904.png")} />
        <div className="main-layout-content">
          <MainLayoutHeader dataSource={_dataSourceHeader} />
          <div className="main-layout-login">
            <App />
          </div>
        </div>
      </div>
      <MainLayoutFooter dataSource={_dataSourceFooter} />
    </div>
  );
};
