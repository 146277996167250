import React, { FC, useState } from "react";
import { ResetPasswordStep1 } from "./step1";
import { ResetPasswordStep2 } from "./step2";
import { ResetPasswordStep3 } from "./step3";
import { ResetPasswordStep4 } from "./step4";
import { EmailScene } from "authing-js-sdk";
import { useTitle } from "../../hooks/useTitle";
import { message } from "antd";
import { useTranslation } from "react-i18next";

export const ResetPasswordPage: FC = () => {
  const { t } = useTranslation();
  useTitle(t("login.resetPwd"));

  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(false);

  const onStep1Finish = async (type: string, value: string) => {
    const authenticationClient = window.__authing__;
    if (type === "phone") {
      setPhone(value);
      setStep(2);
    } else if (type === "email") {
      setEmail(value);
      try {
        setLoading(true);
        await authenticationClient.sendEmail(value, EmailScene.ResetPassword);
        message.success(t("login.emailSent"));
        setStep(3);
      } finally {
        setLoading(false);
      }
    }
  };

  const onStep2OrStep3Finish = () => {
    setStep(4);
  };

  switch (step) {
    case 1:
      return <ResetPasswordStep1 loading={loading} onFinish={onStep1Finish}></ResetPasswordStep1>;
    case 2:
      return <ResetPasswordStep2 phone={phone} onFinish={onStep2OrStep3Finish}></ResetPasswordStep2>;
    case 3:
      return <ResetPasswordStep3 email={email} onFinish={onStep2OrStep3Finish}></ResetPasswordStep3>;
    case 4:
      return <ResetPasswordStep4></ResetPasswordStep4>;
    default:
      return null;
  }
};
