import { Spin } from "antd";
import classNames from "classnames";
import React, { CSSProperties, FC, ReactNode } from "react";
import styles from "./style.module.less";

interface ICardProps {
  title: string;
  topRightComponent?: ReactNode;
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  loading?: boolean;
}

export const Card: FC<ICardProps> = ({
  title,
  topRightComponent,
  children,
  style = {},
  className = "",
  loading = false,
}) => {
  return (
    <Spin spinning={loading}>
      <div className={classNames(styles.CardContainer, className)} style={style}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>{title}</span>
          {topRightComponent ? topRightComponent : null}
        </div>
        {children}
      </div>
    </Spin>
  );
};
