import { Button, Space, Tooltip } from "antd";
import React, { FC, MouseEvent, useEffect, useState } from "react";
import Joyride, { Step, TooltipRenderProps } from "react-joyride";
import { CloseOutlined } from "@ant-design/icons";

import styles from "./styles.module.less";
import { noviceEventBus, waitForRender } from "./utils";

interface GuidanceStepProps {
  stepIndex: number;
  steps: Step[];
  start: Boolean;
  totalStep?: number;
}

const TooltipComponent: FC<
  TooltipRenderProps & {
    setRun: Function;
    totalStep: number;
  }
> = ({ tooltipProps, step, primaryProps, index, totalStep }) => {
  const customOnClick = (evt: MouseEvent<HTMLElement, MouseEvent>) => {
    //   @ts-ignore
    // onClick(evt);
    // step.onNext().then(() => {
    //   setRun(false);
    // });
    step.onNext?.(index, step);
  };

  return (
    <div className={styles.tooltipContainer} {...tooltipProps}>
      <h3 className={styles.tooltipTitle}>
        {step.title}
        <Tooltip className={styles.closeTooltip} title="退出引导">
          <CloseOutlined onClick={() => noviceEventBus.emit("exitNovice")} />
        </Tooltip>
      </h3>
      <p className={styles.toolTipContent}>{step.content}</p>
      <div className={styles.toolTipFooter}>
        <span
          style={{
            color: "#999",
          }}
        >
          {`${index + 1}/${totalStep}`}
        </span>
        <Space
          style={{
            marginLeft: "auto",
          }}
          size={12}
        >
          {(step as Step & { showNext?: boolean }).showNext && (
            // @ts-ignore
            <Button {...{ ...primaryProps, onClick: customOnClick }} type="primary" size="small">
              下一步
            </Button>
          )}
        </Space>
      </div>
    </div>
  );
};

export const AuthingJoyride: FC<GuidanceStepProps> = ({ stepIndex, steps, start, totalStep }) => {
  const handleJoyrideCallback = () => {};
  const [run, setRun] = useState(false);

  useEffect(() => {
    if (start) {
      setRun(false);
      return waitForRender(steps[stepIndex].target, () => {
        setRun(true);
      });
    } else {
      setRun(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex, start]);

  return (
    <Joyride
      styles={{
        options: {
          zIndex: 1000,
        },
      }}
      tooltipComponent={(ps) => <TooltipComponent {...ps} totalStep={totalStep || steps.length} setRun={setRun} />}
      continuous
      disableCloseOnEsc
      spotlightClicks
      disableOverlayClose
      showProgress
      callback={handleJoyrideCallback}
      run={run}
      stepIndex={stepIndex}
      steps={steps}
    />
  );
};
