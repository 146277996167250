import React, { FC } from "react";
import styles from "./styles.module.less";

export interface IconProps {
  icon: string;
  size?: number;
  active?: boolean;
  color?: string;
  backgroundColor?: string;
}

export const Icon: FC<IconProps> = ({ icon, size = 14, active = true, color, backgroundColor }) => {
  color = color || active ? "#ffffff" : "#B0B9D2";
  backgroundColor = backgroundColor || active ? "#446DF6" : "#EFF1F6";

  return (
    <span
      className={styles.icon}
      style={{
        width: "2.5em",
        height: "2.5em",
        fontSize: size,
        color,
        backgroundColor,
      }}
    >
      <i className={`authing-icon authing-${icon}`} />
    </span>
  );
};
