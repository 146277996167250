import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MobileSelect } from "@/components/MobileSelect";
import { changeLang, Lang, LANG_MAP } from "@/locales";
import classnames from "classnames";
import styles from "./styles.module.less";

export const MobileLangSelect: FC<{}> = () => {
  const { i18n } = useTranslation();
  let lang = i18n.language as Lang;
  lang = Object.values(Lang).includes(lang) ? lang : Lang.zhCn;

  return (
    <MobileSelect
      value={lang}
      options={LANG_MAP}
      onChange={(val) => changeLang(val as Lang)}
      selectedRender={(setOptsVisible) => {
        return {
          [Lang.enUs]: (
            <span
              onClick={() => setOptsVisible(true)}
              className={classnames(styles.mobileLangSelect, "authing-mobile-lang-select")}
            >
              <span>En</span>
              <span>/中</span>
            </span>
          ),
          [Lang.zhCn]: (
            <span
              onClick={() => setOptsVisible(true)}
              className={classnames(styles.mobileLangSelect, "authing-mobile-lang-select")}
            >
              <span>中</span>
              <span>/英</span>
            </span>
          ),
        }[lang];
      }}
    />
  );
};
