import { useState, useEffect } from "react";
import { ScreenSize, SCREEN_SIZE_RANG } from "@/constants/enum";
import { inRange, debounce } from "lodash";

export const getScreenSize = () => {
  const viewportWidth = document.body.clientWidth;
  return SCREEN_SIZE_RANG.find((item) => inRange(viewportWidth, ...item.range))?.size ?? ScreenSize.Desktop;
};

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState(getScreenSize);

  useEffect(() => {
    const onResize = debounce(() => {
      setScreenSize(getScreenSize());
    }, 200);
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return [screenSize];
};
