import React, { FC } from "react";
import { Container } from "../../../components/Container";
import { Header } from "../../../components/Header";
import { Form, Button, Input, message } from "antd";
import { SafetyOutlined, LockOutlined } from "@ant-design/icons";
import { SizeBox } from "../../../components/SizeBox/index";
import { RuleObject } from "antd/lib/form";
import { StoreValue } from "antd/lib/form/interface";
import { EmailScene } from "authing-js-sdk";
import { useTranslation } from "react-i18next";
import { getPasswordValidate } from "@/utils/formRules";
import { PASSWORD_STRENGTH_TEXT_MAP } from "@/constants/enum";
import classnames from "classnames";

export interface ResetPasswordStep3Props {
  email: string;
  onFinish?: () => void;
}

export const ResetPasswordStep3: FC<ResetPasswordStep3Props> = ({ email, onFinish = () => {} }) => {
  const config = window.__config__;
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onStep3Finish = async (values: any) => {
    const code = values.code;
    const password = values.password;
    const authenticationClient = window.__authing__;
    const res = await authenticationClient.resetPasswordByEmailCode(email, code, password);
    if (res.code === 200) {
      onFinish();
    } else {
      message.error(res.message);
    }
  };

  return (
    <Container>
      <Header title={t("login.resetPwd")} logo={config.logo} large />
      <p
        style={{
          marginBottom: 24,
          fontFamily: "PingFangSC-Medium",
          padding: "0 12px",
        }}
        className={classnames("authing-password-reset-email-sent-tip")}
      >
        {t("login.resetEmailSent", {
          email,
        })}
      </p>

      <Form form={form} onFinish={onStep3Finish}>
        <Form.Item
          name="code"
          rules={[
            { required: true, message: t("login.noEmpty") },
            {
              len: window.__config__.verifyCodeLength,
              message: t("login.inputFourVerifyCode", {
                length: window.__config__.verifyCodeLength,
              }),
            },
          ]}
        >
          <Input
            name="code"
            size="large"
            autoComplete="code"
            placeholder={t("login.fourVerifyCode", {
              length: window.__config__.verifyCodeLength,
            })}
            prefix={<SafetyOutlined style={{ color: "#ddd" }} />}
          />
        </Form.Item>
        <Form.Item name="password" rules={getPasswordValidate(config.passwordStrength)}>
          <Input.Password
            name="password"
            size="large"
            autoComplete="new-password"
            visibilityToggle={false}
            placeholder={PASSWORD_STRENGTH_TEXT_MAP[config.passwordStrength].placeholder}
            prefix={<LockOutlined style={{ color: "#ddd" }} />}
          />
        </Form.Item>
        <Form.Item
          name="repeat-password"
          rules={[
            { required: true, message: t("login.noEmpty") },
            {
              validator: async (rule: RuleObject, value: StoreValue) => {
                if (form.getFieldValue("password") !== value) {
                  throw new Error(t("login.twoPwdNeedSame"));
                }
              },
            },
          ]}
        >
          <Input.Password
            name="repeat-password"
            size="large"
            autoComplete="repeat-password"
            visibilityToggle={false}
            placeholder={t("login.inputPwdAgain")}
            prefix={<LockOutlined style={{ color: "#ddd" }} />}
          />
        </Form.Item>
        <SizeBox height={16} />
        <Form.Item>
          <Button block type="primary" size="large" htmlType="submit">
            {t("login.resetPwd")}
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            block
            type="primary"
            ghost
            size="large"
            onClick={async () => {
              await window.__authing__.sendEmail(email, EmailScene.ResetPassword);
              message.success(t("login.emailSent"));
            }}
          >
            {t("login.resetPwdEmail")}
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};
