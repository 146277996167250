import React, { useState } from "react";
import { Modal, message } from "antd";
import { Trans, useTranslation } from "react-i18next";
import { delOrgNode } from "@/api/user";
import { lazyLoading } from "@/utils";

export const DeleteNodeModal = (props: {
  currentNode: any;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess: () => void;
}) => {
  const { visible, setVisible, onSuccess, currentNode } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // 添加子节点
  const deleteNode = async () => {
    lazyLoading(async () => {
      const res: any = await delOrgNode({ orgId: currentNode.orgId, nodeId: currentNode.id });
      if (res?.code === 200) {
        message.success(t("user.org.deleteNodeSuccess"));
        onSuccess();
      } else {
        message.error(res?.message);
      }
    }, setLoading);
  };

  return (
    <Modal
      className="org-modal-form-required"
      title={t("user.org.deleteNode")}
      visible={visible}
      onOk={deleteNode}
      onCancel={() => {
        setVisible(false);
      }}
      okText={t("common.confirm")}
      okButtonProps={{ type: "primary", danger: true }}
      cancelText={t("common.cancel")}
      confirmLoading={loading}
      maskClosable={false}
    >
      <p>
        <Trans i18nKey="user.org.confirmDeleteNodeName">
          1
          <span>
            {{
              nodeName: currentNode.name,
            }}
          </span>
          3
        </Trans>
      </p>
    </Modal>
  );
};
