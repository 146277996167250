import React, { useState, useMemo } from "react";
import { Tree, Modal, message } from "antd";
import classnames from "classnames";
import styles from "../style.module.less";
import { useTranslation } from "react-i18next";
import { walker } from "@/common/tree";
import _ from "lodash";
import { LangxinIconFont } from "@/components/LangxinIconFont";
import { moveOrgNode } from "@/api/user";

export const MoveNodeModal = (props: {
  orgs: any[];
  currentNode: any;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess: () => void;
}) => {
  const { visible, setVisible, currentNode, orgs, onSuccess } = props;
  const { id: nodeId, orgId } = currentNode;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // 选中/展开的节点
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);

  const moveNode = async () => {
    const targetId = selectedKeys[0];
    if (!targetId) {
      return message.warn("请选择目标节点");
    }
    if (targetId === currentNode.id) {
      message.error(t("user.org.chooseDiffNode"));
      return;
    }
    setLoading(true);
    try {
      await moveOrgNode({ nodeId, targetId, orgId });
      message.success(t("user.org.moveNodeSuccess"));
      onSuccess();
      setExpandedKeys([]);
      setSelectedKeys([]);
    } finally {
      setLoading(false);
    }
  };

  // 联级选择框的数据
  const source = useMemo(() => {
    return walker(_.cloneDeep(orgs || []), (leaf: any) => {
      return {
        ...leaf,
        label: leaf.name,
        name: leaf.name,
        value: leaf.id,
        children: leaf.children,
      };
    });
  }, [orgs]);

  return (
    <Modal
      className="org-modal-form-required"
      title={t("user.org.moveNode")}
      visible={visible}
      onOk={() => {
        moveNode();
      }}
      onCancel={() => {
        setVisible(false);
        setExpandedKeys([]);
        setSelectedKeys([]);
      }}
      okText={t("common.sure")}
      cancelText={t("common.cancel")}
      confirmLoading={loading}
      maskClosable={false}
      destroyOnClose
    >
      <div>
        <div className={styles.orgLabel}>
          <i className={classnames("langxin-icon", "langxin-iconorganization-line", styles.orgIcon)}></i>
          <span className={styles.label}>组织机构</span>
        </div>
        <Tree
          style={{ marginLeft: "32px" }}
          treeData={source}
          titleRender={(node) => {
            return (
              <div className={styles.treeItem}>
                <div className={styles.treeTitle}>
                  <LangxinIconFont type="langxin-icongroup-line" />
                  <span style={{ marginLeft: "8px" }}>{(node as any).label}</span>
                </div>
              </div>
            );
          }}
          blockNode={true}
          expandedKeys={expandedKeys}
          defaultExpandAll={true}
          onExpand={(expandedKeys) => {
            setExpandedKeys(expandedKeys);
          }}
          selectedKeys={selectedKeys}
          onSelect={(selectedKeys, e) => {
            setSelectedKeys(selectedKeys);
          }}
        />
      </div>
    </Modal>
  );
};
