import { Button, message, Spin, Upload } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import React, { FC, useContext, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import styles from "../styles.module.less";
import { useTranslation } from "react-i18next";
import { UserContext } from "@/context/userContext";
interface ILeftAvatarProps {}

export const LeftAvatar: FC<ILeftAvatarProps> = (props) => {
  const { user, updateUser } = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const onChange = async (info: UploadChangeParam) => {
    const { status } = info.file;
    if (status === "done") {
      const { code, message: errmsg, data } = info.file.response;
      if (code !== 200) {
        return message.error(errmsg);
      }
      const { url } = data;
      if (window.__user__?.id) {
        await window.__authing__.updateProfile({ photo: url });
        updateUser({ photo: url });
        setLoading(false);
        message.success(t("common.updateSuccess"));
      }
    } else if (status === "error") {
      setLoading(false);
      message.error(
        t("common.uploadFail", {
          name: info.file.name,
        })
      );
    }
  };

  return (
    <Spin spinning={loading}>
      <div className={styles.leftAvatarContainer}>
        <span
          style={{
            color: "#5C6560",
          }}
        >
          头像
        </span>
        <img src={user?.photo ?? ""} alt="" className={styles.avatarImg} />
        <Upload
          name="file"
          listType="picture"
          className={styles.uploadBtn}
          showUploadList={false}
          accept="image/*"
          action="/api/v2/upload?folder=photos"
          onChange={onChange}
          beforeUpload={() => {
            setLoading(true);
            return true;
          }}
        >
          <Button icon={<UploadOutlined />}>更换头像</Button>
        </Upload>
      </div>
    </Spin>
  );
};
