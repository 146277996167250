import { Button, Input, message, Modal, Pagination } from "antd";
import React, { FC, useMemo, useState } from "react";
import styles from "./style.module.less";
import { SearchOutlined } from "@ant-design/icons";
import { addCustomApp, delCustomApp, IAllCanAddApp } from "@/api/user";
import { useEffectOnce } from "react-use";

interface IAddModalWrapperProps {
  visible: boolean;
  updateVisible: (bool: boolean) => void;
  appList: IAllCanAddApp[];
  onSearch: (s?: string) => any;
  getCustomList: () => any;
}

export const AddModalWrapper: FC<IAddModalWrapperProps> = ({
  visible,
  updateVisible,
  appList,
  getCustomList,
  onSearch,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageData, setPageData] = useState({
    pageSize: 15,
    page: 1,
  });

  useEffectOnce(() => {
    onSearch();
  });

  const list = useMemo(() => {
    const { pageSize, page } = pageData;
    if (appList.length > pageSize) {
      return appList.slice((page - 1) * pageSize, page * pageSize);
    } else {
      return appList;
    }
  }, [appList, pageData]);

  return (
    <Modal
      title="添加常用"
      visible={visible}
      onCancel={() => updateVisible(false)}
      className={styles.addCustomAppModal}
      footer={false}
    >
      <Input
        placeholder="请输入搜索应用名称"
        prefix={<SearchOutlined />}
        value={searchValue}
        onPressEnter={() => {
          onSearch(searchValue);
          setPageData({ ...pageData, page: 1 });
        }}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <div className={styles.list}>
        {list.map((item) => {
          return (
            <div className={styles.item}>
              <div className={styles.leftItem}>
                <img src={item.appLogo} alt="" className={styles.appLogo} />
                <div className={styles.appTitle}>{item.appName}</div>
              </div>
              {item.exist ? (
                <Button
                  size="small"
                  style={{ color: "rgba(0, 0, 0, 0.65)", borderColor: "rgba(0, 0, 0, 0.15)" }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    await delCustomApp(item.uuid);
                    message.success("移除成功");
                    onSearch(searchValue);
                    getCustomList();
                  }}
                >
                  移除
                </Button>
              ) : (
                <Button
                  size="small"
                  style={{ color: "rgba(221, 128, 80, 1)", borderColor: "rgba(221, 128, 80, 1)" }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    await addCustomApp(item);
                    message.success("添加成功");
                    onSearch(searchValue);
                    getCustomList();
                  }}
                >
                  添加
                </Button>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.pagination}>
        <Pagination
          size="small"
          total={appList.length}
          showSizeChanger={false}
          current={pageData.page}
          pageSize={pageData.pageSize}
          onChange={(page) => {
            setPageData({ ...pageData, page });
          }}
        />
      </div>
    </Modal>
  );
};
