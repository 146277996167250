import { Select, DatePicker, Space, Button } from "antd";
import React, { FC, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { actionType, logType } from "./constants";
import styles from "./styles.module.less";
import { LogsTable } from "./Table";
import { useDebounce, useEffectOnce } from "react-use";
import { getActionAppList, getSearchLogsList } from "@/api/user";
import { useQuery } from "@/hooks/useQuery";
import { LOG_SELECTED_TYPE } from "../constants";
import { useNavigate } from "react-router";
import qs from "query-string";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const UserLogs: FC = () => {
  const [searchAppList, setSearchAppList] =
    useState<
      Array<{
        appId: string;
        appName: string;
      }>
    >();
  const [val, setVal] = useState<string>("");
  const [state, setState] = useState({
    type: logType[0].value,
    operationNames: actionType[0].value,
    appId: "",
    time: [undefined, undefined],
  });
  const [query] = useQuery();
  const navigate = useNavigate();
  const [listData, setListData] = useState<any>({
    page: 1,
    count: 0,
    list: [],
    pageSize: 10,
  });

  const getList = (page: number, query: any = {}) => {
    const data: any = { ...state, ...query };
    const time = data.time;
    if (time[0]) data.startTime = new Date(time[0]).getTime();
    if (time[1]) data.endTime = new Date(time[1]).getTime();
    if (!data.operationNames) delete data.operationNames;
    if (!data.appId) delete data.appId;
    delete data.time;
    getSearchLogsList({ ...data, page, limit: 10 }).then((res) => {
      setListData({ ...listData, ...res });
    });
  };

  useEffectOnce(() => {
    if (query[LOG_SELECTED_TYPE as any]) {
      const target = logType.find((item) => item.key === query[LOG_SELECTED_TYPE as any]);
      setState({ ...state, type: target?.value as any });
      getList(1, { type: target?.value as any });
      const _query = { ...query };
      delete _query[LOG_SELECTED_TYPE as any];
      navigate(`/u?${qs.stringify(_query)}`);
      return;
    }
    getList(1);
  });

  useDebounce(
    async () => {
      const list = await getActionAppList(val);
      setSearchAppList(list);
    },
    1000,
    []
  );

  return (
    <div className={styles.userLogsContainer}>
      <div className={styles.top}>
        <Space size="large">
          <Select
            placeholder="请选择日志类型"
            onChange={(value) => setState({ ...state, type: value as number })}
            allowClear
            value={state.type}
            style={{ width: 160 }}
          >
            {logType.map(({ value, text, key }) => {
              return (
                <Option value={value} key={key}>
                  {text}
                </Option>
              );
            })}
          </Select>
          <Select
            placeholder="请选择操作类型"
            onChange={(value) => setState({ ...state, operationNames: value as string })}
            allowClear
            value={state.operationNames}
            style={{ width: 160 }}
          >
            {(state.type === 2 ? actionType.slice(1, 3) : actionType).map(({ value, text, key }) => {
              return (
                <Option value={value} key={key}>
                  {text}
                </Option>
              );
            })}
          </Select>
          <RangePicker
            style={{ width: 240 }}
            value={state.time as any}
            onChange={(value, str) => {
              setState({ ...state, time: value as any });
            }}
          />
          <Select
            showSearch
            style={{ width: 251 }}
            placeholder="请输入应用名称搜索"
            optionFilterProp="children"
            suffixIcon={<SearchOutlined />}
            value={state.appId}
            onSearch={(value) => {
              setVal(value);
            }}
            onSelect={(val) => {
              setState({ ...state, appId: val as any });
            }}
          >
            {searchAppList && searchAppList.map(({ appId, appName }) => <Option value={appId}>{appName}</Option>)}
          </Select>
        </Space>
        <Space size="middle">
          <Button
            onClick={() => {
              setState({
                type: logType[0].value,
                operationNames: actionType[0].value,
                appId: "",
                time: [],
              });
              setTimeout(() => {
                getList(1);
              });
            }}
          >
            重置
          </Button>
          <Button
            type="primary"
            onClick={() => {
              getList(1);
            }}
          >
            搜索
          </Button>
        </Space>
      </div>
      <LogsTable listData={listData} getList={getList} />
    </div>
  );
};
