import React, { FC, useState } from "react";
import styles from "./style.module.less";
import { CloseOutlined } from "@ant-design/icons";
import { LangxinIconFont } from "../LangxinIconFont";
import classNames from "classnames";

interface INotifyContainerProps {
  iconType: string;
  notifyType: "warn";
  text: string;
}

export const NotifyContainer: FC<INotifyContainerProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(true);
  const { iconType, notifyType, text } = props;

  return (
    <div className={classNames(styles.notifiencesContainer, styles[notifyType], visible ? "" : styles.hiddenNoyify)}>
      <div className={styles.leftContent}>
        <LangxinIconFont type={iconType} />
        <span className={styles.content}>{text}</span>
      </div>
      <CloseOutlined
        className={styles.closeNotify}
        onClick={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};
