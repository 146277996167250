import { Button, Space } from "antd";
import React, { FC } from "react";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import GoogleApple from "../../../../assets/image/GoogleAuthenticator-Apple.png";
import styles from "../styles.module.less";
interface IUserQrcodeProps {}

export const UserQrcode: FC<IUserQrcodeProps> = (props) => {
  return (
    <div className={styles.userQrcodeContainer}>
      <div>
        <div className={styles.qrcodeLabel}>用户二维码</div>
        <div
          className={styles.qrcodeImage}
          style={{
            backgroundImage: `url('${GoogleApple}')`,
          }}
        />
      </div>
      <div className={styles.actionBtn}>
        <Space size="middle" direction="vertical">
          <Button icon={<ReloadOutlined />}>重置</Button>
          <Button icon={<DownloadOutlined />}>下载</Button>
        </Space>
      </div>
    </div>
  );
};
