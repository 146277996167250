import React, { FC, useRef, useState } from "react";
import { Header } from "../../components/Header";
import { Tabs, message } from "antd";
import { Container } from "../../components/Container";
import { AuthingTabs } from "../../components/AuthingTabs/index";
import { User } from "authing-js-sdk";
import { getDisplayName } from "../../utils";
import { useTitle } from "../../hooks/useTitle";
import { useTranslation } from "react-i18next";
import { SubAccountLoginForm } from "../../components/forms/SubAccount";
import { Protocol } from "../../config";
import querystring from "query-string";

const { TabPane } = Tabs;

export const SubAccountPage: FC = () => {
  const { t } = useTranslation();
  useTitle("子账号登录");
  const config = window.__config__;

  const [formHidden, setFormHidden] = useState({
    action: "",
    token: "",
  });
  const [fields, setFields] = useState<{ [key: string]: string }>({});
  const submitBtn = useRef<any>();

  const onSuccess = async (user: User) => {
    window.__authing__.setCurrentUser(user);

    message.success(
      t("common.loginSuccessWelcome", {
        name: getDisplayName(user),
      })
    );

    setTimeout(() => {
      // TODO: 这段逻辑与 src/utils/loginSuccess.tsx 的 loginSuccessRedirect 一致，谁敢替换一下
      if (config.protocol === Protocol.OIDC) {
        const query = querystring.parse(window.location.search);
        setFormHidden({
          action: `/interaction/oidc/${query["uuid"]}/login`,
          token: user.token ?? "",
        });
        submitBtn.current.click();
        return;
      }

      if (config.protocol === Protocol.SAML) {
        setFormHidden({
          action: `/interaction/saml-idp/login`,
          token: user.token ?? "",
        });
        submitBtn.current.click();
        return;
      }

      if (config.protocol === Protocol.CAS) {
        setFormHidden({
          action: `/interaction/cas-idp/login`,
          token: user.token ?? "",
        });
        submitBtn.current.click();
        return;
      }

      if (config.protocol === Protocol.OAUTH) {
        setFormHidden({
          action: `/interaction/oauth/login`,
          token: user.token ?? "",
        });
        setFields(querystring.parse(window.location.search) as any);
        submitBtn.current.click();
        return;
      }

      // protocol=none 时，直接带着用户信息跳转过去
      let redirectUrl = `${config.redirectUris && config.redirectUris[0]}/?data=${encodeURIComponent(
        JSON.stringify(user)
      )}`;
      window.location.href = redirectUrl;
    }, 500);
  };

  const onFail = (error: any) => {
    console.log("登录失败:\n", error);
  };

  const tabPanes = ["subAccount"].map((tab) => {
    return (
      <TabPane tab={"子账号登录"} key={tab}>
        <SubAccountLoginForm onSuccess={onSuccess} onFail={onFail} />
      </TabPane>
    );
  });

  return (
    <Container>
      <Header title={config.name} logo={config.logo} />
      <AuthingTabs centered activeKey={"subAccount"}>
        {tabPanes}
      </AuthingTabs>
      <form action={formHidden.action} method="post" style={{ display: "none" }}>
        {Object.entries(fields).map(([k, v]) => (
          <input key={k} type="text" name={k} value={v} readOnly />
        ))}
        <input type="text" name="token" value={formHidden.token} readOnly />
        <button ref={submitBtn} type="submit"></button>
      </form>
    </Container>
  );
};
