import { uploadOSS } from "@/api/user";
import { Button, message, Spin, Upload } from "antd";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";
import React, { FC } from "react";
import { useAsyncFn } from "react-use";
import { certificationStatusMap } from "../../constants";
import styles from "./style.module.less";
interface IStepOneProps {
  updateStepCurrent: (curr: number) => void;
  uploadFile: (url: string) => any;
  loading?: boolean;
  onUpdate: (type: string) => void;
  certImg: string;
  setCertImg: (url: string) => void;
}

export const StepOne: FC<IStepOneProps> = ({
  updateStepCurrent,
  uploadFile,
  loading = false,
  onUpdate,
  certImg,
  setCertImg,
}) => {
  const handleCancel = () => {
    onUpdate(certificationStatusMap.NO_CERTIFICATION);
  };
  const gotoStepTwo = () => {
    updateStepCurrent(1);
  };

  const [imgsData, uploadImg] = useAsyncFn(async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    const res = await uploadOSS(formData);
    return res;
  });

  const customRequest = async ({ file }: RcCustomRequestOptions) => {
    const res = await uploadImg(file);
    if (res?.url) {
      setCertImg(res?.url);
      uploadFile(res?.url);
    } else {
      message.error("上传失败，请重新上传");
    }
  };

  return (
    <Spin spinning={loading || imgsData.loading}>
      <div className={styles.stepOneContainer}>
        <Upload name="file" listType="picture" showUploadList={false} customRequest={customRequest}>
          <img src={certImg} alt="" className={styles.tipsImg} />
        </Upload>
        <span className={styles.tips}>点击上传营业执照</span>
        <div className={styles.btns}>
          <Button className={styles.cancelAction} onClick={handleCancel}>
            取消
          </Button>
          <Button type="primary" onClick={gotoStepTwo}>
            下一步
          </Button>
        </div>
      </div>
    </Spin>
  );
};
