import React, { FC, useState } from "react";
import { Input, Form, Checkbox, Button, message, Typography } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Container } from "../../components/Container";
import Axios from "axios";
import { getDisplayName } from "../../utils";
import { loginSuccessRedirect } from "../../utils/loginSuccess";
import { User } from "authing-js-sdk";
import { Header } from "../../components/Header";
import styles from "./styles.module.less";
import { Rule } from "antd/lib/form";
import { RouterLink } from "../../components/RouterLink";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

const Step1: FC<{ secret: string; setSecret: Function }> = ({ secret, setSecret }) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: t("login.inputRecoverCode"),
        },
      ]}
      name="MFASecret"
    >
      <Input
        prefix={<LockOutlined style={{ color: "#ddd" }} />}
        placeholder={t("login.inputRecoverCode")}
        size="large"
        onChange={(evt) => setSecret(evt.target.value)}
        value={secret}
      ></Input>
    </Form.Item>
  );
};

const Step2: FC<{ recoverCode: string }> = ({ recoverCode }) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();

  const rules: Rule[] = [
    {
      validateTrigger: [],
      validator() {
        return new Promise((resolve, reject) => {
          if (!checked) {
            reject(t("login.confirmSavedCode"));
          }
          resolve();
        });
      },
    },
  ];
  return (
    <>
      <Paragraph className={styles.secretParagraph} copyable>
        {recoverCode}
      </Paragraph>
      <Form.Item rules={rules} name="MFACopyRecoverCode">
        <Checkbox onChange={(evt) => setChecked(evt.target.checked)} checked={checked}>
          {t("login.rememberedSecret")}
        </Checkbox>
      </Form.Item>
    </>
  );
};

export const ResetMFA = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [rawForm] = Form.useForm();
  const [secret, setSecret] = useState("");
  const [recoverCode, setRecoverCode] = useState("0ccf-2767-26c7-cde7-2a22-cbc5");
  const [userInfo, setUserInfo] = useState<User>({} as User);
  const [submiting, setSubmiting] = useState(false);
  const { t } = useTranslation();
  const MFAToken = new URL(window.location.href).searchParams.get("mfa_token");
  const config = window.__config__;

  const steps: Record<
    number,
    {
      component: JSX.Element;
      submitHandler: Function;
    }
  > = {
    0: {
      component: <Step1 {...{ secret, setSecret }}></Step1>,
      submitHandler: async () => {
        setSubmiting(true);
        try {
          await rawForm.validateFields();
        } catch (e) {
          setSubmiting(false);
          return;
        }

        try {
          const data: any = await Axios.post(
            "/api/v2/mfa/totp/recovery",
            {
              recoveryCode: secret,
            },
            {
              headers: {
                authorization: MFAToken,
              },
            }
          );
          if (data.code !== 200) {
            message.error(data.message);
            setSubmiting(false);
            return;
          }
          message.success(t("login.newGenered"));

          setRecoverCode(data.recoveryCode);

          setUserInfo(data.data);

          setCurrentStep(currentStep + 1);
          setSubmiting(false);
        } catch (e) {
          setSubmiting(false);
          message.error(e.message);
        }
      },
    },
    1: {
      component: <Step2 {...{ recoverCode }} />,
      submitHandler: async () => {
        setSubmiting(true);
        try {
          await rawForm.validateFields();
        } catch (e) {
          setSubmiting(false);
          return;
        }

        await window.__authing__.setCurrentUser(userInfo);
        message.success(
          t("common.loginSuccessWelcome", {
            name: getDisplayName(userInfo),
          })
        );
        setTimeout(() => {
          loginSuccessRedirect(userInfo);
        }, 500);
      },
    },
  };

  const handleConfirm = () => {
    steps[currentStep].submitHandler();
  };

  return (
    <Container>
      <Header title={config.name} logo={config.logo} />

      <h2 className={styles.title}>{t("common.useRecoverCode")}</h2>
      <p className={styles.tips}>{t("login.mfaAfterReset")}</p>
      <Form form={rawForm}>
        {steps[currentStep].component}
        <Button
          loading={submiting}
          className={styles.confirmBtn}
          block
          onClick={handleConfirm}
          type="primary"
          size="large"
        >
          {t("common.sure")}
        </Button>
        {currentStep === 0 && (
          <RouterLink to="/" className={styles.link}>
            {t("login.findedSafetyCode")}
          </RouterLink>
        )}
      </Form>
    </Container>
  );
};
