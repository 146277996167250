import React, { FC } from "react";
import styles from "../style.module.less";
import { ShowCard } from "../../components/ShowCard";
import { getImgSrc } from "@/utils";
import { Button } from "antd";
import { authStepMap } from "../constants";
interface IStartAuthProps {
  updateAuthStatus: (a: string) => void;
}

export const StartAuth: FC<IStartAuthProps> = ({ updateAuthStatus }) => {
  return (
    <div className={styles.startAuthContainer}>
      <ShowCard
        firstTitle="你还未进行实名认证"
        secondTitle="为了不影响你的操作，请尽快完成实名认证"
        imgProps={{
          src: getImgSrc("/langxin/undraw_Wall_post_re_y78d.svg"),
          style: {
            height: 99,
          },
        }}
        actionButton={
          <Button
            style={{ width: 120 }}
            type="primary"
            onClick={() => {
              updateAuthStatus(authStepMap.PROCESSING_AUTH);
            }}
          >
            开始认证
          </Button>
        }
      />
    </div>
  );
};
