import { Container } from "@/components/Container";
import React from "react";
import { FC } from "react";
import { UserMfa, TotpSource } from "../User/Mfa";

export const BindTotpMFA: FC = () => {
  const MFAToken = new URL(window.location.href).searchParams.get("mfa_token");

  return (
    <Container width={1200} minHeight={745}>
      <UserMfa totpSource={TotpSource.APPLICATION} MFAToken={MFAToken as string} />
    </Container>
  );
};
