import { Protocol } from "../config";
import React, { FC } from "react";
import ReactDOM from "react-dom";
import querystring from "query-string";
import { User } from "authing-js-sdk";

interface IPostFormProps {
  action: string;
  token: string;
  fields?: Record<string, any>;
}

export const PostForm: FC<IPostFormProps> = ({ token, action, fields = {} }) => {
  return (
    <form id="_authingHiddenLoginForm" action={action} method="post" style={{ display: "none" }}>
      {Object.entries(fields).map(([k, v]) => (
        <input key={k} type="text" name={k} value={v as any} readOnly />
      ))}
      <input type="text" name="token" value={token} readOnly />
    </form>
  );
};

export const loginSuccessRedirect = (user: User) => {
  const config = window.__config__;
  const query = querystring.parse(window.location.search);

  let formProps: IPostFormProps = {} as IPostFormProps;
  switch (config.protocol) {
    case Protocol.OIDC:
      formProps = {
        action: `/interaction/oidc/${query["uuid"]}/login`,
        token: user.token ?? "",
      };
      break;

    case Protocol.SAML:
      formProps = {
        action: `/interaction/saml-idp/login`,
        token: user.token ?? "",
      };
      break;

    case Protocol.CAS:
      formProps = {
        action: `/interaction/cas-idp/login`,
        token: user.token ?? "",
      };
      break;

    case Protocol.OAUTH:
      formProps = {
        action: `/interaction/oauth/login`,
        token: user.token ?? "",
        fields: query,
      };

      break;
    default:
      // protocol=none 时，直接带着用户信息跳转过去
      let redirectUrl = `${config.redirectUris && config.redirectUris[0]}/?data=${encodeURIComponent(
        JSON.stringify(user)
      )}`;
      window.location.href = redirectUrl;
      break;
  }

  let divEl: HTMLDivElement | null = document.querySelector("#_authingHiddenLoginFormMount");
  if (!divEl) {
    divEl = document.createElement("div");
    divEl.id = "_authingHiddenLoginFormMount";
    divEl.style.display = "none";
  }
  document.body.appendChild(divEl);

  divEl.querySelector(`#_authingHiddenLoginForm`)?.remove();
  ReactDOM.render(<PostForm {...formProps} />, divEl);
  (document.body.querySelector(`#_authingHiddenLoginForm`) as HTMLFormElement).submit();
};
