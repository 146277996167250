import { Spin } from 'antd';
import React from 'react';

export const LoadingComponent = () => {
	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				top: 0,
				left: 0,
				zIndex: 10086,
				background: 'rgba(255, 255, 255, 0.2)',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				paddingBottom: '15vh'
			}}
		>
			<Spin size="large" />
		</div>
	);
};
