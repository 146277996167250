import React, { FC, useState, useEffect } from "react";
import { Modal, Form, Row, Col, Input, Button, message } from "antd";
import styles from "../style.module.less";
import classnames from "classnames";
import CopyIcon from "@/components/CopyIcon";
import { useTranslation } from "react-i18next";
import { updateOrgNode } from "@/api/user";

export const EditNodeModal: FC<{
  currentNode: any;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess: () => void;
  disabled?: boolean;
}> = (props) => {
  const { visible, setVisible, onSuccess, currentNode } = props;
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [form] = Form.useForm();

  const updateNode = async () => {
    try {
      const formData = form.getFieldsValue();
      await updateOrgNode({ nodeId: currentNode.id, ...formData });
      message.success("编辑成功");
      onSuccess();
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      name: currentNode.name,
      code: currentNode.code,
      description: currentNode.description || "",
    });
  }, [currentNode, form]);

  return (
    <Modal
      className="org-modal-form-required"
      title={<div style={{ width: "100%", paddingRight: 15 }}>{currentNode?.name}</div>}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      footer={
        <div className={classnames(styles["edit-node-modal-footer"])}>
          <div className={classnames(styles["edit-node-modal-footer-item"])}>
            <Button
              onClick={() => {
                setVisible(false);
                // setDisabled(true);
              }}
            >
              {t("common.cancel")}
            </Button>
          </div>
          <div className={classnames(styles["edit-node-modal-footer-item"])}>
            <Button
              type="primary"
              disabled={disabled}
              onClick={() => {
                form.submit();
              }}
            >
              保存
            </Button>
          </div>
        </div>
      }
      confirmLoading={false}
      maskClosable={false}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={() => {
          updateNode();
        }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="部门名称"
              rules={[
                {
                  required: true,
                },
              ]}
              name="name"
            >
              <Input placeholder="请输入部门名称" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="部门 ID">
              <div
                className={styles.formItem}
                style={{
                  background: " #F5F5F5",
                  borderRadius: "4px",
                  border: "1px solid #EAEBEE",
                }}
              >
                <span style={{ paddingRight: "8px" }}>{currentNode.id}</span>
                <CopyIcon copyValue={currentNode.id} />
              </div>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="部门标志符" name="code">
              <Input placeholder="请输入部门标志符" disabled={disabled} />
            </Form.Item>
            <Form.Item label="部门描述" name="description">
              <Input placeholder="请输入部门描述" disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
