import { i18n } from "@/locales";
import { CSSProperties } from "react";

export interface IBaseMenus {
  key: string;
  label: string;
  icon: string;
  style?: CSSProperties;
}

export const baseMenusText = {
  WORKBENCH: "workbench",
  BASIC: "basic",
  CONNECTION: "connection",
  MFA: "mfa",
  PASSWORD: "password",
  SECURITY_CENTER: "security_center",
  USER_LOGS: "user_logs",
  ENTERPRISE_AUTH: "enterprise_auth",
  EMPLOYEE_AUTH: "employee_auth",
  REAL_NAME_AUTH: "real_name_auth",
};

// 左侧菜单
export const baseMenus: IBaseMenus[] = [
  {
    key: baseMenusText.WORKBENCH,
    label: i18n.t("user.workbench"),
    icon: "langxin-icondashboard-2-line",
    style: { marginBottom: "24px" },
  },

  {
    key: baseMenusText.BASIC,
    label: i18n.t("user.personalInfo"),
    icon: "langxin-iconcontacts-line",
  },
  {
    key: baseMenusText.CONNECTION,
    label: i18n.t("login.accBind"),
    icon: "langxin-iconlinks-line",
  },
  {
    key: baseMenusText.MFA,
    label: i18n.t("user.mfaBind"),
    icon: "langxin-iconzhiwen2x",
  },
  {
    key: baseMenusText.PASSWORD,
    label: i18n.t("user.accPwd"),
    icon: "langxin-iconlock-line",
  },
  {
    key: baseMenusText.SECURITY_CENTER,
    label: "安全中心",
    icon: "langxin-iconshield-check-line",
  },
  {
    key: baseMenusText.USER_LOGS,
    label: "用户日志",
    icon: "langxin-iconfile-list-3-line",
    style: { marginBottom: "24px" },
  },

  {
    key: baseMenusText.ENTERPRISE_AUTH,
    label: i18n.t("user.enterpriseAuthentication"),
    icon: "langxin-iconbuilding-line",
  },
  {
    key: baseMenusText.EMPLOYEE_AUTH,
    label: i18n.t("user.employeeAuthentication"),
    icon: "langxin-iconuser-follow-line",
  },
  {
    key: baseMenusText.REAL_NAME_AUTH,
    label: i18n.t("user.realNameAuth"),
    icon: "langxin-iconuser-smile-line",
  },
];

export const APP_PANEL_LOGO = "https://files.authing.co/user-contents/photos/9d47feef-ddf7-473e-bac2-2569cbb62d91.png";

export const downloadAppText = {
  IOS_URL: "https://apps.apple.com/cn/app/id1537359876",
  ANDROID_URL: "https://app-oss.yzbays.com/App/Download/Version/release/App-version-1.1.4.apk",
  IOS_QRCODE_URL: "https://authing-files.oss-cn-zhangjiakou.aliyuncs.com/authing-user-portal/langxin/ios_qrcode.png",
  ANDROID_QRCODE_URL:
    "https://authing-files.oss-cn-zhangjiakou.aliyuncs.com/authing-user-portal/langxin/android_qrcode.png",
};

export const LOG_SELECTED_TYPE: string = "log_selected_type";

export const logSelectedType = {
  ALL: "all",
  BUSINESS_ACTION_LOG: "business_action_log",
  USER_ACTION_LOG: "user_action_log",
};

export const pageSizeOptions: string[] = ["5", "10"];

export const ignoreApps: string[] = [
  "60b89faeb085e0d8da504817",
  "6125b22e5bf02447a6aa2169",
  "619efccf8c3c915b254b03e1",
  "619b5df3e863d425728902d6",
  "619b0ce7532ab9bbc52e842b",
  "61ef76aa419990935f383e2c",
  "61f0b728ca348d5122a177cf",
  "61efa59e457564e779eea8c6",
];
