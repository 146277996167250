import React from "react";
import { Column } from "@ant-design/charts";

export const DemoColumn: React.FC<{ list: any[] }> = ({ list }) => {
  return (
    <Column
      data={list}
      isGroup={true}
      color={["rgba(90, 216, 166, 0.85)", "rgba(249, 152, 91, 0.85)"]}
      xField="data"
      yField="num"
      seriesField="name"
    />
  );
};
