import React, { useState, useEffect } from "react";
import { Container } from "../../components/Container/index";
import { Form, Input, Button, message, Select } from "antd";
import { SizeBox } from "../../components/SizeBox/index";
import styles from "./styles.module.less";
import { useNavigateWithSearch } from "../../hooks/useNavigateWithSearch";
import { useTitle } from "../../hooks/useTitle";
import { getImgSrc } from "../../utils";
import { useTranslation } from "react-i18next";
import { ImageCrop } from "@/components/ImageCrop";
import { i18n } from "@/locales";
import { loginSuccessRedirect } from "@/utils/loginSuccess";
import { RegisterInfoSessionKey } from "@/constants/enum";
import { RegisterInfo } from "@/types/user";
import { registerUser } from "../Register/registerUser";
import Axios from "axios";
import { User } from "authing-js-sdk";
import classnames from "classnames";

const UDF_INPUT_MAP: Record<string, React.ReactNode | undefined> = {
  image: <ImageCrop size={70} />,
};

const INTERNAL_INPUT_MAP: Record<string, React.ReactNode | undefined> = {
  gender: (
    <Select
      options={[
        { label: i18n.t("common.man"), value: "M" },
        { label: i18n.t("common.female"), value: "F" },
      ]}
    ></Select>
  ),
};

export const RegisterCompletePage = () => {
  const { t } = useTranslation();

  useTitle(t("common.registerInfoFill"));

  const [form] = Form.useForm();
  const navigate = useNavigateWithSearch();
  const [definitions, setDefinitions] = useState<any[]>([]);
  const extendsFields = window.__config__.extendsFields;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      const user = JSON.parse(sessionStorage.getItem(RegisterInfoSessionKey) || "null");

      if (user) {
        const { data: dfs } = await Axios.get("/api/v2/udfs/definitions", {
          headers: {
            authorization: user.token,
          },
        });
        setDefinitions(dfs);
      }
    })();
  }, []);

  const onFinish = async (values: any) => {
    const internalFields: any = {};
    const userFields: {
      nameOrId: string;
      value: any;
    }[] = [];

    let user: User;

    Object.entries(values).forEach(([key, value]) => {
      const [type, nameOrId] = key.split(" ");
      if (type === "internal") {
        internalFields[nameOrId] = value;
      } else if (type === "user") {
        userFields.push({ nameOrId, value });
      }
    });

    const udfs = userFields.map(({ nameOrId, value }) => ({
      definition: definitions.find((def) => def.id === nameOrId),
      value,
    }));

    // 苏州园区 POC 特殊逻辑
    const realnameField = userFields.find((item) => item.nameOrId?.toLowerCase() === "realname");
    if (realnameField) {
      internalFields.familyName = realnameField.value?.[0];
      internalFields.givenName = realnameField.value?.slice?.(1);
    }

    let registerInfo: RegisterInfo | null = null;
    try {
      registerInfo = JSON.parse(sessionStorage.getItem(RegisterInfoSessionKey) || "null");
    } catch (e) {
      // do nothing
    }

    if (registerInfo) {
      /** 从注册页面过来 */
      setLoading(true);
      user = await registerUser(registerInfo, {
        completeUserInfo: internalFields,
        completeUdfsInfo: udfs.map((udv) => ({
          key: udv.definition.key,
          value: udv.value,
        })),
        onFail() {
          setLoading(false);
        },
      });
      sessionStorage.removeItem(RegisterInfoSessionKey);

      setTimeout(() => {
        navigate("/login");
      }, 500);
    } else {
      /** 从登录页面过来 */

      setLoading(true);
      try {
        user = await window.__authing__.updateProfile(internalFields);

        await Axios.post(
          `/api/v2/udfs/values`,
          {
            udfs,
          },
          {
            headers: {
              authorization: user?.token,
            },
          }
        );
        message.success(t("common.operateSuccess"));

        setTimeout(() => {
          loginSuccessRedirect(user);
        }, 500);
      } catch (e) {
        setLoading(false);
        return;
      } finally {
      }
    }
  };

  const items = extendsFields.map((def) => {
    const key = `${def.type} ${def.type === "internal" ? def.name : def.id}`;

    return (
      <Form.Item
        rules={[
          {
            required: true,
            message: `${def.label} 不能为空`,
          },
        ]}
        key={key}
        name={key}
        label={def.label}
        style={{ marginBottom: 8 }}
      >
        {INTERNAL_INPUT_MAP[def.name] || UDF_INPUT_MAP[def.inputType] || <Input type={def.inputType} size="large" />}
      </Form.Item>
    );
  });

  return (
    <Container minHeight="auto">
      <h1 className={classnames(styles.title, "authing-complete-user-info-title")}>{t("common.perfectUserInfo")}</h1>

      <div className={classnames(styles.subTitle, "authing-complete-user-info-sub-title")}>
        <p>
          {t("common.welcomeJoinAndPerfect", {
            name: window.__config__.name,
          })}
        </p>
        <img
          className={classnames(styles.tipImg, "authing-complete-user-tip-img")}
          src={getImgSrc("/20200904122043.svg")}
          alt=""
        />
      </div>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        {items}

        <SizeBox height={20} />
        <Form.Item>
          <Button
            loading={loading}
            block
            size="large"
            type="primary"
            htmlType="submit"
            className="authing-complete-user-info-btn"
          >
            {t("common.submit")}
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};
