import { isPhone } from "../../utils";
import { message } from "antd";
import React, { FC } from "react";
import { SendCode } from "../SendCode";
import { useTranslation } from "react-i18next";

export interface SendPhoneCodeProps {
  phone: string;
}

export const SendPhoneCode: FC<SendPhoneCodeProps> = ({ phone }) => {
  const { t } = useTranslation();

  return (
    <SendCode
      beforeSend={async () => {
        if (!isPhone(phone)) {
          message.error(t("common.phoneFormateError"));
          return false;
        }
        try {
          await window.__authing__.sendSmsCode(phone);
          return true;
        } catch (error) {
          return false;
        }
      }}
    />
  );
};
