import React, { FC, useState, useRef, useEffect } from "react";
import { RouterLink } from "../../components/RouterLink";
import { Header } from "../../components/Header";
import { Tabs, Button } from "antd";
import { PhoneRegisterForm } from "../../components/forms/register/PhoneRegisterForm";
import { Container } from "../../components/Container";
import styles from "./styles.module.less";
// import { ReadAndAccept } from "../../components/ReadAndAccept";
import { EmailRegisterForm } from "../../components/forms/register/EmailRegisterForm";
import { createForm } from "../../hooks/createForm";
import { AuthingTabs } from "../../components/AuthingTabs/index";
import { useNavigateWithSearch } from "../../hooks/useNavigateWithSearch";
import { useTitle } from "../../hooks/useTitle";
import querystring from "query-string";
import { track } from "../../utils/sensors";
import { noviceEventBus } from "../../components/Guidance/utils";
import { useTranslation } from "react-i18next";
import { REGISTER_METHODS_MAP } from "@/constants/enum";
import { RegisterInfo } from "@/types/user";
import { registerUser, useGotoRegisterComplete } from "./registerUser";
import { ReadAndAccept } from "@/components/ReadAndAccept";
import classnames from "classnames";
import { i18n } from "@/locales";
import { ToggleLang } from "@/components/ToggleLang";

const { TabPane } = Tabs;

const getDefaultTab = (tab: string, tabs: string[]) => {
  return tabs.find((t) => t === tab) || tabs[0];
};

export const RegisterPage: FC = () => {
  const { t } = useTranslation();

  useTitle(t("common.register"));

  const config = window.__config__;
  const formsRef = useRef<any>({});
  const [activeTabKey, setActiveTabKey] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigateWithSearch();
  const [needComplete, gotoComplete] = useGotoRegisterComplete();
  /** 表单是否被提交校验过 */
  const [validated, setValidated] = useState(false);

  const agreements = config.agreements.filter((item) => item.lang === i18n.language);

  useEffect(() => {
    const query = querystring.parse(window.location.search);
    const tab = query["tab"] as string;
    setActiveTabKey(getDefaultTab(tab || config.registerTabs.default, config.registerTabs.list));
  }, [config.registerTabs.default, config.registerTabs.list]);

  const submit = async () => {
    if (loading) return;

    // 提交表单
    await formsRef.current[activeTabKey].current?.submit();
    noviceEventBus.emit("next");
  };

  const onSuccess = async (registerInfo: RegisterInfo) => {
    track("Register", {
      from: new URLSearchParams(window.location.search).get("from_origin") || document.referrer,
    });

    setValidated(true);

    if (config.agreementEnabled && agreements?.length && !accepted) {
      return;
    }

    setLoading(true);

    if (needComplete) {
      gotoComplete(registerInfo);
    } else {
      await registerUser(registerInfo, {
        onFail,
      });
      setLoading(false);
      navigate("/login");
    }
  };

  const onFail = (error: any) => {
    setLoading(false);
    console.error(error);
  };

  const formsMap: { [key: string]: any } = {
    phone: PhoneRegisterForm,
    email: EmailRegisterForm,
  };

  const tabPanes = config.registerTabs.list
    // 过滤掉不支持的方式
    .filter((tab) => Object.keys(formsMap).includes(tab))
    .map((tab) => {
      const Form = formsMap[tab];
      const ref = (formsRef.current[tab] = createForm());
      const title = REGISTER_METHODS_MAP[tab];
      return (
        <TabPane tab={title} key={tab}>
          <Form ref={ref} onSubmit={() => {}} onSuccess={onSuccess} onFail={onFail} />
        </TabPane>
      );
    });

  return (
    <Container>
      <Header
        title={t("common.welcomeJoin", {
          name: config.name,
        })}
        logo={config.logo}
      />
      {config.ssoPageComponentDisplay.registerMethodNav && (
        <AuthingTabs centered activeKey={activeTabKey} onChange={setActiveTabKey}>
          {tabPanes}
        </AuthingTabs>
      )}

      {config.agreementEnabled && Boolean(config.agreements?.length) && (
        <ReadAndAccept onChange={setAccepted} agreements={agreements} showError={validated} />
      )}

      <div style={{ marginBottom: 20 }}></div>

      <div style={{ marginBottom: 12 }}></div>

      <div
        className="hide-in-desktop"
        style={{
          flex: 1,
        }}
      ></div>

      {config.ssoPageComponentDisplay.registerBtn && (
        <Button
          data-novice-focus="6"
          data-novice-action="6"
          className={classnames(styles.registerBtn, "authing-register-btn")}
          loading={loading}
          block
          size="large"
          type="primary"
          onClick={submit}
        >
          {t("common.register")}
        </Button>
      )}

      <div className={classnames(styles.gotoLogin, "authing-has-acc")}>
        {/* {t("common.alreadyHasAcc")} */}
        <RouterLink to="/">{t("common.loginImmediate")}</RouterLink>
      </div>

      <div style={{ flex: 1 }}></div>

      <div className={classnames(styles.layoutFlex, "hide-in-mobile")}>
        <ToggleLang />
        <div
          className={classnames(styles.problem, "authing-guard-feedback-btn", "hide-in-mobile")}
          onClick={() => {
            navigate("/feedback");
          }}
          style={{
            textAlign: "right",
          }}
        >
          {t("common.quesitons")}
        </div>
      </div>
    </Container>
  );
};
