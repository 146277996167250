import { User } from "authing-js-sdk";
import validator from "validator";
import { PasswordSecurityLevel } from "@/constants/enum";
import shortid from "shortid";
import qs from "qs";

/**
 * 支持 await 的休眠函数
 * @param second 秒数
 */
export const sleep = (second: number) =>
  new Promise((resolve) => {
    setTimeout(() => {
      // @ts-ignore
      resolve();
    }, second * 1000);
  });

/**
 * 在屏幕中心弹出新窗口加载 url
 * @param url
 * @param param1
 */
export const popupCenter = (url: string, { w, h }: { w: number; h: number } = { w: 585, h: 649 }) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    "_blank",
    `
      toolbar=no,
      menubar=no,
      scrollbars=no,
      resizable=no,
      location=no,
      status=no
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
  );

  newWindow?.focus();
};

export const getDisplayName = (user: User) => {
  if (!user) return "";

  return (
    user.nickname ||
    user.name ||
    user.username ||
    user.givenName ||
    user.familyName ||
    user.email ||
    user.phone ||
    user.id
  );
};

export const isEmail = validator.isEmail;

export const PATTERN = {
  phone: /^1[3-9]\d{9}$/,
};

export const isPhone = (value: string) => {
  return PATTERN.phone.test(value);
};

export const isIp = (str: string) => {
  return /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/.test(str);
};

export const isHost = (str: string) => {
  return /^[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+.?$/.test(str);
};
/**
 * 传对象 {'background-color': 'red'}
 * 传字符串 "CSS 样式"
 */
export const addStyles = (styles: string | any) => {
  let styleElt, styleSheet;
  if ((document as any).createStyleSheet) {
    // IE
    styleSheet = (document as any).createStyleSheet();
  } else {
    let head = document.getElementsByTagName("head")[0];
    styleElt = document.createElement("style");
    head.appendChild(styleElt);
    styleSheet = document.styleSheets[document.styleSheets.length - 1];
  }
  if (typeof styles === "string") {
    if (styleElt) styleElt.innerHTML = styles;
    else styleSheet.cssText = styles; // IE
  } else {
    let i = 0;
    for (let selector in styles) {
      if (styleSheet.insertRule) {
        let rule = selector + " {" + styles[selector] + "}";
        styleSheet.insertRule(rule, i++);
      } else {
        styleSheet.addRule(selector, styles[selector], i++);
      }
    }
  }
};

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export const getImgSrc = (path: string) => {
  return `${window.__cdnBase__}${path}`;
};

export const basePath = "http://192.168.50.175:3000";

/**
 * 判断密码强度
 * @param password
 */
export function getPasswordSecurityLevel(password: string): PasswordSecurityLevel {
  const highLevel = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{12,}$/g);
  const middleLevel = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)[^]{8,}$/g);
  if (password.match(highLevel) !== null) {
    return PasswordSecurityLevel.HIGH;
  }
  if (password.match(middleLevel) !== null) {
    return PasswordSecurityLevel.MIDDLE;
  }
  return PasswordSecurityLevel.LOW;
}

export const getCaptchaUrl = () => `/api/v2/security/captcha?r=${shortid()}&userpool_id=${window.__userPoolId__}`;

export const isWechatBrowser = () => /MicroMessenger/i.test(navigator.userAgent);

// 解析url参数
export const getUserRegisterParams = () => {
  const query = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  return query;
};

// 解析 location search 参数
export const parseLocationSearchUrl = (search: string) => {
  search = search.slice(1);
  return qs.parse(decodeURIComponent(search));
};

export const lazyLoading = async (cb: (...args: any[]) => any, setLoading: (bool: boolean) => void) => {
  try {
    setLoading(true);
    return await cb();
  } finally {
    setLoading(false);
  }
};
