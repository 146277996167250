import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { Input, Form } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { FormInstance } from "../../../../hooks/createForm";
import { User } from "authing-js-sdk";
import { useTranslation } from "react-i18next";
import { getCaptchaUrl } from "@/utils";

export interface LdapLoginFormProps extends React.HTMLAttributes<HTMLDivElement> {
  onSubmit?: () => void;
  onSuccess?: (user: User) => void;
  onFail?: (error: any) => void;
  onFinishFailed?: (error: any) => void;
}

const InternalLdapLoginForm: ForwardRefRenderFunction<FormInstance, LdapLoginFormProps> = (
  { onSubmit, onSuccess, onFail, onFinishFailed },
  ref
) => {
  const [rawForm] = Form.useForm();
  const config = window.__config__;
  const [needVerifyCode, setNeedVerifyCode] = useState(false);
  const [verifyCodeUrl, setVerifyCodeUrl] = useState<string | null>(null);
  const { t } = useTranslation();

  const onFinish = async (values: any) => {
    try {
      const authenticationClient = window.__authing__;
      const identity = values.identity && values.identity.trim();
      const password = values.password && values.password.trim();
      const captchaCode = values.captchaCode && values.captchaCode.trim();
      const autoRegister = config.ssoPageComponentDisplay.autoRegisterThenLoginHintInfo;

      const user = await authenticationClient.loginByLdap(identity, password, { autoRegister, captchaCode });
      onSuccess && onSuccess(user);
    } catch (error) {
      if (typeof error.message === "string") {
        // js sdk httpclient 的报错，这里只有一种情况就是用户开启了 mfa 的报错
        try {
          const errorData = JSON.parse(error.message);
          onFail && onFail(errorData);
          return;
        } catch (_) {}
      }
      onFail && onFail(error);

      if (error.code === 2000 && verifyCodeUrl === null) {
        setNeedVerifyCode(true);
        setVerifyCodeUrl(getCaptchaUrl());
      }
    }
  };

  useImperativeHandle(ref, () => ({
    submit: async () => {
      try {
        onSubmit && onSubmit();
        await rawForm.validateFields();
        rawForm.submit();
      } catch (error) {
        onFail && onFail(error);
      }
    },
  }));

  return (
    <Form form={rawForm} onSubmitCapture={onSubmit} onFinishFailed={onFinishFailed} onFinish={onFinish}>
      <button type="submit" hidden></button>

      {config.ssoPageComponentDisplay.userPasswordInput && (
        <>
          <Form.Item
            name="identity"
            rules={[
              {
                required: true,
                message: t("login.inputLdapUsername"),
              },
            ]}
          >
            <Input
              autoComplete="email,username,tel"
              size="large"
              placeholder={t("login.inputLdapUsername")}
              prefix={<UserOutlined style={{ color: "#ddd" }} />}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("login.inputLdapPwd"),
              },
            ]}
          >
            <Input.Password
              autoComplete="current-password"
              size="large"
              visibilityToggle={false}
              placeholder={t("login.inputLdapPwd")}
              prefix={<LockOutlined style={{ color: "#ddd" }} />}
            />
          </Form.Item>
          {needVerifyCode && (
            <Form.Item
              name="captchaCode"
              rules={[
                {
                  required: true,
                  message: t("login.inputCaptchaCode"),
                },
              ]}
            >
              <Input
                autoComplete="one-time-code"
                size="large"
                placeholder={t("login.inputCaptchaCode")}
                addonAfter={
                  <img
                    src={verifyCodeUrl ?? ""}
                    alt={t("login.captchaCode")}
                    style={{ height: "2em", cursor: "pointer" }}
                    onClick={() => setVerifyCodeUrl(getCaptchaUrl())}
                  />
                }
              />
            </Form.Item>
          )}
        </>
      )}
    </Form>
  );
};

export const LdapLoginForm = forwardRef<FormInstance, LdapLoginFormProps>(InternalLdapLoginForm);
