import { Input, Table, Tag } from "antd";
import { User } from "authing-js-sdk";
import React, { FC, useMemo, useRef, useState } from "react";
import styles from "../style.module.less";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { LangxinIconFont } from "@/components/LangxinIconFont";
import { fetchUsers, IUserOrg } from "@/api/user";

interface IOrgTableProps {
  userList: {
    totalCount?: number;
    list?: Array<User>;
    page?: number;
  };
  treeData: Partial<IUserOrg[]>;
  getDepartmentUsers: (id: string, page: number) => void;
  selectedNode: any;
  setUserList: (o: { totalCount?: number; list?: Array<User>; page: number }) => void;
  isSearch: boolean;
  setIsSearch: (bool: boolean) => void;
}

const type = "DragableBodyRow";
const PAGE_SIZE = 6;

const DragableBodyRow: FC<any> = ({ index, moveRow, className, style, record, ...restProps }) => {
  const ref = useRef();
  const onDragStart = (e: any) => {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("user/info", JSON.stringify(record || {}));
  };
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = (monitor.getItem() || {}) as any;
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
      onDragStart={onDragStart}
    />
  );
};

export const OrgTable: FC<IOrgTableProps> = ({
  userList,
  treeData,
  getDepartmentUsers,
  selectedNode,
  setUserList,
  setIsSearch,
  isSearch,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const columns = useMemo(() => {
    return [
      {
        title: "姓名",
        key: "username",
        width: 180,
        render: (record: User) => {
          const name = record.username || "";
          if (!name) return "-";
          return (
            <div className={styles.userLabel}>
              <img src={record.photo ?? ""} alt="" className={styles.photo} />
              <div className={styles.name}>
                {name.length > 1 ? name[0] + new Array(name.slice(1).length).fill("*").join("") : name}
              </div>
            </div>
          );
        },
      },
      {
        title: "手机",
        dataIndex: "phone",
        key: "phone",
        render: (phone: string | number) => {
          if (!phone) return "-";
          phone = String(phone);
          return phone.slice(0, 3) + "*****" + phone.slice(8);
        },
      },
      {
        title: "邮箱",
        dataIndex: "email",
        key: "email",
        render: (email: string) => email ?? "-",
      },
      {
        title: "认证状态",
        key: "status",
        render: (record: any) => {
          const colors = [
            {
              color: "gold",
              text: "认证中",
            },
            {
              color: "green",
              text: "已认证",
            },
          ];
          const data = colors[1];
          return <Tag color={data.color}>{data.text}</Tag>;
        },
      },
    ];
  }, []);

  const search = async (page: number) => {
    const res = await fetchUsers({ nodeId: selectedNode.id, page, limit: PAGE_SIZE, search: searchValue });
    setUserList({ ...res, page });
  };

  return (
    <div className={styles.tableWrap}>
      <div className={styles.search}>
        <Input
          placeholder="请输入成员名称或手机号"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          suffix={<LangxinIconFont type="langxin-iconsearch" style={{ color: "rgba(81, 91, 120, 1)" }} />}
          style={{ width: 221 }}
          onPressEnter={async () => {
            setIsSearch(true);
            search(1);
          }}
        />
      </div>

      <DndProvider backend={HTML5Backend}>
        <Table
          columns={columns}
          dataSource={userList.list ?? []}
          components={{
            body: {
              row: DragableBodyRow,
            },
          }}
          rowKey="id"
          pagination={{
            size: "small",
            pageSize: PAGE_SIZE,
            current: userList.page,
            total: userList.totalCount,
            onChange: (page) => {
              isSearch ? search(page) : getDepartmentUsers(selectedNode.id, page);
            },
          }}
          // @ts-ignore
          onRow={(record, index) => ({
            index,
            record,
          })}
        />
      </DndProvider>
    </div>
  );
};
