import React, { FC, useCallback, useEffect, useState } from "react";
import styles from "./style.module.less";
import { LeftTree } from "./LeftTree";
import { OrgTable } from "./OrgTable";
import { getOrgUserList, getUserOrg, IUserOrg } from "@/api/user";
import { Spin } from "antd";
import { formatTree } from "../../constants";
import _ from "lodash";
import { User } from "authing-js-sdk";

interface IOrgProps {}

const PAGE_SIZE: number = 6;

export const Org: FC<IOrgProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [orgTree, setOrgTree] = useState<Partial<IUserOrg[]>>([]);
  const [selectedNode, setSelectedNode] = useState<any>({});
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [userList, setUserList] = useState<{
    totalCount?: number;
    list?: Array<User>;
    page: number;
  }>({ page: 1 });

  const loadingEffect = async (cb: (...args: any) => Promise<any>) => {
    try {
      setLoading(true);
      await cb();
    } finally {
      setLoading(false);
    }
  };

  const getOrgTree = useCallback(() => {
    loadingEffect(async () => {
      const treeData = await getUserOrg();
      setOrgTree(formatTree([_.cloneDeep(treeData)]));
    });
  }, []);

  const getDepartmentUsers = (id: string, page: number = userList.page) => {
    loadingEffect(async () => {
      const userList = await getOrgUserList(id, { page, limit: PAGE_SIZE });
      setUserList({ ...userList, page });
    });
  };

  useEffect(() => {
    getOrgTree();
  }, [getOrgTree]);

  return (
    <Spin spinning={loading}>
      <div className={styles.orgContainer}>
        <LeftTree
          treeData={orgTree}
          getDepartmentUsers={getDepartmentUsers}
          getOrgTree={getOrgTree}
          selectedNode={selectedNode}
          setSelectedNode={setSelectedNode}
          setIsSearch={setIsSearch}
        />
        <OrgTable
          userList={userList}
          treeData={orgTree}
          getDepartmentUsers={getDepartmentUsers}
          selectedNode={selectedNode}
          setUserList={setUserList}
          setIsSearch={setIsSearch}
          isSearch={isSearch}
        />
      </div>
    </Spin>
  );
};
