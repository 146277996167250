import classnames from "classnames";
import { Input } from "antd";
import React, { FC, Fragment, useRef } from "react";
import styles from "./styles.module.less";

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  verifyCode: string[];
  setVerifyCode: (code: string[]) => void;
  length?: number;
  size?: string;
  gutter?: string;
  onEenter?: Function;
}

export const VerifyCodeInput: FC<IProps> = ({
  size = "48px",
  gutter = "30px",
  className,
  verifyCode,
  setVerifyCode,
  length = 6,
  onEenter,
  ...rest
}) => {
  const inputRef = useRef<any[]>([]);

  const handleChange = (val: string | undefined = "", index: number) => {
    const num = parseInt(val);
    if (isNaN(num)) {
      val = "";
    } else {
      val = String(num);
    }
    const codes = [...verifyCode];
    codes[index] = val.split("").slice(-1)[0] || "";
    setVerifyCode(codes);

    if (val && inputRef.current[index + 1]) {
      inputRef.current[index + 1].focus();
    }
  };

  const handleKeyDown = (evt: any, index: number) => {
    const currentVal = verifyCode[index];

    switch (evt.keyCode) {
      case 8:
        if (!currentVal && inputRef.current[index - 1]) {
          handleChange("", index - 1);
          inputRef.current[index - 1].focus();
        }
        break;

      case 13:
        onEenter?.();
        break;
      default:
        break;
    }
  };

  return (
    <div className={classnames([className, styles.verifyCodeInputWrapper])} {...rest}>
      {new Array(length).fill(0).map((_, index) => (
        <Fragment key={index}>
          <Input
            ref={(el) => (inputRef.current[index] = el)}
            style={{
              width: size,
              minWidth: size,
              minHeight: size,
              height: size,
              lineHeight: size,
              marginLeft: index === 0 ? 0 : gutter,
            }}
            className={classnames([styles.verifyCodeInput, "authing-verify-code-input"])}
            size="large"
            autoFocus={index === 0}
            onKeyDown={(evt) => handleKeyDown(evt, index)}
            value={verifyCode[index]}
            onChange={(evt) => {
              evt.persist()
              // @ts-ignore
              if (evt.nativeEvent.isComposing) {
                return
              }
              handleChange(evt.target.value, index);
            }}
          />
          {index === Math.floor(length / 2 - 1) && (
            <div
              style={{
                marginLeft: gutter,
              }}
              className={styles.splitLine}
            ></div>
          )}
        </Fragment>
      ))}
    </div>
  );
};
