import React from "react";
import { useLocation } from "react-router";
import qs from "query-string";
import { useTitle } from "@/hooks/useTitle";
import styles from "./styles.module.less";

export const AgreementPage = () => {
  const { search } = useLocation();
  const { agreement_id: agreementId } = qs.parse(search);

  const config = window.__config__;

  const agreementDetail = config?.agreements?.find((item) => item.id === Number(agreementId));

  useTitle(agreementDetail?.title || "");

  return (
    <div
      style={{
        flex: 1,
        alignSelf: "stretch",
        backgroundColor: "#f1f1f1",
      }}
    >
      <header className={styles.agreementHeader}>
        <div
          style={{
            height: "100%",
            maxWidth: 1200,
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          <img alt="logo" height="35px" src={config.logo} />
          <h3
            style={{
              margin: "0 0 0 12px",
            }}
          >
            {config.name}
          </h3>
        </div>
      </header>

      <section className={styles.agreementContent}>
        <div
          dangerouslySetInnerHTML={{
            // @ts-ignore
            __html: agreementDetail?.content || "",
          }}
        ></div>
      </section>
    </div>
  );
};
