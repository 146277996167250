import { noviceEventBus } from "./utils";
import { i18n } from "@/locales";

export const steps = [
  {
    target: "[data-novice-focus='0']",
    title: "创建用户池",
    content: "用户池帮助你和你的应用即刻实现安全、高效的用户账号系统，快来创建你的第一个用户池吧！",
    disableBeacon: true,
    onNext() {
      (document.querySelector("[data-novice-action='0']") as HTMLElement)?.click?.();
    },
  },
  {
    target: "[data-novice-focus='1']",
    title: "创建用户池",
    content: "新建用户池时需要填写用户池的名称与自定义的域名，我们已经帮你填好了，直接点击确认按钮完成创建吧！",
    disableBeacon: true,
    disableOverlay: false,
    onNext() {
      (document.querySelector("[data-novice-action='1']") as HTMLElement)?.click?.();
    },
  },
  {
    target: "[data-novice-focus='2']",
    title: "创建应用",
    content: "用户池创建好了，接着你需要创建使用当前用户池的应用",
    disableBeacon: true,
    disableOverlay: false,
    onNext() {
      (document.querySelector("[data-novice-action='2']") as HTMLElement)?.click?.();

      noviceEventBus.emit("next");
    },
  },
  {
    target: "[data-novice-focus='3']",
    title: "创建应用",
    content: "直接点击确认按钮完成创建吧！",
    disableBeacon: true,
    disableOverlay: false,
    onNext() {
      (document.querySelector("[data-novice-action='3']") as HTMLElement)?.click?.();
    },
  },
  {
    target: "[data-novice-focus='4']",
    title: "体验应用",
    content:
      "还记得吗？Authing 的核心功能之一，就是帮助你在几秒钟之内构建用户系统并实现注册登录功能，现在点击试试注册与登录吧！",
    disableBeacon: true,
    disableOverlay: false,
    onNext() {
      (document.querySelector("[data-novice-action='4']") as HTMLElement)?.click?.();
    },
  },
  {
    target: "[data-novice-focus='5']",
    title: i18n.t("common.guidance.registerAcc"),
    content: i18n.t("common.guidance.registerAccStep1"),
    disableBeacon: true,
    disableOverlay: false,
    onNext() {
      (document.querySelector("[data-novice-action='5']") as HTMLElement)?.click?.();
    },
  },
  {
    target: "[data-novice-focus='6']",
    title: i18n.t("common.guidance.registerAcc"),
    content: i18n.t("common.guidance.registerAccStep2"),
    disableBeacon: true,
    disableOverlay: false,
    onNext() {
      (document.querySelector("[data-novice-action='6']") as HTMLElement)?.click?.();
    },
  },
  {
    target: "[data-novice-focus='7']",
    title: i18n.t("common.guidance.loginApp"),
    content: i18n.t("common.guidance.loginAppStep1"),
    disableBeacon: true,
    disableOverlay: false,
    onNext() {
      (document.querySelector("[data-novice-action='7']") as HTMLElement)?.click?.();
    },
  },
  {
    target: "[data-novice-focus='8']",
    title: "用户列表",
    content: "你的用户池账号在这里统一管理，就在刚刚你注册了一个用户名为 xxx 的新用户，快去看看吧！",
    disableBeacon: true,
    disableOverlay: false,
    onNext() {
      noviceEventBus.emit("finish");
    },
  },
];
