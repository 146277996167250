import React, { useState, forwardRef, useImperativeHandle, useRef, FC } from "react";
import { Button, Form, Input, message, Space } from "antd";
import { useTranslation } from "react-i18next";
import { FormInstance } from "antd/lib/form";
import { User } from "authing-js-sdk";
import Modal from "antd/lib/modal/Modal";
import { PasswordLevel } from "@/components/PasswordLevel";
import { getPasswordSecurityLevel } from "@/utils";
import { getPasswordValidate } from "@/utils/formRules";
import { PASSWORD_STRENGTH_TEXT_MAP } from "@/constants/enum";
import Axios from "axios";
import { Lang } from "@/locales";

export const UpdatePassword = forwardRef<
  FormInstance,
  {
    beforeSubmit?: () => void;
    submitFinally?: () => void;
    submitSuccess?: (user: User) => void;
    hideSubmitBtn?: boolean;
    showPwdLevel?: boolean;
  }
>(({ beforeSubmit, submitFinally, submitSuccess, hideSubmitBtn = false, showPwdLevel }, ref) => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const passwordStrength = window.__config__.passwordStrength;

  useImperativeHandle(ref, () => form);

  return (
    <Form
      form={form}
      hideRequiredMark
      labelAlign="left"
      labelCol={{ span: i18n.language === Lang.zhCn ? 2 : 5 }}
      onFinish={async (values) => {
        try {
          beforeSubmit?.();
          setLoading(true);
          window.__user__ = await window.__authing__.updatePassword(password, values.oldPassword);
          setPassword("");
          form.resetFields();
          message.success(t("common.updateSuccess"));
          submitSuccess?.(window.__user__);

          // 登出
          await Axios.post("/logout");
          if (await window.__authing__.getCurrentUser()) {
            await window.__authing__.logout();
          }
          window.location.href = "/login";
        } finally {
          setLoading(false);
          submitFinally?.();
        }
      }}
    >
      {Boolean(window.__user__?.password) && (
        <Form.Item
          name="oldPassword"
          label={t("user.currentPwd")}
          rules={[{ required: true, message: t("login.noEmpty") }]}
        >
          <Input.Password
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            placeholder={t("user.inputCurrPwd")}
          />
        </Form.Item>
      )}

      <Form.Item
        name="newPassword"
        label={t("user.newPwd")}
        rules={getPasswordValidate(passwordStrength)}
        help={
          showPwdLevel && (
            <div
              style={{
                marginTop: 4,
              }}
            >
              <PasswordLevel level={getPasswordSecurityLevel(password)} />
            </div>
          )
        }
      >
        <Input.Password
          autoComplete="new-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder={PASSWORD_STRENGTH_TEXT_MAP[passwordStrength].placeholder}
        />
      </Form.Item>

      <Form.Item
        name="repeatPassword"
        label={t("user.confrimPwd")}
        rules={[
          { required: true, message: t("login.noEmpty") },
          {
            validator: (_, value, callback) => {
              if (form.getFieldValue("newPassword") !== value) {
                callback(t("user.needSameWithNewPwd"));
              } else {
                callback();
              }
            },
          },
        ]}
      >
        <Input.Password autoComplete="new-password" placeholder={t("user.inputNewPwd")} />
      </Form.Item>

      {hideSubmitBtn ? (
        <Button hidden htmlType="submit"></Button>
      ) : (
        <Form.Item wrapperCol={{ offset: 2 }}>
          <Button loading={loading} type="primary" htmlType="submit">
            {t("user.confirmModify")}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
});

export const UpdatePasswordModal: FC<{
  onSuccess: (user: User) => void;
  visible: boolean;
  setVisible: (v: boolean) => void;
  showPwdLevel?: boolean;
}> = ({ onSuccess, visible, setVisible, showPwdLevel }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormInstance>(null);

  return (
    <Modal
      title={t("user.modifyPwd")}
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={
        <Space size={11}>
          <Button onClick={() => setVisible(false)}>{t("common.cancel")}</Button>
          <Button type="primary" onClick={() => formRef.current?.submit()} loading={loading}>
            {t("common.sure")}
          </Button>
        </Space>
      }
    >
      <UpdatePassword
        ref={formRef}
        showPwdLevel={showPwdLevel}
        beforeSubmit={() => setLoading(true)}
        submitFinally={() => setLoading(false)}
        hideSubmitBtn
        submitSuccess={(user) => {
          setVisible(false);
          onSuccess(user);
        }}
      />
    </Modal>
  );
};
