import { getUserBusinessLogs } from "@/api/user";
import { lazyLoading } from "@/utils";
import { formatTime } from "@/utils/formRules";
import { Table } from "antd";
import qs from "query-string";
import React, { FC, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { baseMenusText, logSelectedType, LOG_SELECTED_TYPE } from "../../constants";
import { Card } from "../Card";
import styles from "./style.module.less";

interface IBusinessLogCardProps {}

export const BusinessLogCard: FC<IBusinessLogCardProps> = (props) => {
  const [pageData, setPageData] = useState({ page: 1, total: 0, pageSize: 5, list: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getLogList = async (page: number) => {
    lazyLoading(async () => {
      const res = await getUserBusinessLogs({ page, limit: pageData.pageSize });
      setPageData({ ...pageData, page, total: res.count, list: res.list as any });
    }, setLoading);
  };

  useEffectOnce(() => {
    getLogList(1);
  });

  const columns = useMemo(() => {
    return [
      {
        title: "应用名称",
        dataIndex: "appName",
        key: "appName",
      },
      {
        title: "操作类型",
        dataIndex: "operationDesc",
        key: "operationDesc",
      },
      {
        title: "操作时间",
        dataIndex: "timestamp",
        key: "timestamp",
        render: (timestamp: string) => formatTime(timestamp, 2),
      },
    ];
  }, []);

  return (
    <Card
      className={styles.businessLogCardContainer}
      title="业务行为日志"
      style={{
        width: 584,
      }}
      loading={loading}
      topRightComponent={
        <span
          className={styles.lookMore}
          onClick={() => {
            navigate(
              `/u?${qs.stringify({
                ...qs.parse(location.search),
                user_active_tab: baseMenusText.USER_LOGS,
                [LOG_SELECTED_TYPE]: logSelectedType.BUSINESS_ACTION_LOG,
              })}`
            );
          }}
        >
          查看更多
        </span>
      }
    >
      <Table
        columns={columns}
        dataSource={pageData?.list ?? []}
        size="small"
        rowKey={(_record, index) => `${index}`}
        pagination={{
          pageSize: pageData.pageSize,
          current: pageData.page,
          total: pageData.total,
          onChange: (page) => {
            getLogList(page);
          },
          simple: true,
        }}
      />
    </Card>
  );
};
