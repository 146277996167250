import React, { FC, useEffect, useMemo, useState } from "react";
import styles from "./style.module.less";
import { authStepMap } from "./constants";
import { StartAuth } from "./StartAuth";
import { SuccessAuth } from "./SuccessAuth";
import { ProcessingAuth } from "./ProcessingAuth";
import { getUserAuthMap } from "@/api/langxinApp";

interface IRealNameAuthProps {}

export const RealNameAuth: FC<IRealNameAuthProps> = (props) => {
  const [authStatus, setAuthStatus] = useState(authStepMap.START_AUTH);

  const authStepComponentMap = useMemo(() => {
    return {
      [authStepMap.START_AUTH]: <StartAuth updateAuthStatus={setAuthStatus} />,
      [authStepMap.PROCESSING_AUTH]: <ProcessingAuth updateAuthStatus={setAuthStatus} />,
      [authStepMap.SUCCESS_AUTH]: <SuccessAuth updateAuthStatus={setAuthStatus} />,
    };
  }, []);

  useEffect(() => {
    getUserAuthMap().then((res) => {
      res?.isVerified === 1 && setAuthStatus(authStepMap.SUCCESS_AUTH);
    });
  }, []);

  return <div className={styles.realNameAuthContainer}>{authStepComponentMap[authStatus]}</div>;
};
