import React, { FC } from "react";
import styles from "../style.module.less";
import { ShowCard } from "../../components/ShowCard";
import { getImgSrc } from "@/utils";
import { Button, message, Space, Steps } from "antd";
import { authStepMap } from "../constants";
import { LangxinIconFont } from "@/components/LangxinIconFont";
import { getUserAuthMap } from "@/api/langxinApp";
import { downloadAppText } from "../../constants";

const { Step } = Steps;
interface IProcessingAuthProps {
  updateAuthStatus: (a: string) => void;
}

export const ProcessingAuth: FC<IProcessingAuthProps> = ({ updateAuthStatus }) => {
  return (
    <div className={styles.processingAuthContainer}>
      <Space direction="vertical" size="large">
        <ShowCard
          firstTitle="请在“我爱崖州湾”手机  APP 中进行相应操作"
          secondTitle="实名认证当前仅支持移动端设备"
          imgProps={{
            src: getImgSrc("/langxin/undraw_Selfie_re_h9um.svg"),
            style: {
              height: 123,
            },
          }}
          actionButton={
            <Button
              style={{ width: 120 }}
              type="primary"
              onClick={async () => {
                const res = await getUserAuthMap();
                if (res?.isIdentity === 1) {
                  updateAuthStatus(authStepMap.SUCCESS_AUTH);
                  message.success("认证成功");
                } else {
                  message.warn("你还没有完成认证");
                }
              }}
            >
              完成认证
            </Button>
          }
        />
        <div className={styles.authStepContainer}>
          <div className={styles.left}>
            <h3 className={styles.label}>
              <LangxinIconFont type="langxin-iconquestion-fill" style={{ color: "#DD8050", marginRight: "4px" }} />
              实名认证帮助指南
            </h3>
            <Steps direction="vertical" size="small" current={-1} style={{ height: 250 }}>
              <Step
                title="打开“我爱崖州湾” APP"
                description={
                  <>
                    如果未下载应用，可以 <span style={{ color: "#DD8050" }}>在右侧进行扫码下载</span>
                  </>
                }
              />
              <Step title="在界面底部点击“我的” " />
              <Step title="选择“实名认证”" description="手机上完成人脸录入后，点击右上角完成录入按钮" />
            </Steps>
          </div>
          <div className={styles.right}>
            <h3>下载地址</h3>
            <div className={styles.middle}>
              <div className={styles.qrcode} style={{ marginRight: "36px" }}>
                <img src={downloadAppText.IOS_QRCODE_URL} alt="" />
                <a
                  className={styles.qrcodeTips}
                  href={downloadAppText.IOS_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  我爱崖州湾 iOS 版
                </a>
              </div>
              <div className={styles.qrcode}>
                <img src={downloadAppText.ANDROID_QRCODE_URL} alt="" />
                <a
                  className={styles.qrcodeTips}
                  href={downloadAppText.ANDROID_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  我爱崖州湾 Android 版
                </a>
              </div>
            </div>
            {/* <h4>下载地址</h4>
            <a href={download?.marketAddr} target="_blank" rel="noopener noreferrer">
              {download?.marketAddr ?? ""}
            </a> */}
          </div>
        </div>
      </Space>
    </div>
  );
};
