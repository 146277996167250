import React, { useState } from "react";
import { Select, Drawer } from "antd";
import { FC } from "react";
import styles from "./styles.module.less";
import { SelectProps } from "antd/lib/select";

export interface MobileSelectProps {
  onChange?: (value: string | number, option: any) => void;
  value?: string | number;
  options: {
    label: React.ReactNode;
    value: string | number;
  }[];
  style?: React.CSSProperties;
  className?: string;
  height?: number | string;
  size?: SelectProps<any>["size"];
  selectedRender?: (setOptsVisible: (val: boolean) => void, value?: string | number, option?: any) => React.ReactNode;
}
export const MobileSelect: FC<MobileSelectProps> = ({
  value,
  options,
  onChange,
  style,
  className,
  height,
  size = "large",
  selectedRender,
}) => {
  const [optsVisible, setOptsVisible] = useState(false);

  return (
    <>
      {selectedRender ? (
        selectedRender(
          setOptsVisible,
          value,
          options.find((item) => item.value === value)
        )
      ) : (
        <Select
          style={style}
          className={className}
          open={false}
          onClick={() => setOptsVisible(true)}
          options={options}
          value={value}
          size={size}
        ></Select>
      )}
      <Drawer
        className={styles.mobileSelectDropdown}
        height={height || 60 * options.length}
        visible={optsVisible}
        placement="bottom"
        closable={false}
        onClose={() => setOptsVisible(false)}
      >
        <div className={styles.optsContainer}>
          {options.map((item) => {
            return (
              <div
                onClick={() => {
                  onChange?.(item.value, item);
                  setOptsVisible(false);
                }}
                key={item.value}
                className={styles.optsItem}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </Drawer>
    </>
  );
};
