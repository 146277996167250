import { Button, Col, Modal, Row, Space } from "antd";
import { User } from "authing-js-sdk";
import React, { FC, useState } from "react";
import { BindEmail } from "./BindEmail";
import { BindPhone } from "./BindPhone";
import { Icon } from "./Icon";
import styles from "./styles.module.less";
import { UpdateEmail } from "./UpdateEmail";
import { UpdatePhone } from "./UpdatePhone";
import { useTranslation } from "react-i18next";

export const UserPageConnection: FC = () => {
  const [user, setUser] = useState(window.__user__);
  const { t } = useTranslation();
  const updateUser = (user: User) => {
    setUser(user);
    window.__user__ = user;
  };

  const [bindPhoneVisible, setBindPhoneVisible] = useState(false);
  const [updatePhoneVisible, setUpdatePhoneVisible] = useState(false);
  const renderBindPhone = () => (
    <BindPhone
      visible={bindPhoneVisible}
      onOk={(user) => {
        setBindPhoneVisible(false);
        updateUser(user);
      }}
      onCancel={() => setBindPhoneVisible(false)}
    />
  );
  const renderUpdatePhone = () =>
    user && (
      <UpdatePhone
        user={user}
        visible={updatePhoneVisible}
        onOk={(user) => {
          setUpdatePhoneVisible(false);
          updateUser(user);
        }}
        onCancel={() => setUpdatePhoneVisible(false)}
      />
    );

  const [bindEmailVisible, setBindEmailVisible] = useState(false);
  const [updateEmailVisible, setUpdateEmailVisible] = useState(false);
  const renderBindEmail = () => (
    <BindEmail
      visible={bindEmailVisible}
      onOk={(user) => {
        setBindEmailVisible(false);
        updateUser(user);
      }}
      onCancel={() => setBindEmailVisible(false)}
    />
  );
  const renderUpdateEmail = () =>
    user && (
      <UpdateEmail
        user={user}
        visible={updateEmailVisible}
        onOk={(user) => {
          setUpdateEmailVisible(false);
          updateUser(user);
        }}
        onCancel={() => setUpdateEmailVisible(false)}
      />
    );

  const items = [
    {
      key: "email",
      label: t("common.email"),
      value: user?.email,
      onBind: () => setBindEmailVisible(true),
      onUpdate: () => setUpdateEmailVisible(true),
      onUnbind: () =>
        Modal.confirm({
          title: t("login.confirmUnbindEmail"),
          okCancel: true,
          onOk: async () => {
            const user = await window.__authing__.unbindEmail();
            updateUser(user);
          },
        }),
    },
    {
      key: "phone",
      label: t("common.phoneNumber"),
      value: user?.phone,
      onBind: () => setBindPhoneVisible(true),
      onUpdate: () => setUpdatePhoneVisible(true),
      onUnbind: () =>
        Modal.confirm({
          title: t("login.confirmUnbinPhont"),
          okCancel: true,
          onOk: async () => {
            const user = await window.__authing__.unbindPhone();
            updateUser(user);
          },
        }),
    },
  ];

  return (
    <div className={styles.connection}>
      <h3>{t("login.accBind")}</h3>

      {renderBindPhone()}
      {renderUpdatePhone()}
      {renderBindEmail()}
      {renderUpdateEmail()}

      <Row>
        {items.map((item) => (
          <Col key={item.key} span={24} className={styles.row}>
            <Icon icon={item.key} active={!!item.value} />
            <span className={styles.label}>
              {item.label}
              {item.value && ":"}
            </span>
            <span className={styles.value}>{item.value}</span>
            <div style={{ flex: 1 }}></div>
            {item.value ? (
              <Space>
                {item.onUnbind && (
                  <Button style={{ width: 100 }} onClick={item.onUnbind}>
                    {t("login.releaseBind")}
                  </Button>
                )}

                <Button style={{ width: 100 }} onClick={item.onUpdate}>
                  {t("common.modify")}
                </Button>
              </Space>
            ) : (
              <Button type="primary" style={{ width: 100 }} onClick={item.onBind}>
                {t("common.bind")}
              </Button>
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};
