import React, { FC, useCallback, useEffect, useState } from "react";
import styles from "./style.module.less";
import { Card } from "../Card";
import { SearchOutlined } from "@ant-design/icons";
import { TitleCard } from "../TitleCard";
import { Input } from "antd";
import { isHost, isIp } from "@/utils";
import { Protocol } from "@/config";
import { ignoreApps } from "../../constants";
import { getAllCanAddApp, IAllCanAddApp } from "@/api/user";

interface IAppPanelCardProps {}

let APP_LIST: IAllCanAddApp[] = [];

export const AppPanelCard: FC<IAppPanelCardProps> = (props) => {
  const [appList, setAppList] = useState<IAllCanAddApp[]>();
  const [value, setValue] = useState<string>("");

  const getApps = useCallback(async () => {
    const data = await getAllCanAddApp();
    const list = data?.filter((app) => !ignoreApps.includes(app?.appId));
    APP_LIST = list;
    setAppList(list);
  }, []);

  useEffect(() => {
    if (!window.__user__) {
      window.location.href = "/login?logout=1";
      return;
    }
    getApps();
  }, [getApps]);

  const repleaceSecondLevelDomain = (target: string, source: string) => {
    if (!isIp(target) && isHost(target)) {
      const arr = target.split(".");
      arr[0] = source;
      return arr.join(".");
    }
    return target;
  };

  return (
    <Card
      title={"应用面板"}
      style={{
        width: 684,
        height: 270,
      }}
      topRightComponent={
        <Input
          style={{ width: "240px", height: "24px", fontSize: "12px" }}
          placeholder="请输入搜索应用名称"
          suffix={<SearchOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onPressEnter={() => setAppList([...APP_LIST]?.filter((info) => info.appName.includes(value)))}
        />
      }
      className={styles.appPanelCardWrap}
    >
      <div className={styles.list}>
        {appList &&
          appList.map((v) => {
            return (
              <TitleCard
                key={v.appId}
                title={v.appName}
                img={v.appLogo}
                className={styles.titleCardItem}
                onClick={() => {
                  if (v.protocol === Protocol.SAML) {
                    window.open(
                      `${window.location.protocol}//` +
                        `${repleaceSecondLevelDomain(window.location.host, v.identifier)}` +
                        `/api/v2/saml-idp/${v.appId}`
                    );
                  } else {
                    // 跳转到此应用的域名下
                    window.open(
                      `${window.location.protocol}//` +
                        `${repleaceSecondLevelDomain(window.location.host, v.identifier)}` +
                        `/login?app_id=${v.appId}`
                    );
                  }
                }}
              />
            );
          })}
      </div>
    </Card>
  );
};
