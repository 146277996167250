import React, { FC } from 'react';
// import { Avatar } from 'antd';
// import defalutLogo from '../../assets/image/logo.png';
import styles from './styles.module.less';
import classnames from 'classnames';
import { ScreenSize } from '@/constants/enum';
import { useScreenSize } from '@/hooks/useScreenSize';
import { MobileLangSelect } from '../MobileLangSelector';

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
	logo?: string;
	title: string;
	large?: boolean;
}

export const Header: FC<HeaderProps> = props => {
	// const { logo = defalutLogo, title, large } = props;
	const { large } = props;
	const [screenSize] = useScreenSize();

	// const logoElement = (
	// 	<Avatar
	// 		className={`${styles.logo} __authing-app-logo`}
	// 		src={logo}
	// 		size={screenSize === ScreenSize.Mobile ? 35 : large ? 70 : 50}
	// 	></Avatar>
	// );
	// const titleElement = <div className={classnames(styles.title, 'authing-header-title')}>{title}</div>;

	const cls = classnames(
		styles.container,
		{
			[styles.large]: large
		},
		'authing-guard-header-container'
	);
	return (
		<div className={cls}>
			{/* {logoElement}
      {titleElement} */}
			<span
				style={{
					paddingBottom: '30px',
					color: '#4E737D',
					letterSpacing: '5px',
					fontSize: '26px',
					fontWeight: 'bold'
				}}
			>
				崖州湾科技城统一身份认证平台
			</span>
			{screenSize === ScreenSize.Mobile && <MobileLangSelect />}
		</div>
	);
};
