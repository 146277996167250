import { IMessageDetail } from "@/api/user";
import { formatTime } from "@/utils/formRules";
import { Button, Modal } from "antd";
import React, { FC } from "react";
import styles from "./style.module.less";

interface IMessageCenterModalProps {
  visible: boolean;
  updateVisible: (bool: boolean) => void;
  detail: Partial<IMessageDetail>;
  onOk: () => Promise<void>;
}

export const MessageCenterModal: FC<IMessageCenterModalProps> = ({ visible, updateVisible, detail, onOk }) => {
  return (
    <Modal
      title={detail.title || "-"}
      visible={visible}
      onCancel={() => updateVisible(false)}
      style={{
        width: 486,
        height: 406,
      }}
      className={styles.userMessageCenterModal}
      footer={
        <Button type="primary" onClick={onOk}>
          确定
        </Button>
      }
    >
      <div className={styles.time}>{formatTime(detail.insertTime as string, 2)}</div>
      <div className={styles.content}>{detail.content}</div>
    </Modal>
  );
};
