import React, { FC, useEffect, useState } from "react";
import styles from "./styles.module.less";
import { LaunchpadLayout } from "../../components/LaunchpadLayout/index";
import axios from "axios";
import { Empty } from "antd";
import { isIp, isHost, getDisplayName } from "../../utils/index";
import { useTitle } from "../../hooks/useTitle";
import { useTranslation } from "react-i18next";
import { Protocol } from "@/config";
import { keyBy } from "lodash";

export const LaunchpadPage: FC = () => {
  const { t } = useTranslation();

  useTitle(t("common.enterprisePanel"), "");

  const [appIdMaps, setAppIdMaps] = useState<any>({});
  const [appIds, setAppIds] = useState<any[]>([]);

  const getApps = async () => {
    const { data } = await axios.get("/api/v2/launchpad/applications", {
      headers: {
        // authorization: window.__user__?.token,
      },
    });
    setAppIdMaps(keyBy(data, "id"));
  };

  const getLaunchPadConfig = async () => {
    const { data: launchpad } = await axios.get(`/api/v2/launchpads/`);
    const appIds: any[] = launchpad.config || [];
    setAppIds(appIds);
  };

  useEffect(() => {
    if (!window.__user__) {
      window.location.href = "/login?logout=1";
      return;
    }
    getApps();
    getLaunchPadConfig();
  }, []);

  const repleaceSecondLevelDomain = (target: string, source: string) => {
    if (!isIp(target) && isHost(target)) {
      const arr = target.split(".");
      arr[0] = source;
      return arr.join(".");
    }
    return target;
  };

  const existAppIds = appIds.filter((id) => appIdMaps[id]);

  return (
    <LaunchpadLayout name={t("common.enterprisePanel")}>
      <div className={styles.container}>
        <h1>
          Hi {window.__user__ && getDisplayName(window.__user__)}!<sub>{t("common.enterprisePanelInfo")}</sub>
        </h1>

        <div className={styles.appList}>
          {existAppIds.length > 0 ? (
            existAppIds.map((id) => {
              const v = appIdMaps[id] as any;

              return (
                <div
                  key={v.id}
                  className={styles.app}
                  onClick={() => {
                    if (v.protocol === Protocol.SAML) {
                      window.open(
                        `${window.location.protocol}//` +
                          `${repleaceSecondLevelDomain(window.location.host, v.identifier)}` +
                          `/api/v2/saml-idp/${v.id}`
                      );
                    } else {
                      // 跳转到此应用的域名下
                      window.open(
                        `${window.location.protocol}//` +
                          `${repleaceSecondLevelDomain(window.location.host, v.identifier)}` +
                          `/login?app_id=${v.id}`
                      );
                    }
                  }}
                >
                  <div className={styles.appLogoWrap}>
                    <img src={v.logo} alt="" className={styles.logo} />
                  </div>
                  <p className={styles.appName}>{v.name}</p>
                </div>
              );
            })
          ) : (
            <Empty description={t("common.noOpenedApp")} />
          )}
        </div>
      </div>
    </LaunchpadLayout>
  );
};
