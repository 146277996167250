import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState, FC, useRef, useMemo } from "react";
import { User, EmailScene } from "authing-js-sdk";
import { Form, message, Input } from "antd";
import { Rule, FormInstance as AntFormInstance } from "antd/lib/form";
import querystring from "query-string";

import { VerifyCodeInput } from "../../../VerifyCodeInput";
import Axios from "axios";
import styles from "./style.module.less";
import { useTranslation } from "react-i18next";
import { ApplicationMfaType, ErrorCodes } from "@/constants/enum";
import { SendCode } from "@/components/SendCode";
import { FormInstance } from "@/hooks/createForm";
import { PATTERN } from "@/utils";
import validator from "validator";
import { useNavigate } from "react-router";
import { FaceRecognition } from "@/components/FaceRecognition";

export interface MFAErrorData {
  mfaToken: string;
  phone?: string;
  email?: string;
  totpMfaEnabled?: boolean;
  faceMfaEnabled?: boolean;
  avatar?: string;
  applicationMfa?: {
    status: 0 | 1;
    mfaPolicy: ApplicationMfaType;
    sort: number;
  }[];
  errorCode?: number;
}

export interface MFALoginFormProps extends React.HTMLAttributes<HTMLDivElement> {
  onSubmit?: () => void;
  onSuccess?: (user: User) => void;
  onFail?: (error: any) => void;
  MFAInfo?: MFAErrorData;
  MFAType?: ApplicationMfaType;
}

const getQueryMFAToken = () => {
  const queryVal = querystring.parse(window.location.search).mfa_token;
  if (typeof queryVal === "string") {
    return queryVal;
  }
  if (Array.isArray(queryVal)) {
    return queryVal.filter((item) => item)[0] || "";
  }
  return "";
};

const OtpMFAForm: FC<{
  MFAType: any;
  MFAInfo?: MFAErrorData;
  rawForm: AntFormInstance;
  onSubmit: any;
  onFinish: any;
  submit: any;
}> = ({ rawForm, onSubmit, onFinish, submit, MFAInfo }) => {
  const { t } = useTranslation();
  const nav = useNavigate();

  const [MFACode, setMFACode] = useState(new Array(6).fill(""));

  const rules: Rule[] = [
    {
      validateTrigger: [],
      validator(r, v, cb) {
        if (MFACode.some((item) => !item)) {
          cb(t("login.inputFullMfaCode"));
          return;
        }
        cb();
      },
    },
  ];

  return (
    <>
      {MFAInfo?.totpMfaEnabled || MFAInfo?.errorCode === ErrorCodes.OTP_MFA_CODE || Boolean(getQueryMFAToken()) ? (
        <>
          <h3 className={styles.title}>{t("login.accPwdLoginVerify")}</h3>
          <p className={styles.tips}>{t("login.inputSixCode")}</p>
          <Form
            form={rawForm}
            onSubmitCapture={onSubmit}
            onFinish={() =>
              onFinish({
                totp: MFACode.join(""),
              })
            }
          >
            <Form.Item name="mfaCode" rules={rules}>
              <VerifyCodeInput
                size="40px"
                gutter="18px"
                verifyCode={MFACode}
                setVerifyCode={setMFACode}
                onEenter={submit}
              />
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          <h3 className={styles.title} style={{ marginTop: 90 }}>
            {t("common.bindOtp")}
          </h3>
          <p className={styles.tips}>{t("common.bindOtpTip")}</p>
          <Form
            form={rawForm}
            onFinish={() => {
              nav({
                pathname: "/bind-totp?",
                search: querystring.stringify(
                  Object.assign(querystring.parse(window.location.search), {
                    mfa_token: MFAInfo?.mfaToken,
                  })
                ),
              });
            }}
            onSubmitCapture={onSubmit}
          />
        </>
      )}
    </>
  );
};

const FaceMFAForm: FC<{
  MFAInfo?: MFAErrorData;
  rawForm: AntFormInstance;
  onSubmit: any;
  onFinish: any;
  submit: any;
}> = ({ MFAInfo, ...prop }) => {
  return <>{MFAInfo?.faceMfaEnabled ? <CheckFace {...prop} /> : <BindFaceForm {...prop} MFAInfo={MFAInfo} />}</>;
};
const BindFaceForm: FC<{
  MFAInfo?: MFAErrorData;
  rawForm: AntFormInstance;
  onSubmit: any;
  onFinish: any;
  submit: any;
}> = ({ rawForm, onSubmit, MFAInfo, submit }) => {
  const { t } = useTranslation();

  const nav = useNavigate();

  return (
    <>
      <h3 className={styles.title}>{t("common.bindFace")}</h3>
      <p className={styles.tips}>{t("common.bindFaceTips")}</p>
      <Form
        form={rawForm}
        onFinish={() => {
          nav({
            pathname: "/bind-face?",
            search: querystring.stringify(
              Object.assign(querystring.parse(window.location.search), {
                mfa_token: MFAInfo?.mfaToken,
                phone: MFAInfo?.phone,
                email: MFAInfo?.email,
              })
            ),
          });
        }}
        onSubmitCapture={onSubmit}
      >
        <div className={styles.bindFace} onClick={submit}>
          <div
            className={styles.bindFaceBox}
            style={{
              backgroundImage: `url('${window.__cdnBase__}/face-scanner-bg.png')`,
            }}
          >
            <div className={styles.userImage} style={{ backgroundImage: `url('${MFAInfo?.avatar}')` }} />
          </div>
        </div>
      </Form>
    </>
  );
};

const CheckFace: FC<{
  MFAInfo?: MFAErrorData;
  rawForm: AntFormInstance;
  onSubmit: any;
  onFinish: any;
  submit: any;
}> = ({ rawForm, onSubmit, MFAInfo, onFinish }) => {
  // TODO 人脸识别
  const { t } = useTranslation();

  return (
    <>
      <h3 className={styles.title}>{t("common.MFAFcae")}</h3>
      <p className={styles.tips}>{t("common.bindFaceTips")}</p>
      <FaceRecognition onFinish={onFinish} />
    </>
  );
};
const BindPhoneForm: FC<{
  bindPhoneForm: AntFormInstance;
  setPhone: any;
  onSuccess: any;
  onFail: any;
  MFAInfo?: MFAErrorData;
  smsSendCodeBtnRef: any;
}> = ({ bindPhoneForm, setPhone, onSuccess, onFail, MFAInfo, smsSendCodeBtnRef }) => {
  const { t } = useTranslation();

  return (
    <Form
      style={{
        marginTop: 6,
      }}
      form={bindPhoneForm}
      onFinish={(values) => {
        Axios.post(
          "/api/v2/applications/mfa/check",
          {
            phone: values.phone,
          },
          {
            headers: {
              authorization: MFAInfo?.mfaToken,
            },
          }
        )
          .then((data: any) => {
            if (data.data) {
              setPhone(values.phone);
              onSuccess();
              setTimeout(() => {
                smsSendCodeBtnRef.current.click();
              });
            } else {
              message.error(t("login.phoneBinded"));
              onFail(data);
            }
          })
          .catch(onFail);
      }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: t("login.inputPhone"),
          },
          {
            pattern: PATTERN.phone,
            message: t("login.phoneError"),
          },
        ]}
        name="phone"
      >
        <Input size="large" placeholder={t("login.inputPhone")}></Input>
      </Form.Item>
    </Form>
  );
};

const BindEmailForm: FC<{
  bindEmailForm: AntFormInstance;
  setEmail: any;
  onSuccess: any;
  onFail: any;
  MFAInfo?: MFAErrorData;
  emailSendCodeBtnRef: any;
}> = ({ bindEmailForm, setEmail, onSuccess, onFail, MFAInfo, emailSendCodeBtnRef }) => {
  const { t } = useTranslation();

  return (
    <Form
      style={{
        marginTop: 6,
      }}
      form={bindEmailForm}
      onFinish={(values) => {
        Axios.post(
          "/api/v2/applications/mfa/check",
          {
            email: values.email,
          },
          {
            headers: {
              authorization: MFAInfo?.mfaToken,
            },
          }
        )
          .then((data: any) => {
            if (data.data) {
              setEmail(values.email);
              onSuccess();
              setTimeout(() => {
                emailSendCodeBtnRef.current.click();
              });
            } else {
              message.error(t("login.mailBinded"));
              onFail(data);
            }
          })
          .catch(onFail);
      }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: t("login.inputEmail"),
          },
          {
            validator(r, v) {
              if (!validator.isEmail(v)) {
                return Promise.reject(t("login.emailError"));
              }
              return Promise.resolve();
            },
          },
        ]}
        name="email"
      >
        <Input size="large" placeholder={t("login.inputEmail")}></Input>
      </Form.Item>
    </Form>
  );
};

const MsgMFAForm: FC<{
  rawForm: AntFormInstance;
  onSubmit: any;
  onFinish: any;
  submit: any;
  MFAType?: ApplicationMfaType;
  MFAInfo?: MFAErrorData;
  onSuccess: any;
  onFail: any;
}> = ({ rawForm, onSubmit, onFinish, submit, MFAType, MFAInfo, onSuccess, onFail }) => {
  const { t } = useTranslation();

  const [MFACode, setMFACode] = useState(new Array(4).fill(""));
  const smsSendCodeBtnRef = useRef<HTMLSpanElement>(null);
  const emailSendCodeBtnRef = useRef<HTMLSpanElement>(null);

  const [sending, setSending] = useState(false);
  const [sentStatus, setSentStatus] = useState({
    [ApplicationMfaType.EMAIL]: false,
    [ApplicationMfaType.SMS]: false,
  });

  const [phone, setPhone] = useState(MFAInfo?.phone);
  const [email, setEmail] = useState(MFAInfo?.email);

  const titleAndTip = useMemo(() => {
    if (MFAType === ApplicationMfaType.SMS) {
      if (!phone) {
        return {
          title: t("common.bindPhone"),
          tips: t("login.dontBindPhone"),
        };
      }
      if (!sentStatus[ApplicationMfaType.EMAIL]) {
        return {
          title: t("login.inputPhoneCode"),
          tips: t("login.clickSent"),
        };
      }
      return {
        title: t("login.inputPhoneCode"),
        tips: sending ? t("login.sendingVerifyCode") : `${t("login.verifyCodeSended")}} ${phone}`,
      };
    }

    if (MFAType === ApplicationMfaType.EMAIL) {
      if (!email) {
        return {
          title: t("common.bindEmail"),
          tips: t("login.dontbindEmail"),
        };
      }
      if (!sentStatus[ApplicationMfaType.EMAIL]) {
        return {
          title: t("login.inputEmailCode"),
          tips: t("login.clickSent"),
        };
      }
      return {
        title: t("login.inputEmailCode"),
        tips: sending ? t("login.sendingVerifyCode") : `${t("login.verifyCodeSended")} ${email}`,
      };
    }
  }, [MFAType, email, phone, sending, sentStatus, t]);

  const rules: Rule[] = [
    {
      validateTrigger: [],
      validator(r, v, cb) {
        if (MFACode.some((item) => !item)) {
          cb(t("login.inputFullMfaCode"));
          return;
        }
        cb();
      },
    },
  ];

  const sendSmsVerifyCode = async () => {
    try {
      setSending(true);

      setSentStatus((prev) => ({
        ...prev,
        [MFAType!]: true,
      }));

      await window.__authing__.sendSmsCode(phone!);
      return true;
    } catch (e) {
      return false;
    } finally {
      setSending(false);
    }
  };

  const sendEmailVerifyCode = async () => {
    try {
      setSending(true);

      setSentStatus((prev) => ({
        ...prev,
        [MFAType!]: true,
      }));
      await window.__authing__.sendEmail(email!, EmailScene.MfaVerify);
      return true;
    } catch (e) {
      return false;
    } finally {
      setSending(false);
    }
  };

  const getEmailOrPhone = () => {
    switch (MFAType) {
      case ApplicationMfaType.EMAIL:
        return {
          email,
        };

      default:
        return {
          phone,
        };
    }
  };

  return (
    <>
      <h3 className={styles.title}>{titleAndTip?.title}</h3>
      <p className={styles.tips}>{titleAndTip?.tips}</p>
      {((phone && MFAType === ApplicationMfaType.SMS) || (email && MFAType === ApplicationMfaType.EMAIL)) && (
        <Form
          form={rawForm}
          onSubmitCapture={onSubmit}
          onFinish={() =>
            onFinish(
              Object.assign(
                {
                  code: MFACode.join(""),
                },
                getEmailOrPhone()
              )
            )
          }
        >
          <Form.Item
            style={{
              textAlign: "center",
            }}
            name="mfaCode"
            rules={rules}
          >
            <VerifyCodeInput
              size="40px"
              gutter="18px"
              verifyCode={MFACode}
              setVerifyCode={setMFACode}
              onEenter={submit}
              length={4}
            />
          </Form.Item>
        </Form>
      )}

      <div
        style={{
          textAlign: "center",
        }}
      >
        <SendCode
          style={{
            display: MFAType === ApplicationMfaType.SMS && phone ? "initial" : "none",
          }}
          btnRef={smsSendCodeBtnRef}
          beforeSend={sendSmsVerifyCode}
        />
        <SendCode
          style={{
            display: MFAType === ApplicationMfaType.EMAIL && email ? "initial" : "none",
          }}
          btnRef={emailSendCodeBtnRef}
          beforeSend={sendEmailVerifyCode}
        />
      </div>

      {!phone && MFAType === ApplicationMfaType.SMS && (
        <BindPhoneForm
          onSuccess={onSuccess}
          onFail={onFail}
          setPhone={setPhone}
          bindPhoneForm={rawForm}
          MFAInfo={MFAInfo}
          smsSendCodeBtnRef={smsSendCodeBtnRef}
        />
      )}

      {!email && MFAType === ApplicationMfaType.EMAIL && (
        <BindEmailForm
          onSuccess={onSuccess}
          onFail={onFail}
          setEmail={setEmail}
          bindEmailForm={rawForm}
          MFAInfo={MFAInfo}
          emailSendCodeBtnRef={emailSendCodeBtnRef}
        />
      )}
    </>
  );
};

export const InternalMFALoginForm: ForwardRefRenderFunction<FormInstance, MFALoginFormProps> = (
  { onSubmit, onSuccess, onFail, MFAInfo, MFAType },
  ref
) => {
  const [rawForm] = Form.useForm();

  const onFinish = async (params: any) => {
    const apiMap = {
      [ApplicationMfaType.EMAIL]: "/api/v2/applications/mfa/email/verify",
      [ApplicationMfaType.OTP]: "/api/v2/mfa/totp/verify",
      [ApplicationMfaType.SMS]: "/api/v2/applications/mfa/sms/verify",
      [ApplicationMfaType.FACE]: "/api/v2/mfa/face/verify",
    };

    try {
      const res: any = await Axios.post(apiMap[MFAType!], params, {
        headers: {
          authorization: getQueryMFAToken() || MFAInfo?.mfaToken,
        },
      });
      if (res.code !== 200) {
        message.error(res.message);
        onFail?.(res);
        return res;
      }
      onSuccess && onSuccess(res.data);
    } catch (e) {
      onFail?.(e);
      message.error(e.message);
    }
  };

  const submit = async () => {
    try {
      onSubmit?.();
      await rawForm.validateFields();
      rawForm.submit();
    } catch (error) {
      onFail && onFail(error);
    }
  };

  useImperativeHandle(ref, () => ({
    submit,
  }));

  const formMap = {
    [ApplicationMfaType.OTP]: (
      <OtpMFAForm
        {...{
          MFAInfo,
          rawForm,
          onSubmit,
          onFinish,
          submit,
          MFAType,
        }}
      />
    ),
    [ApplicationMfaType.EMAIL]: (
      <MsgMFAForm
        {...{
          rawForm,
          onSubmit,
          onFinish,
          submit,
          MFAType,
          MFAInfo,
          onSuccess,
          onFail,
        }}
      />
    ),
    [ApplicationMfaType.SMS]: (
      <MsgMFAForm
        {...{
          rawForm,
          onSubmit,
          onFinish,
          submit,
          MFAType,
          MFAInfo,
          onSuccess,
          onFail,
        }}
      />
    ),
    [ApplicationMfaType.FACE]: (
      <FaceMFAForm
        {...{
          rawForm,
          onSubmit,
          onFinish,
          submit,
          MFAInfo,
        }}
      />
    ),
  };

  return formMap[MFAType!];
};

export const MFALoginForm = forwardRef<FormInstance, MFALoginFormProps>(InternalMFALoginForm);
