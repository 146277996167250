import React, { useState } from "react";
import ImgCrop from "antd-img-crop";
import { message, Upload } from "antd";
import styles from "./styles.module.less";
// import classnames from "classnames";
import { useTranslation } from "react-i18next";

export interface IProps {
  size?: number | string;
  defalutImg?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const ImageCrop = (props: IProps) => {
  const { size = 26, value, defalutImg, onChange: onUrlChange } = props;
  const [imageUrl, setImageUrl] = useState(value);
  const { t } = useTranslation();

  const onChange = (info: any) => {
    const { status } = info.file;
    if (status === "done") {
      const { code, message: errmsg, data } = info.file.response;
      if (code !== 200) {
        return message.error(errmsg);
      }
      const { url } = data;
      setImageUrl(url);
      onUrlChange && onUrlChange(url);
    } else if (status === "error") {
      message.error(
        t("common.uploadFail", {
          name: info.file.name,
        })
      );
    }
  };

  return (
    // TODO 苏州工业园演示完记得去掉 aspect ！！！
    <ImgCrop
      // aspect={8.56 / 5.4}
      modalOk={t("common.confirm")}
      modalCancel={t("common.cancel")}
      modalTitle={t("common.editPic")}
    >
      <Upload
        name="file"
        listType="picture"
        className="avatar-uploader"
        showUploadList={false}
        action="/api/v2/upload?folder=photos"
        onChange={onChange}
        beforeUpload={(file) => {
          if (!/jpe?g|png$/.test(file.type)) {
            message.error(t("common.picExtError"));
            return false;
          }
          const isLt5M = file.size / 1024 / 1024 < 5;
          if (!isLt5M) {
            message.error(t("common.picSizeLimit"));
            return false;
          }
          return true;
        }}
        style={{ cursor: "pointer" }}
      >
        <div className={styles.groupLogo} style={{ width: size, height: size }}>
          {imageUrl ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: `url('${imageUrl}')`,
              }}
            />
          ) : defalutImg ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundImage: `url('${defalutImg}')`,
              }}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                backgroundColor: "#d9d9d9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="authing-icon authing-ad" />
            </div>
          )}
          {/* <i className={classnames(styles.edit, "authing-icon", "authing-edit")} /> */}
          <div className={styles.mask}>
            <span>{t("common.modify")}</span>
            <span>{t("common.image")}</span>
          </div>
        </div>
      </Upload>
    </ImgCrop>
  );
};
