import React from "react";
import styles from "./styles.module.less";
import { Row } from "antd";
import { MInput, MDatetime, MSelect } from "../../../components/MutableFormItem/index";
import { useTranslation } from "react-i18next";
import { LeftAvatar } from "./LeftAvatar";
import { UserQrcode } from "./UserQrcode";

export const UserPageBasic = (props: any) => {
  const { onUpdate } = props;
  const { t } = useTranslation();
  const user = window.__user__;
  const genderOptions = [
    { label: t("common.man"), value: "M" },
    { label: t("common.female"), value: "F" },
    { label: t("common.unknown"), value: "U" },
  ];

  return (
    <div className={styles.basic}>
      <LeftAvatar />
      <div className={styles.infoWrap}>
        <Row gutter={24}>
          <MInput prop="id" value={user?.id} label="ID：" span={12} readonly />
          <MInput
            onUpdate={onUpdate}
            prop="username"
            value={user?.username}
            label={t("common.usernameLabel")}
            span={12}
          />
          <MInput prop="email" value={user?.email} label={t("common.emailLabel")} span={12} readonly />
          <MInput prop="phone" value={user?.phone} label={t("common.phoneLabel")} span={12} readonly />
          <MInput prop="name" value={user?.name} label={t("common.nameLabel")} span={12} />
          <MInput prop="nickname" value={user?.nickname} label={t("common.nicknameLabel")} span={12} />
          <MSelect
            prop="gender"
            value={user?.gender}
            label={t("common.genderLabel")}
            options={genderOptions}
            span={12}
          />
          <MInput prop="address" value={user?.address} label={t("common.addressLabel")} span={12} />
          {/* <MInput prop="company" value={user?.company} label={t("common.companyLabel")} span={12} /> */}
          <MDatetime prop="birthdate" value={user?.birthdate} label={t("common.birthdayLabel")} span={12} />
          <MDatetime
            prop="createdAt"
            value={user?.createdAt}
            label={t("common.registerDateLabel")}
            span={12}
            readonly
          />
          {/* <MInput prop="website" value={user?.website} label={t("common.blogLabel")} span={24} /> */}
        </Row>
        <UserQrcode />
      </div>
    </div>
  );
};
