import { SendPhoneCode } from "../../../../components/SendPhoneCode";
import { message, Input, Form, Modal } from "antd";
import React, { FC, useState } from "react";
import { User } from "authing-js-sdk";
import { useTranslation } from "react-i18next";
import { Lang } from "@/locales";

export interface UpdatePhoneProps {
  user: User;
  visible: boolean;
  onOk?: (user: User) => void;
  onCancel?: () => void;
}

export const UpdatePhone: FC<UpdatePhoneProps> = ({ user, visible, onOk, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  return (
    <Modal
      title={t("common.modifyPhoneNumber")}
      visible={visible}
      onOk={form.submit}
      onCancel={onCancel}
      confirmLoading={loading}
      width="700px"
    >
      <Form
        form={form}
        hideRequiredMark
        labelAlign="left"
        labelCol={{ span: i18n.language === Lang.zhCn ? 6 : 8 }}
        onFinish={async (values) => {
          try {
            setLoading(true);
            const { phoneCode, oldPhoneCode } = values;
            const data = await window.__authing__.updatePhone(phone, phoneCode, user?.phone!, oldPhoneCode);
            onOk && onOk(data);
            message.success(t("common.updateSuccess"));
          } finally {
            setLoading(false);
          }
        }}
      >
        <Form.Item name="oldPhone" label={t("common.currentPhoneNumber")} initialValue={user?.phone}>
          <Input placeholder={t("common.currentPhoneNumber")} readOnly />
        </Form.Item>

        <Form.Item
          name="oldPhoneCode"
          label={t("common.oldPhoneVerfiyCode")}
          rules={[{ required: true, message: t("login.noEmpty") }]}
        >
          <Input
            autoComplete="one-time-code"
            placeholder={t("common.inputFourVerifyCode", {
              length: window.__config__.verifyCodeLength,
            })}
            addonAfter={<SendPhoneCode phone={user?.phone!} />}
          />
        </Form.Item>

        <Form.Item name="phone" label={t("common.newPhone")} rules={[{ required: true, message: t("login.noEmpty") }]}>
          <Input value={phone} onChange={(e) => setPhone(e.target.value)} placeholder={t("common.inputNewPhone")} />
        </Form.Item>

        <Form.Item
          name="phoneCode"
          label={t("common.newPhoneVerify")}
          rules={[{ required: true, message: t("login.noEmpty") }]}
        >
          <Input
            autoComplete="one-time-code"
            placeholder={t("common.inputFourVerifyCode", {
              length: window.__config__.verifyCodeLength,
            })}
            addonAfter={<SendPhoneCode phone={phone} />}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
