import { Tabs } from "antd";
import React, { FC, Fragment } from "react";
import { TabsProps } from "antd/lib/tabs";
import styles from "./styles.module.less";
import classnames from "classnames";

export interface AuthingTabsProps extends TabsProps {}

export const AuthingTabs: FC<AuthingTabsProps> = (props) => {
  const renderTabBar: (props: any, DefaultTabBar: React.ComponentType) => React.ReactElement = (props) => {
    const { panes, activeKey, onTabClick } = props;
    return (
      <div className={`${styles.tabBar} __authing-tab-bar`}>
        {panes.map((pane: any, index: number) => {
          const iActive = pane.key === activeKey;
          const cls = classnames(styles.tab, {
            [styles.active]: iActive,
          });

          const isFirst = index === 0;
          const split = <div className={styles.split} />;

          return (
            <Fragment key={pane.key}>
              {isFirst ? null : split}
              <span className={cls} key={pane.key} onClick={(e) => onTabClick(pane.key, e)}>
                {pane.props.tab}
              </span>
            </Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <Tabs renderTabBar={renderTabBar} {...props}>
      {props.children}
    </Tabs>
  );
};
