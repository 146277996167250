import React, { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select, Upload, Modal, Progress, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styles from "./styles.module.less";
import { UploadFile } from "antd/es/upload/interface";
import Axios from "axios";
import { RouterLink } from "@/components/RouterLink";
import { MobileSelect } from "@/components/MobileSelect";
import { i18n } from "@/locales";
import { useForm } from "antd/lib/form/Form";
import classnames from "classnames";
import { useAsyncFn } from "react-use";
import { useNavigateWithSearch } from "@/hooks/useNavigateWithSearch";
// import { basePath } from "../../utils/index";

const { Option } = Select;

const typeOperations = [
  {
    label: i18n.t("common.problem.noVerify"),
    value: 0,
  },
  {
    label: i18n.t("common.problem.noLogin"),
    value: 1,
  },
  {
    label: i18n.t("common.problem.noRegister"),
    value: 2,
  },
  {
    label: i18n.t("common.problem.noAccount"),
    value: 3,
  },
  {
    label: i18n.t("common.problem.noResetPass"),
    value: 4,
  },
  {
    label: i18n.t("common.problem.other"),
    value: 5,
  },
];
const typeProblemMap: any = {
  0: [
    i18n.t("common.problem.noVerifyTip.tip"),
    i18n.t("common.problem.noVerifyTip.type1"),
    i18n.t("common.problem.noVerifyTip.type2"),
    i18n.t("common.problem.noVerifyTip.type3"),
  ],
  1: [
    i18n.t("common.problem.noLoginTip.tip"),
    i18n.t("common.problem.noLoginTip.type1"),
    i18n.t("common.problem.noLoginTip.type2"),
  ],
  2: [
    i18n.t("common.problem.noRegisterTip.tip"),
    i18n.t("common.problem.noRegisterTip.type1"),
    i18n.t("common.problem.noRegisterTip.type2"),
    i18n.t("common.problem.noRegisterTip.type3"),
  ],
  3: [
    i18n.t("common.problem.noAccountTip.tip"),
    i18n.t("common.problem.noAccountTip.type1"),
    i18n.t("common.problem.noAccountTip.type2"),
  ],
  4: [
    i18n.t("common.problem.noResetPassTip.tip"),
    i18n.t("common.problem.noResetPassTip.type1"),
    i18n.t("common.problem.noResetPassTip.type2"),
  ],
  5: [i18n.t("common.problem.otherTip.tip"), i18n.t("common.problem.otherTip.type1")],
};

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const DesktopProblemForm: FC<{
  className?: string;
}> = ({ className }) => {
  const [typeProblem, setTypeProblem] = useState(0);
  const [uploadUrl, setUploadUrl] = useState();
  const onFinish = (value: any) => {
    const params = {
      type: typeProblem,
      description: value.description,
      phone: value.phone,
      images: uploadUrl,
      appId: window.__appId__,
    };
    postFeedBack(params);
  };
  const { t } = useTranslation();
  const validateMessages = {
    // eslint-disable-next-line
    required: t("common.problem.verifyTip"),
  };

  const postSuccess = () => {
    Modal.success({
      title: t("common.problem.successTip"),
      content: t("common.problem.successTipMsg"),
      onOk() {
        window.location.href = "/login";
      },
    });
  };
  const postFeedBack = async (params: any) => {
    const { code } = await Axios.post("/api/v2/feedback", params);
    if (code === 200) {
      postSuccess();
    }
  };
  const typeProblemBlock = () => {
    return (
      <div>
        {typeProblemMap[typeProblem]?.map((item: any, index: any) => (
          <div key={index}>{item}</div>
        ))}
      </div>
    );
  };

  const UploadImg = () => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState();
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
    const handleChange = (info: any) => {
      setFileList(info.fileList);
      const imgUrl = info.fileList.map((item: any) => {
        const response = item.response;
        return response?.data.url;
      });
      setUploadUrl(imgUrl);
    };
    const handleCancel = () => {
      setPreviewVisible(false);
    };

    const handlePreview = async (file: any) => {
      setPreviewImage(file.url);
      setPreviewVisible(true);
    };
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>{t("common.problem.form.upload")}</div>
      </div>
    );
    return (
      <>
        <Upload
          action="/api/v2/upload?folder=photos"
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} title="title" footer={null} onCancel={handleCancel}>
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </>
    );
  };
  return (
    <Form className={className} name="" onFinish={onFinish} validateMessages={validateMessages} colon={false}>
      <Form.Item
        name={"phone"}
        label={t("common.problem.form.phone")}
        rules={[{ required: true }]}
        className="authing-required-form-item"
      >
        <Input placeholder={t("common.problem.form.phonePlaceholder")} className={styles.inputItem} />
      </Form.Item>
      <Form.Item name="type" label={t("common.problem.form.question")}>
        <Select
          onChange={(value) => {
            setTypeProblem(value);
          }}
          className={styles.inputItem}
          defaultValue={typeProblem}
        >
          {typeOperations?.map(({ value, label }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div className={styles.typeProblem}>{typeProblemBlock()}</div>
      <div className={styles.label}>{t("common.problem.form.questionDescript")}</div>
      <Form.Item name={"description"}>
        <Input.TextArea />
      </Form.Item>
      <div className={styles.uploadImgWarp}>
        <div className={styles.label}>{t("common.problem.form.questionClip")}</div>
        {UploadImg()}
      </div>
      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          {t("common.problem.form.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export const MobileProblemForm: FC<{
  className?: string;
}> = ({ className }) => {
  const [rawForm] = useForm();
  const navigate = useNavigateWithSearch();
  const { t } = useTranslation();

  const [feedbackState, feedback] = useAsyncFn(
    async (values: any) => {
      if (!values.phone) {
        message.error(t("login.inputContactWayPlease"));
        return;
      }

      const params = {
        type: values.type,
        description: values.description,
        phone: values.phone,
        images: values.images?.map((item: any) => JSON.parse(item.xhr.response)?.data.url),
        appId: window.__appId__,
      };

      try {
        await Axios.post("/api/v2/feedback", params);
        navigate("/feedback-success");
      } catch (e) {
        message.error(t("login.submitFailRetry"));
      }
    },
    [window.__appId__]
  );

  return (
    <Form
      form={rawForm}
      className={classnames(className, styles.mobileProblemForm)}
      initialValues={{
        type: 0,
      }}
      onFinish={feedback}
    >
      <Form.Item label={t("login.selectYourProblem")} name="type">
        <MobileSelect
          style={{
            width: "100%",
          }}
          options={typeOperations}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(next, prev) => next.type !== prev.type}>
        {() => {
          const type = rawForm.getFieldValue("type");

          return (
            <div className={styles.typeProblemContainer}>
              {typeProblemMap[type]?.map((item: any, index: any) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          );
        }}
      </Form.Item>
      <div className={styles.splitLine}></div>
      <Form.Item label={t("common.problem.form.questionDescript")} name="description">
        <Input.TextArea
          rows={4}
          style={{
            padding: 0,
          }}
          bordered={false}
          placeholder={t("login.problemDetail")}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={(next, prev) => prev.fileList?.length !== next.fileList?.length}>
        {() => (
          <Form.Item name="images" valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload
              action="/api/v2/upload?folder=photos"
              listType="picture-card"
              itemRender={(n, file) => {
                return (
                  <div
                    style={{
                      padding: 6,
                      borderRadius: 2,
                      border: "1px solid #d9d9d9",
                    }}
                  >
                    {file.status === "uploading" ? (
                      <Progress
                        showInfo={false}
                        style={{
                          margin: 1,
                        }}
                        width={40}
                        type="circle"
                        percent={file.percent}
                      />
                    ) : (
                      <img alt="" width="100%" height="100%" object-fit="contain" src={file.thumbUrl} />
                    )}
                  </div>
                );
              }}
            >
              {rawForm.getFieldValue("fileList")?.length >= 8 ? null : (
                <PlusOutlined
                  style={{
                    color: "#CCCCCC",
                  }}
                />
              )}
            </Upload>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item label={t("common.problem.form.phone")} name="phone">
        <Input
          style={{
            padding: 0,
          }}
          bordered={false}
          placeholder={t("login.inputContactWayCheap")}
        />
      </Form.Item>

      <Button
        style={{
          margin: "40px 0 14px 0",
        }}
        type="primary"
        size="large"
        block
        htmlType="submit"
        loading={feedbackState.loading}
      >
        {t("common.problem.form.submit")}
      </Button>
    </Form>
  );
};

export const ProblemPage = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.problemPage}>
      <div className={styles.title}>{t("common.problem.title")}</div>
      <DesktopProblemForm className="hide-in-mobile" />
      <MobileProblemForm className="hide-in-desktop" />
      <div className={styles.bottom}>
        {/* {t("common.problem.noProblem")} */}
        <RouterLink to="/login">{t("common.problem.goLogin")}</RouterLink>
      </div>
    </div>
  );
};
