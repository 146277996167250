import axios from "axios";
import querystring from "query-string";
import { AuthenticationClient } from "authing-js-sdk";
import shortid from "shortid";
import { message } from "antd";
import { addStyles, getImgSrc } from "../utils";
import { LoginMethods, RegisterMethods, ErrorCodes, PasswordStrength } from "@/constants/enum";
import { Lang } from "@/locales";

export enum SocialConnectionProvider {
  ALIPAY = "alipay",
  GOOGLE = "google",
  WECHATPC = "wechat:pc", // 微信扫码登录
  WECHATMP = "wechat:webpage-authorization", // 微信公众号网页授权
  WECHAT_MINIPROGRAM = "wechat:miniprogram:default", // 微信小程序登录
  WECHAT_MINIPROGRAM_QRCODE = "wechat:miniprogram:qrconnect", // 微信小程序登录
  WECHAT_MINIPROGRAM_APPLAUNCH = "wechat:miniprogram:app-launch", // 微信小程序登录
  WECHATMOBILE = "wechat:mobile", // 微信移动应用
  GITHUB = "github",
  QQ = "qq",
  WECHATWORK_ADDRESS_BOOK = "wechatwork:addressbook", // 企业微信通讯录
  WECHATWORK_CORP_QRCONNECT = "wechatwork:corp:qrconnect",
  WECHATWORK_SERVICEPROVIDER_QRCONNECT = "wechatwork:service-provider:qrconnect",
  DINGTALK = "dingtalk",
  WEIBO = "weibo",
  APPLE = "apple",
  APPLE_WEB = "apple:web",
}

export type TooltipLangMap = Record<Lang, string>;
export interface InternalExtendsField {
  type: "internal";
  name: string;
  label: string;
  inputType: string;
}

export interface UserExtendsField {
  type: "user";
  id: string;
  name: string;
  label: string;
  inputType: string;
}

/**
 *  套餐类型
 */
export enum PackageType {
  DEF = -1, // 默认
  POP = 0, // 普通
  HIG = 1, // 高级
  BUS = 2, // 商业
}

export type ExtendsField = InternalExtendsField | UserExtendsField;

// export enum AgreementType {
//   Url = "URL",
//   RichText = "RICH_TEXT",
// }
export interface Agreement {
  id: number;
  title: string;
  required: boolean;
  lang: Lang;
}
export interface ApplicationConfig {
  id: string;
  cdnBase: string;
  userPoolId: string;
  rootUserPoolId: string;
  publicKey: string;
  passwordStrength: PasswordStrength;
  // 登录框自定义 css 代码
  css: string;
  name: string;
  logo: string;
  redirectUris: string[];
  registerDisabled: boolean;
  logoutRedirectUris: string[];
  registerTabs: {
    list: RegisterMethods[];
    default: string;
    title: { [x: string]: string };
  };
  loginTabs: {
    list: LoginMethods[];
    default: string;
    title: { [x: string]: string };
  };
  socialConnections: {
    provider: string;
    name: string;
    authorizationUrl: string;
    tooltip: TooltipLangMap;
  }[];

  agreementEnabled: boolean;
  agreements: Agreement[];

  extendsFieldsEnabled: boolean;
  extendsFields: ExtendsField[];

  identityProviders: {
    identifier: string;
    protocol: Protocol;
    displayName: string;
    logo: string;
    config:
      | ISamlConnectionConfig
      | OIDCConnectionConfig
      | ICasConnectionConfig
      | IAzureAdConnectionConfig
      | IOAuthConnectionConfig;
  }[];

  ssoPageComponentDisplay: {
    autoRegisterThenLoginHintInfo: boolean;
    forgetPasswordBtn: boolean;
    idpBtns: boolean;
    loginBtn: boolean;
    loginByPhoneCodeTab: boolean;
    loginByUserPasswordTab: boolean;
    loginMethodNav: boolean;
    phoneCodeInput: boolean;
    registerBtn: boolean;
    registerByEmailTab: boolean;
    registerByPhoneTab: boolean;
    registerMethodNav: boolean;
    socialLoginBtns: boolean;
    userPasswordInput: boolean;
    wxMpScanTab: boolean;
  };

  protocol: Protocol;
  oidcConfig: OidcClientMetadata;
  enableSubAccount: boolean;
  packageType: PackageType;
  // 用户池是否在白名单
  userPoolInWhitelist: boolean;

  userPortal: UserPortalConfig;
  identifier: string;

  /** websocket 域名*/
  websocket: string;
  verifyCodeLength: number;
}

export enum Protocol {
  OIDC = "oidc",
  OAUTH = "oauth",
  SAML = "saml",
  LDAP = "ldap",
  AD = "ad",
  CAS = "cas",
  AZURE_AD = "azure-ad",
}

export interface OIDCConnectionConfig {
  issuerUrl: string;
  authorizationEdpoint: string;
  responseType: string;
  mode: OIDCConnectionMode;
  clientId: string;
  clientSecret: string;
  scopes: string;
  redirectUri: string;
}

export enum OIDCConnectionMode {
  FRONT_CHANNEL = "FRONT_CHANNEL",
  BACK_CHANNEL = "BACK_CHANNEL",
}
export interface IOAuthConnectionConfig {
  authEndPoint: string;
  tokenEndPoint: string;
  scope: string;
  clientId: string;
  clientSecret: string;
  authUrlTemplate: string;
  codeToTokenScript: string;
  tokenToUserInfoScript: string;
  tokenToUserInfoScriptFuncId: string;
  codeToTokenScriptFuncId: string;
  authUrl?: string; // 根据模板拼接出来的授权 url
}
export interface ISamlConnectionConfig {
  signInEndPoint: string;
  samlRequest?: string;

  // saml assertion 验签公钥

  samlIdpCert: string;

  // saml request 验签公钥

  samlSpCert: string;

  // saml request 签名私钥

  samlSpKey: string;

  signOutEndPoint: string;

  signSamlRequest: boolean;

  signatureAlgorithm: string;

  digestAlgorithm: string;

  protocolBinding: string;
}

export interface ICasConnectionConfig {
  casConnectionLoginUrl: string;
}

export interface IAzureAdConnectionConfig {
  microsoftAzureAdDomain: string;
  clientId: string;
  syncUserProfileOnLogin: string;
  emailVerifiedDefault: boolean;
  authorizationUrl: string;
  callbackUrl: string;
}

export interface OidcClientMetadata {
  grant_types: string[];
  client_id: string;
  redirect_uris: string[];
  scope: string;
  response_types: ResponseType[];
}

export interface UserPortalConfig {
  cdnBase: string;
  assetsBase: string;
  assetsVersion: string;
  // 网站备案号
  icpRecord: string;
  // 公安备案号
  psbRecord: string;
  poweredBy?: {
    name: string;
    logo: string;
    link: string;
  };
}

export interface ServerConfig {
  dev: boolean;
  publicKey: string;
  rootUserpoolId: string;
  server: string;
  userPortal: UserPortalConfig;
}

const getApplicationConfig = async (appId: string) => {
  const query = querystring.parse(window.location.search);
  const { data: config } = await axios.get<ApplicationConfig>(`/api/v2/applications/${appId}/public-config`);
  const protocol = query["protocol"] || config.protocol || "oidc";
  config.protocol = protocol as Protocol;
  console.log(config);
  const { userPoolId, name, logo, cdnBase, publicKey } = config;

  // 不要 localStorage 中的 token 了，只用 session 判断，之后 SDK 中取 token 的逻辑也应该去掉，但是写 token 可以留着
  localStorage.removeItem("_authing_token");

  // 1. 将应用配置和 sdk 挂载到 window 上
  window.__config__ = config;
  window.__userPortalConfig__ = config.userPortal;
  window.__appId__ = appId as string;
  window.__userPoolId__ = userPoolId;
  window.__cdnBase__ = cdnBase;
  window.__authing__ = new AuthenticationClient({
    timeout: 60000,
    // userPoolId,
    appId: appId as string,
    host: `${window.location.protocol}//${window.location.host}`, // 当前域名下
    websocketHost: config.websocket,
    publicKey,
    onError: (code: any, msg: any) => {
      if (code === ErrorCodes.LOGIN_REQUIRED) {
        return;
      }
      if ([ErrorCodes.OTP_MFA_CODE, ErrorCodes.MSG_MFA_CODE].includes(code)) {
        message.info(msg);
        return;
      }
      message.error(msg);
    },
    requestFrom: "userPortal",
  });

  // 注意：调用 SDK 接口可能抛出错误
  try {
    try {
      let token = localStorage.getItem("_authing_token");
      if (token) {
        // 如果 token 过期了就不要携带过期的 token 去请求 getCurrentUser
        let tokenPayload = JSON.parse(window.atob(token.split(".")[1]));
        if (tokenPayload.exp <= Date.now() / 1000) {
          localStorage.removeItem("_authing_token");
        }
      }
    } catch (_) {}

    const user = await window.__authing__.getCurrentUser();
    window.__user__ = user;
  } catch (error) {
    window.__user__ = null;
  }

  // 2. 修改 favicon 和 title
  document.title = name;
  const favicon = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
  if (favicon) {
    favicon.href = logo || getImgSrc("authing-logo.png");
    document.getElementsByTagName("head")[0].appendChild(favicon);
  }

  // 3. 根据 config.css 字段插入 style node
  const css = config.css;
  if (css) {
    addStyles(css);
  }

  // 3. 如果使用 oidc 协议访问 /login，并且没有 uuid 的话就跳转
  if (window.location.pathname === "/login" && protocol === Protocol.OIDC && !query["uuid"]) {
    const { client_id, redirect_uris, scope, response_types } = config.oidcConfig || {};
    const query = querystring.stringify({
      ...querystring.parse(window.location.search),
      app_id: appId,
      client_id: client_id,
      redirect_uri: (redirect_uris && redirect_uris[0]) || "",
      scope: scope,
      response_type: (response_types && response_types[0]) || "",
      state: shortid.generate(),
      nonce: shortid.generate(),
    });
    // TODO: 跳转回当前路由
    window.location.href = `/oidc/auth?${query}`;
    // 中断后续的代码
    throw new Error("redirect");
  }

  // 4. 如果使用 oauth 协议访问 /login，并且没有 uuid 的话就跳转
  if (window.location.pathname === "/login" && protocol === Protocol.OAUTH && !query["uuid"]) {
    const { id, redirectUris } = config || {};
    const query = querystring.stringify({
      ...querystring.parse(window.location.search),
      app_id: appId,
      client_id: id,
      redirect_uri: (redirectUris && redirectUris[0]) || "",
      scope: "user",
      response_type: "code",
      state: shortid.generate(),
    });
    // TODO: 跳转回当前路由
    window.location.href = `/oauth/auth?${query}`;
    // 中断后续的代码
    throw new Error("redirect");
  }
};

const getServerConfig = async () => {
  const { data: config } = await axios.get<ServerConfig>(`/api/v2/get_user_portal_context`);
  window.__cdnBase__ = config?.userPortal?.cdnBase;
  window.__userPortalConfig__ = config.userPortal;
};

export async function configure() {
  const query = querystring.parse(window.location.search);
  const appId = query["app_id"];

  if (appId) {
    await getApplicationConfig(appId as string);
  } else {
    await getServerConfig();
  }
}
