import { createRef } from "react";

export interface FormInstance {
  /** 失败会抛出错误 */
  submit: () => void | Promise<void>;
}

export const createForm = () => {
  return createRef<FormInstance>();
};
