import React, { useState, useMemo, useEffect } from "react";
import { Input, Form, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { walker } from "@/common/tree";
import _ from "lodash";
import { OrgCascader } from "@/components/OrgCascader";
import { addOrgUserNode } from "@/api/user";
/**
 * 创建新部门
 */
export const CeateOrgModal = (props: {
  orgs: any[];
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess: () => void;
}) => {
  const { visible, setVisible, orgs, onSuccess } = props;
  // 状态
  const [loading, setLoading] = useState(false);
  // 创建组织机构表单
  const { t } = useTranslation();
  // 表单
  const [form] = useForm();
  const source = useMemo(() => {
    return [
      {
        value: null,
        label: "无",
        name: "无",
        key: "key-root-null",
      },
      ...walker(_.cloneDeep(orgs || []), (leaf: any) => {
        return {
          ...leaf,
          label: leaf.name,
          name: leaf.name,
          value: leaf.id,
          children: leaf.children,
        };
      }),
    ];
  }, [orgs]);

  useEffect(() => {
    form.setFieldsValue({ parentNodeId: ["无"] }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // 创建部门
  const createOrg = async () => {
    const formData = form.getFieldsValue();
    const { parentNodeId } = formData;
    try {
      setLoading(true);
      await addOrgUserNode({
        ...formData,
        orgId: orgs[0].orgId as string,
        parentNodeId: parentNodeId[0] === "无" ? orgs[0].id : [...parentNodeId].pop(),
      });
      onSuccess();
      message.success("创建成功");
      setVisible(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t("user.org.createDepartment")}
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        setVisible(false);
        // setExpandedKeys([]);
        // setSelectedKeys([]);
        form.resetFields();
      }}
      okText="创建"
      cancelText={t("common.cancel")}
      confirmLoading={loading}
      className="org-modal-form-required"
      maskClosable={false}
      destroyOnClose
    >
      <Form layout="vertical" form={form} onFinish={createOrg}>
        <Form.Item
          name="departName"
          label={t("user.org.departmentName")}
          rules={[
            {
              required: true,
              message: "请输入部门名称",
            },
          ]}
        >
          <Input placeholder="请输入部门名称" />
        </Form.Item>
        <Form.Item
          name="parentNodeId"
          label={t("user.org.preDepartment")}
          rules={[
            {
              required: true,
              message: t("common.pleaseInput", {
                name: t("user.org.preDepartment"),
              }),
            },
          ]}
        >
          <OrgCascader
            onChange={(name) => {
              form.setFieldsValue({
                parentNodeId: name,
              });
            }}
            form={form}
            source={source}
            reset={!visible}
            defaultValue={["无"]}
          />
        </Form.Item>
        <Form.Item
          name="code"
          label={t("user.org.departmentCode")}
          rules={[
            {
              pattern: /^[a-zA-Z0-9-_]*$/,
              message: t("common.identifierRuleMsg"),
            },
          ]}
        >
          <Input placeholder="请输入部门标识符" />
        </Form.Item>
        <Form.Item name="description" label={t("user.org.departmentDes")}>
          <Input placeholder={t("user.org.departmentDes")} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
