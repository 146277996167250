import { Table } from "antd";
import React, { FC, useMemo, useState } from "react";
import { Card } from "../Card";
import styles from "./style.module.less";
import Indexstyles from "../style.module.less";
import { MessageCenterModal } from "./MessageCenterModal";
import classNames from "classnames";
import { useAsyncFn, useEffectOnce } from "react-use";
import { getMessageList, getMessageStatistics, IMessageDetail, updateToReaded } from "@/api/user";
import { formatTime } from "@/utils/formRules";
import { DemoColumn } from "./Chart";
import classnames from "classnames";

interface IMessageCenterCardProps {}

let PAGE_SIZE: number = 5;

export const MessageCenterCard: FC<IMessageCenterCardProps> = (props) => {
  const [detailVisible, setDataVisible] = useState<boolean>(false);
  const [current, setCurrent] = useState<number>(1);
  const [detail, setDetail] = useState<Partial<IMessageDetail>>({});
  const [list, setList] = useState<any>([]);

  const [messageList, getMsgList] = useAsyncFn(async (page: number = 1) => {
    setCurrent(page);
    return await getMessageList({ limit: PAGE_SIZE, page });
  });

  const getMsgCharts = () => {
    getMessageStatistics().then((res) => {
      if (Array.isArray(res)) {
        const data = res.map((item) => ({
          name: item.type === "read" ? "已读" : "未读",
          data: item.name,
          num: item.num,
        }));
        setList(data);
      }
    });
  };

  useEffectOnce(() => {
    getMsgList(1);
    getMsgCharts();
  });

  const columns = useMemo(() => {
    return [
      {
        title: "标题",
        key: "title",
        render: (record: any) => (
          <div
            className={classnames(styles.textTitle, Indexstyles.ellipsisText)}
            style={{ maxWidth: 184 }}
            onClick={() => {
              setDetail(record);
              setDataVisible(true);
            }}
          >
            {record.title}
          </div>
        ),
      },
      {
        title: "业务平台",
        dataIndex: "appName",
        key: "appName",
        render: (appName: string) => (
          <div className={Indexstyles.ellipsisText} style={{ maxWidth: 200 }}>
            {appName}
          </div>
        ),
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        render: (status: number) => {
          return (
            <div className={classNames(styles.tags, !!status ? styles.readered : styles.unreader)}>
              {!!status ? "已读" : "未读"}
            </div>
          );
        },
      },
      {
        title: "时间",
        dataIndex: "insertTime",
        key: "insertTime",
        align: "right",
        render: (insertTime: string) => formatTime(insertTime, 2),
      },
    ];
  }, []);

  return (
    <Card className={styles.messageCenterCardContainer} title="消息中心" loading={messageList.loading}>
      <div className={styles.content}>
        <div className={styles.left}>
          <Table
            columns={columns as any}
            dataSource={messageList.value?.list ?? []}
            size="small"
            rowKey="key"
            pagination={{
              showSizeChanger: false,
              current: current,
              total: messageList.value?.count ?? 0,
              onChange: (page) => {
                getMsgList(page);
              },
              pageSize: PAGE_SIZE,
              size: "default",
            }}
          />
        </div>
        <div className={styles.right}>
          <DemoColumn list={list} />
        </div>
      </div>
      <MessageCenterModal
        visible={detailVisible}
        updateVisible={setDataVisible}
        detail={detail}
        onOk={async () => {
          if (detail.status === 0) {
            await updateToReaded(detail.id as string);
            getMsgList(current);
            getMsgCharts();
          }
          setDataVisible(false);
        }}
      />
    </Card>
  );
};
