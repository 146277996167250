import { Button, Dropdown, Menu, Spin, Tree } from "antd";
import classNames from "classnames";
import React, { FC, Key, useEffect, useState } from "react";
import styles from "../style.module.less";
import { MoreOutlined } from "@ant-design/icons";
import { PlusOutlined } from "@ant-design/icons";
import { IUserOrg, moveUserOrgNode } from "@/api/user";
import { DataNode } from "antd/lib/tree";
import { useNavigate, useLocation } from "react-router";
import { parseLocationSearchUrl } from "@/utils";
import qs from "qs";
import classnames from "classnames";
import { LangxinIconFont } from "@/components/LangxinIconFont";
import { DeleteNodeModal } from "../../Modals/DeleteNodeModal";
import { AddNodeModal } from "../../Modals/AddNodeModal";
import { MoveNodeModal } from "../../Modals/MoveNodeModal";
import { EditNodeModal } from "../../Modals/EditNodeModal";
import { CeateOrgModal } from "../../Modals/CreateOrgModal";
import { useEffectOnce } from "react-use";
import { fetchUserRole } from "@/api/langxinApp";

interface ILeftTreeProps {
  treeData: Partial<IUserOrg[]>;
  getDepartmentUsers: (id: string, page?: number) => void;
  getOrgTree: () => void;
  selectedNode: any;
  setSelectedNode: (o: any) => void;
  setIsSearch: (bool: boolean) => void;
}

const getNode = (nodeKey: Key, treeData: Partial<IUserOrg[]>): IUserOrg | null => {
  for (let node of treeData) {
    if (node?.key === nodeKey) {
      return node;
    }
    if (node?.children?.length) {
      return getNode(nodeKey, node?.children);
    }
  }
  return null;
};

export const LeftTree: FC<ILeftTreeProps> = ({
  treeData = [],
  getDepartmentUsers,
  getOrgTree,
  selectedNode,
  setSelectedNode,
  setIsSearch,
}) => {
  const [selectedKeys, setSelectedKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [addNodeFromVisible, setAddNodeFormVisible] = useState<boolean>(false);
  const [deleteNodeModalVisible, setDeleteNodeModalVisible] = useState<boolean>(false);
  const [moveNodeFormVisible, setMoveNodeFormVisible] = useState<boolean>(false);
  const [editNodeModal, setEditNodeModal] = useState<boolean>(false);
  const [createOrgFromVisible, serCreateOrgFromVisible] = useState<boolean>(false);
  const [currentNode, setCurrentNode] = useState<any>({});
  const [userRole, setUserRole] = useState<any>({});

  useEffect(() => {
    const nodeKey = parseLocationSearchUrl(search).node as Key;
    if (nodeKey && treeData.length) {
      setSelectedKeys([nodeKey]);
      const node = getNode(nodeKey, treeData) || {};
      const { path = [] } = node as any;
      setSelectedNode(node);
      path.length && setExpandedKeys(path);
      getDepartmentUsers(nodeKey as string, 1);
    } else if (treeData.length) {
      getDepartmentUsers(treeData?.[0]?.key as string, 1);
      setSelectedKeys([treeData?.[0]?.key || ""]);
      setSelectedNode(treeData?.[0] as any);
      setExpandedKeys(treeData.length ? (treeData[0]?.path as Key[]) : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setSelectedNode, treeData]);

  useEffectOnce(() => {
    fetchUserRole().then((res) => {
      setUserRole(res);
    });
  });

  const menu = (node: IUserOrg) => (
    <Menu
      style={{
        border: "1px solid #eaebee",
        borderRadius: "8px",
        boxShadow: "0 0 12px 0 #f3f5f8",
        color: "#293350",
        minWidth: "70px",
      }}
    >
      <Menu.Item>
        <div
          onClick={() => {
            setAddNodeFormVisible(true);
            setCurrentNode(node);
          }}
        >
          <LangxinIconFont type="langxin-iconadd2" />
          <span className={classnames(styles["org-tree-item-action-text"])}>添加下级部门</span>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          onClick={() => {
            setEditNodeModal(true);
            setCurrentNode(node);
          }}
        >
          <LangxinIconFont type="langxin-iconedit_black_24dp" />
          <span className={classnames(styles["org-tree-item-action-text"])}>编辑部门</span>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          onClick={() => {
            setMoveNodeFormVisible(true);
            setCurrentNode(node);
          }}
        >
          <LangxinIconFont type="langxin-iconarrow-down-circle-line" />
          <span className={classnames(styles["org-tree-item-action-text"])}>移动部门</span>
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          onClick={() => {
            setDeleteNodeModalVisible(true);
            setCurrentNode(node);
          }}
        >
          <LangxinIconFont type="langxin-icondelete-bin-6-line" />
          <span className={classnames(styles["org-tree-item-action-text"])}>删除</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  const onDrop = async (e: any, node: any) => {
    if (e.dataTransfer.effectAllowed !== "move") return;
    const userInfo = JSON.parse(e.dataTransfer.getData("user/info"));
    e.target.style.color = "";
    e.target.style.background = "";
    try {
      setLoading(true);
      console.log({ userId: userInfo.id, targetNodeId: node.id, sourceNodeId: selectedNode.id });
      await moveUserOrgNode({ userId: userInfo.id, targetNodeId: node.id, sourceNodeId: selectedNode.id });
      getDepartmentUsers(selectedNode.id);
    } finally {
      setLoading(false);
    }
  };

  const onDragLeave = (e: any) => {
    if (e.dataTransfer.effectAllowed !== "move") return;
    e.target.style.color = "";
    e.target.style.background = "";
  };

  const dragoverHandler = (ev: any) => {
    ev.preventDefault();
    if (ev.dataTransfer.effectAllowed !== "move") return;
    if (ev.target.style.color !== "#FFFFFF") ev.target.style.color = "#FFFFFF";
    if (ev.target.style.background !== "#dd8050") ev.target.style.background = "#dd8050";
  };

  return (
    <Spin spinning={loading}>
      <div className={styles.leftTreeContainer}>
        <div className={styles.orgLabel}>
          <i className={classNames("langxin-icon", "langxin-iconorganization-line", styles.orgIcon)}></i>
          <span className={styles.label}>组织机构</span>
        </div>
        {/* <Input
          className={styles.search}
          placeholder="搜索成员和部门"
          suffix={<IconFont type="authing-icon-search" style={{ color: "#515B78", fontSize: "16px" }} />}
        /> */}
        <Tree
          className={styles.orgTree}
          titleRender={(node) => (
            <div
              className={styles.lineTitle}
              onDragOver={dragoverHandler}
              onDrop={(e) => onDrop(e, node)}
              onDragLeave={onDragLeave}
            >
              <div className={styles.treeTitle}>
                <i className={classNames("langxin-icon", "langxin-icongroup-line")} style={{ marginRight: "8px" }}></i>
                {node.title}
              </div>
              {userRole?.orgRole?.isMainAdmin === 1 ? (
                <Dropdown overlay={() => menu(node as IUserOrg)} placement="bottomLeft">
                  <MoreOutlined style={{ fontWeight: 700, fontSize: "20px" }} />
                </Dropdown>
              ) : null}
            </div>
          )}
          treeData={(treeData as DataNode[]) || []}
          defaultExpandAll
          blockNode
          selectedKeys={selectedKeys}
          expandedKeys={expandedKeys}
          onExpand={(expandedKeys) => {
            setExpandedKeys(expandedKeys);
          }}
          onSelect={(selectedKeys, { node }) => {
            setIsSearch(false);
            setSelectedNode(node);
            setSelectedKeys(selectedKeys);
            if (selectedKeys?.length) {
              navigate(`/u?${qs.stringify({ ...parseLocationSearchUrl(search), node: selectedKeys?.[0] })}`);
            }
          }}
          draggable
        />
        {userRole?.orgRole?.isMainAdmin === 1 && (
          <>
            <Button
              className={styles.addAction}
              icon={<PlusOutlined style={{ color: "#515B78" }} />}
              onClick={() => serCreateOrgFromVisible(true)}
            >
              新建部门
            </Button>
            {currentNode && (
              <>
                <DeleteNodeModal
                  currentNode={currentNode}
                  visible={deleteNodeModalVisible}
                  setVisible={setDeleteNodeModalVisible}
                  onSuccess={() => {
                    setDeleteNodeModalVisible(false);
                    getOrgTree();
                    if (selectedNode.id === currentNode.id) {
                      setSelectedKeys([]);
                      setCurrentNode({});
                    }
                  }}
                />

                <AddNodeModal
                  orgs={treeData}
                  disabled={true}
                  expandedKeys={expandedKeys}
                  currentNode={currentNode}
                  visible={addNodeFromVisible}
                  setVisible={setAddNodeFormVisible}
                  onSuccess={(data) => {
                    setAddNodeFormVisible(false);
                    getOrgTree();
                  }}
                />

                <MoveNodeModal
                  orgs={treeData}
                  currentNode={currentNode}
                  visible={moveNodeFormVisible}
                  setVisible={setMoveNodeFormVisible}
                  onSuccess={() => {
                    setMoveNodeFormVisible(false);
                    getOrgTree();
                  }}
                />

                <EditNodeModal
                  currentNode={currentNode}
                  visible={editNodeModal}
                  setVisible={setEditNodeModal}
                  onSuccess={() => {
                    setEditNodeModal(false);
                    getOrgTree();
                  }}
                />

                {/* 新建部门 */}
                <CeateOrgModal
                  orgs={treeData as any}
                  visible={createOrgFromVisible}
                  setVisible={serCreateOrgFromVisible}
                  onSuccess={() => {
                    serCreateOrgFromVisible(false);
                    getOrgTree();
                  }}
                />
              </>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};
