import { LangxinIconFont } from "@/components/LangxinIconFont";
import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import styles from "./style.module.less";

interface IShowCardProps {
  showActionBtn?: boolean;
  imgProps: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  firstTitle: ReactNode;
  secondTitle: string;
  statusType?: "processing" | "success" | "failure";
  statusText?: string;
  actionButton?: ReactNode;
}

// 0 审核中 | 1 认证成功 | 2 认证失败
export const ShowCard: FC<IShowCardProps> = (props) => {
  const { showActionBtn = true, imgProps, firstTitle, secondTitle, statusType, statusText, actionButton } = props;
  return (
    <div className={styles.showCardContainer}>
      <div className={styles.left}>
        {<img {...imgProps} alt="" />}
        <div className={styles.middleContent}>
          <span className={styles.name}>{firstTitle || ""}</span>
          <div className={classNames(styles.statusTips, styles[statusType === "failure" ? "fail" : ""])}>
            {statusType === "failure" && (
              <LangxinIconFont
                type="langxin-iconerror-warning-fill"
                style={{ color: "#FF4D4F", marginRight: "8px", fontSize: "16px" }}
              />
            )}
            {secondTitle || ""}
          </div>
        </div>
      </div>
      {showActionBtn ? (
        actionButton
      ) : (
        <div className={classNames(styles.baseStatus, styles[statusType as string])}>{statusText}</div>
      )}
    </div>
  );
};
