import { useEffect } from "react";
import { User } from "authing-js-sdk";
import EventBus from "onfire.js";

export const noviceEventBus = new EventBus();

export const useNoviceEvent = (evt: string, cb: Function) => {
  useEffect(() => {
    noviceEventBus.on(evt, cb);
    return () => noviceEventBus.off(evt, cb);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const isGuiding = () => {
  return new URLSearchParams(window.location.search).get("is_novice_guidance") === "1";
};

export const finish = () => {
  localStorage.setItem("NOVICE_GUID_FINISHED", "1");
};

export const exitNovice = () => {
  localStorage.setItem("NOVICE_GUID_FINISHED", "1");
  window.location.href = "https://console.authing.cn?exit_novice=1";
};

const POOL_FREQUENCY = 500;
const POOL_TIME = 60 * 1000 * 3; // 3 分钟还找不到就不找了
const POOL_TIMES = POOL_TIME / POOL_FREQUENCY;
export const waitForRender = (target: string | HTMLElement, cb: Function) => {
  let interval: ReturnType<typeof setInterval>;

  if (typeof target === "string") {
    let count = 0;
    interval = setInterval(() => {
      if (document.querySelector(target)) {
        clearInterval(interval);
        requestAnimationFrame(() => cb());
        return;
      }
      if (count >= POOL_TIMES) {
        clearInterval(interval);
      }
      count++;
    }, POOL_FREQUENCY);
  } else {
    cb();
  }
  return () => clearInterval(interval);
};

const ACCOUNT_INFO_SESSION_KEY = "NOVICE_DEFAULT_ACCOUNT";
export const sessionAccountInfo = {
  set(account: any) {
    sessionStorage.setItem(ACCOUNT_INFO_SESSION_KEY, JSON.stringify(account));
  },
  get() {
    const acc = JSON.parse(sessionStorage.getItem(ACCOUNT_INFO_SESSION_KEY) || "null");
    return (
      acc && {
        ...acc,
        identity: acc?.email,
      }
    );
  },
  remove() {
    sessionStorage.removeItem(ACCOUNT_INFO_SESSION_KEY);
  },
};

export const isRegister = (user: User) => {
  if (!user.lastLogin || !user.createdAt) {
    return false;
  }
  return new Date(user.lastLogin).getTime() - new Date(user.createdAt).getTime() <= 2000;
};
