/* eslint-disable react/jsx-no-target-blank */
import React, { CSSProperties, FC } from "react";
import styles from "./styles.module.less";
import { getEnv } from "@/utils/getEnv";

export interface BeianhaoProps {
  style?: CSSProperties;
  className?: string;
}

export const Beianhao: FC<BeianhaoProps> = (props) => {
  const beiAnText = getEnv() === "prod" ? "琼ICP备19004414号-2" : "沪ICP备18010586号-10";
  return (
    <div className={styles.beianContainer}>
      <a href="https://beian.miit.gov.cn" target="_blank">
        {beiAnText}
      </a>
    </div>
  );
};
