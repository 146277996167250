import { isEmail } from "../../utils";
import { message } from "antd";
import { EmailScene } from "authing-js-sdk";
import React, { FC } from "react";
import { SendCode } from "../SendCode";
import { useTranslation } from "react-i18next";

export interface SendEmailCodeProps {
  email: string;
}

export const SendEmailCode: FC<SendEmailCodeProps> = ({ email }) => {
  const { t } = useTranslation();

  return (
    <SendCode
      beforeSend={async () => {
        if (!isEmail(email)) {
          message.error(t("common.emailFormatError"));
          return false;
        }
        await window.__authing__.sendEmail(email, EmailScene.ChangeEmail);
        return true;
      }}
    />
  );
};
