import React, { FC } from "react";
import styles from "./styles.module.less";
import { UpdatePassword } from "./UpdatePassword";
import { useTranslation } from "react-i18next";

export const UserPagePassword: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.password}>
      <h3>{t("user.modifyPwd")}</h3>
      <UpdatePassword />
    </div>
  );
};
