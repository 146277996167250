import React, { useState } from "react";
import { Input, Col, Select, message, DatePicker } from "antd";
import styles from "./styles.module.less";
import { CheckOutlined, CloseOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import classnames from "classnames";
import _ from "lodash";
import { useTranslation } from "react-i18next";

interface IMutableFormItemProps {
  onConfirm: any;
  onCancel: any;
  editing: boolean;
  children: any;
  setEditing: any;
  label: any;
  span: number;
  readonly?: boolean;
}

const MutableFormItem = (props: IMutableFormItemProps) => {
  const { onConfirm, onCancel, editing, children, setEditing, label, span, readonly } = props;

  return (
    <Col span={span} style={{ marginBottom: 24 }}>
      <p className={styles.subtitle}>{label}</p>
      <div className={classnames(styles.mutableInput, { [styles.bg]: !editing })}>
        {children}
        {readonly ? null : editing ? (
          <>
            <CheckOutlined
              className={styles.action}
              onClick={() => {
                onConfirm();
                // updateUser
                setEditing(false);
              }}
            />
            <CloseOutlined
              className={styles.action}
              onClick={() => {
                setEditing(false);
                onCancel();
              }}
            />
          </>
        ) : (
          <EditOutlined className={styles.action} onClick={() => setEditing(true)} />
        )}
      </div>
    </Col>
  );
};

const useMutate = (onSuccess?: any) => {
  const [editing, setEditing] = useState(false);
  const { t } = useTranslation();

  const onConfirm = async (key: string, value: any) => {
    if (!window.__user__?.id) return;
    const user = await window.__authing__.updateProfile({
      [key]: value,
    });
    window.__user__ = user;
    onSuccess();
    message.success(t("common.updateSuccess"));
  };
  const onCancel = () => {};

  return {
    editing,
    setEditing,
    onConfirm,
    onCancel,
  };
};

interface IProps {
  prop: string;
  value: any;
  span: number;
  label: any;
  readonly?: boolean;
  onUpdate?: any;
}

export const MInput = (props: IProps) => {
  const { prop, value, span, label, readonly, onUpdate } = props;
  const { editing, setEditing, onConfirm } = useMutate(onUpdate);
  const [innerValue, setInnerValue] = useState(value);
  return (
    <MutableFormItem
      {...{
        editing,
        setEditing,
        onConfirm: () => onConfirm(prop, innerValue),
        onCancel: () => setInnerValue(value),
        span,
        label,
        readonly,
      }}
    >
      {editing ? (
        <Input
          value={innerValue}
          onChange={(e) => setInnerValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Enter") return;
            onConfirm(prop, innerValue);
            setEditing(false);
          }}
        />
      ) : (
        <span style={{ flex: 1 }}>{innerValue}</span>
      )}
    </MutableFormItem>
  );
};

export const MSelect = (props: IProps & { options: { label: string; value: string }[] }) => {
  const { prop, value, span, label, options, readonly } = props;
  const { editing, setEditing, onConfirm } = useMutate();
  const [innerValue, setInnerValue] = useState(value);

  return (
    <MutableFormItem
      {...{
        editing,
        setEditing,
        onConfirm: () => onConfirm(prop, innerValue),
        onCancel: () => setInnerValue(value),
        span,
        label,
        readonly,
      }}
    >
      {editing ? (
        <Select
          value={innerValue}
          onChange={setInnerValue}
          options={options}
          disabled={!editing}
          style={{ flex: 1 }}
          onKeyDown={(e) => {
            if (e.key !== "Enter") return;
            onConfirm(prop, innerValue);
            setEditing(false);
          }}
        />
      ) : (
        <span style={{ flex: 1 }}>{_.find(options, { value: innerValue })?.label}</span>
      )}
    </MutableFormItem>
  );
};

export const MDatetime = (props: IProps) => {
  const { prop, value, span, label, readonly } = props;
  const { editing, setEditing, onConfirm } = useMutate();
  const [innerValue, setInnerValue] = useState(value);
  const disabledDate = (current: any) => {
    return current && current > moment().endOf("day");
  };
  return (
    <MutableFormItem
      {...{
        editing,
        setEditing,
        onConfirm: () => onConfirm(prop, dayjs(innerValue).toISOString()),
        onCancel: () => setInnerValue(value),
        span,
        label,
        readonly,
      }}
    >
      {editing ? (
        <div style={{ flex: 1 }}>
          <DatePicker
            allowClear={false}
            onChange={(date, dateString) => {
              setInnerValue(dateString);
            }}
            style={{ width: "100%" }}
            disabledDate={disabledDate}
            value={innerValue ? moment(innerValue) : moment(new Date())}
          />
          {/* <Input
            type="date"
            value={dayjs(innerValue).format("YYYY-MM-DD")}
            onChange={(e) => {
              console.log(e.target.value);
              setInnerValue(e.target.value);
            }}
            style={{ flex: 1 }}
            onKeyDown={(e) => {
              if (e.key !== "Enter") return;
              onConfirm(prop, dayjs(innerValue).toISOString());
              setEditing(false);
            }}
          /> */}
        </div>
      ) : (
        // <Input
        //   type="date"
        //   value={dayjs(innerValue).format("YYYY-MM-DD")}
        //   onChange={(e) => setInnerValue(e.target.value)}
        //   style={{ flex: 1 }}
        //   onKeyDown={(e) => {
        //     if (e.key !== "Enter") return;
        //     onConfirm(prop, dayjs(innerValue).toISOString());
        //     setEditing(false);
        //   }}
        // />
        <span style={{ flex: 1 }}>{innerValue ? moment(innerValue).format("YYYY-MM-DD") : "-"}</span>
      )}
    </MutableFormItem>
  );
};
