import React from 'react';
import './styles.less';
// import { SyncOutlined } from '@ant-design/icons';

export const RegisteredByCompanies = () => {
	return (
		<div className="registered-companies">
			{/* <SyncOutlined />
			<span style={{ paddingLeft: '7px' }}>切换企业注册</span> */}
		</div>
	);
};
