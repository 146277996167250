import React, { useEffect, useMemo, useState } from "react";
import { Input, Form, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { OrgCascader } from "@/components/OrgCascader";
import { walker } from "@/common/tree";
import _ from "lodash";
import { addOrgUserNode } from "@/api/user";

export const AddNodeModal = (props: {
  currentNode: any;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  onSuccess: (org?: any) => void;
  disabled?: boolean;
  expandedKeys: string[];
  orgs: any[];
}) => {
  const { visible, setVisible, currentNode, orgs, onSuccess } = props;
  const { id: _parentId, path: initialPath } = currentNode;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = useForm();
  const [path, setPath] = useState(initialPath);

  const source = useMemo(() => {
    return walker(_.cloneDeep(orgs || []), (leaf: any) => {
      return {
        ...leaf,
        label: leaf.name,
        name: leaf.name,
        value: leaf.id,
        children: leaf.children,
      };
    });
  }, [orgs]);

  useEffect(() => {
    // setParentId(_parentId);
    setPath(currentNode.path);
  }, [_parentId, currentNode, path, visible]);

  // 添加子节点
  const addNode = async () => {
    await form.validateFields();
    setLoading(true);
    const formData = form.getFieldsValue();
    try {
      await addOrgUserNode({ orgId: currentNode.orgId, parentNodeId: [...currentNode.path].pop(), ...formData });
      message.success(t("user.org.addNodeSuccesss"));
      onSuccess();
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      className="org-modal-form-required"
      title={t("user.org.addSubNode")}
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        setVisible(false);
      }}
      okText={"创建"}
      cancelText={"取消"}
      confirmLoading={loading}
      maskClosable={false}
      destroyOnClose
    >
      <Form layout="vertical" form={form} onFinish={addNode}>
        <Form.Item required label={t("user.org.belongDepartment")}>
          <OrgCascader
            onChange={(name) => {
              form.setFieldsValue({
                parentNodeId: name,
              });
            }}
            defaultValue={currentNode.path}
            form={form}
            source={source}
            disabled
          />
        </Form.Item>
        <Form.Item
          name="departName"
          label={t("user.org.subNodeName")}
          rules={[{ required: true, message: t("user.org.inputSubNodeName") }]}
        >
          <Input placeholder={t("user.org.inputSubNodeName")} />
        </Form.Item>
        <Form.Item
          name="code"
          label={t("user.org.nodeCode")}
          rules={[{ pattern: /^[a-zA-Z0-9-_]*$/, message: "只允许包含英文字母、数字、下划线 _、横线 -" }]}
        >
          <Input placeholder={t("user.org.inputNodeCode")} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
