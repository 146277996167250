import React from "react";
import ReactDOM from "react-dom";
import { MainLayout } from "./MainLayout";
import "./assets/css/antd.less";
import "./index.less";
import { configure } from "./config";
import { LoadingComponent } from "./components/Loading/index";
import zhCN from "antd/es/locale/zh_CN";
import enUS from "antd/es/locale/en_US";
import { ConfigProvider } from "antd";
import { initSensors } from "./utils/sensors";
import "react-app-polyfill/ie11";
import "url-polyfill";
// import "core-js/features/array/find";
// import "core-js/features/array/includes";
// import "core-js/features/array/find-index";
// import "core-js/features/number/is-nan";
// import "core-js/features/string/includes";
// import "core-js/features/string/ends-with";
// import "core-js/features/string/starts-with";
// import "core-js/features/object/values";
// import "core-js/features/object/entries";
// import "core-js/features/promise";
// import "core-js/features/symbol";
import "core-js";

import "./locales"; // 初始化 i18n
import { Lang, i18n } from "./locales";

const root = document.getElementById("root");

const langMap = {
  [Lang.zhCn]: zhCN,
  [Lang.enUs]: enUS,
};

initSensors();

async function bootstrap() {
  ReactDOM.render(<LoadingComponent />, root);
  await configure();
  ReactDOM.render(
    <ConfigProvider locale={langMap[i18n.language as Lang]}>
      <MainLayout />
    </ConfigProvider>,
    root
  );
}

bootstrap().catch(console.error);
