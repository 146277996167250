import { Button, Layout, message, Modal } from "antd";
import React, { ReactNode, FC, useCallback, useContext } from "react";
import styles from "./style.module.less";
import langxinLogo from "@/assets/image/langxin_logo.png";
// import { useNavigateWithSearch } from "@/hooks/useNavigateWithSearch";
import { useTranslation } from "react-i18next";
import axios from "axios";
import classNames from "classnames";
import { NotifyContainer } from "../NotifyContainer";
import { UserContext } from "@/context/userContext";
import { useAsyncFn, useEffectOnce } from "react-use";
import { getNotice } from "@/api/user";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { Header, Content, Sider } = Layout;

interface IUserLayoutProps {
  children: ReactNode;
  sider: ReactNode;
}
export const UserLayout: FC<IUserLayoutProps> = (props) => {
  const { user } = useContext(UserContext);
  const { children, sider } = props;
  // const nav = useNavigateWithSearch();
  const { t } = useTranslation();

  const onLogout = useCallback(async () => {
    await axios.post("/logout");
    if (await window.__authing__.getCurrentUser()) {
      await window.__authing__.logout();
    }
    message.success(t("common.logoutSuccess"));
    setTimeout(() => {
      let redirect = "/login";
      if (window.__config__.logoutRedirectUris?.length) {
        redirect = window.__config__.logoutRedirectUris[0];
      }
      window.location.href = redirect;
    }, 500);
  }, [t]);

  // const gotoConsole = () => {
  //   window.location.href = "/console";
  // };

  // const gotoUserCenter = useCallback(() => {
  //   nav("/u");
  // }, [nav]);

  // const gotoLaunchpad = useCallback(() => {
  //   nav("/launchpad");
  // }, [nav]);

  const showLogout = useCallback(() => {
    Modal.warning({
      title: t("common.confirmLogout"),
      okCancel: true,
      onOk: onLogout,
    });
  }, [onLogout, t]);

  // const menu = useMemo(() => {
  //   return (
  //     <Menu>
  //       {window.__userPoolId__ === window.__config__.rootUserPoolId && (
  //         <Menu.Item onClick={gotoConsole}>{t("common.dashboard")}</Menu.Item>
  //       )}
  //       <Menu.Item onClick={gotoUserCenter}>{t("common.personalCenter")}</Menu.Item>
  //       <Menu.Item onClick={gotoLaunchpad}>{t("common.appPanel")}</Menu.Item>
  //       <Menu.Item onClick={showLogout}>{t("common.logout")}</Menu.Item>
  //     </Menu>
  //   );
  // }, [gotoLaunchpad, gotoUserCenter, showLogout, t]);

  const [noticeValue, fetchNotice] = useAsyncFn(async () => {
    return await getNotice();
  }, []);

  useEffectOnce(() => {
    const flag = sessionStorage.getItem("FIRST_LOGIN_FLAG");
    if (!flag) {
      fetchNotice();
      const { createdAt, username } = user || {};
      message.success(
        `欢迎你，${username}！你已登录 ${dayjs(Date.now()).diff((createdAt as string) || Date.now(), "day")} 天。`,
        4,
        () => {
          sessionStorage.setItem("FIRST_LOGIN_FLAG", "ok");
        }
      );
    }
  });

  return (
    <Layout className={styles.userLayoutContainer}>
      <Header className={styles.header}>
        <div
          className={styles.logo}
          style={{
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url('${langxinLogo}')`,
          }}
        ></div>
        {noticeValue.value?.[0]?.content && (
          <NotifyContainer
            iconType="langxin-iconvolume-up-fill"
            notifyType="warn"
            text={noticeValue.value[0].content}
          />
        )}
        <div className={styles.leftHeaderContainer}>
          <span className={styles.help}>
            <QuestionCircleOutlined className={styles.helpIcon} />
            帮助文档
          </span>
          <Button type="primary" style={{ marginLeft: "32px" }} onClick={showLogout}>
            退出
          </Button>
        </div>
      </Header>
      <Layout>
        <Sider width={200} className={classNames(styles.siteLayoutBackground, styles.leftSider)}>
          {sider}
        </Sider>
        <Layout className={classNames(styles.layoutControll, styles.siteLayoutBackground)}>
          <Content
            className={classNames(styles.siteLayoutBackground, styles.layContent)}
            style={{
              minHeight: 280,
              maxHeight: "clac(100vh - 64px)",
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
