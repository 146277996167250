import { LangxinIconFont } from "@/components/LangxinIconFont";
import { getImgSrc } from "@/utils";
import { message, Tooltip } from "antd";
import classnames from "classnames";
import React, { FC, useState } from "react";
import styles from "./style.module.less";

interface ITitleCardProps {
  title: string;
  img: string;
  delHandle?: () => any;
  onClick?: () => void;
  className?: string;
}

export const TitleCard: FC<ITitleCardProps> = ({ title, img, delHandle, onClick = () => {}, className }) => {
  const [showDel, setShowDel] = useState<boolean>(false);

  return (
    <div
      className={classnames(styles.titleCard, className)}
      onClick={onClick}
      onMouseOver={() => delHandle && setShowDel(true)}
      onMouseLeave={() => delHandle && setShowDel(false)}
    >
      <img src={img} alt="" className={styles.img} />
      <Tooltip title={title}>
        <div
          className={styles.title}
          style={{ backgroundImage: `url(${getImgSrc("/langxin/app_name_background.pic.jpg")})` }}
        >
          <span>{title}</span>
        </div>
      </Tooltip>
      {showDel && delHandle && (
        <LangxinIconFont
          type="langxin-iconclose-circle-fill"
          style={{ color: "rgba(221, 128, 80, 1)", fontSize: "12px", cursor: "pointer" }}
          onClick={async (e) => {
            e.stopPropagation();
            await delHandle();
            message.success("删除成功");
          }}
        />
      )}
    </div>
  );
};
