import React, { FC, useEffect } from "react";
import { Container } from "../../../components/Container";
import { Header } from "../../../components/Header";
import { useCounter } from "react-use";
import { useNavigateWithSearch } from "../../../hooks/useNavigateWithSearch";
import { useTranslation } from "react-i18next";

export const ResetPasswordStep4: FC = () => {
  const config = window.__config__;
  const navigate = useNavigateWithSearch();
  const [count, { dec, get }] = useCounter(3);
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      dec();
      if (get() <= 0) navigate("/");
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <Container>
      <Header title={t("common.resetPwd")} logo={config.logo} large />

      <div style={{ textAlign: "center" }}>
        <h3 style={{ fontSize: 20, fontFamily: "PingFangSC-Medium", marginBottom: 0 }}>
          {t("common.pwdModifySuccess")}
        </h3>
        <p style={{ fontSize: 12 }}>
          {t("common.jumpAfterCount", {
            number: count,
          })}
        </p>
      </div>
    </Container>
  );
};
