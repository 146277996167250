import React, { FC } from 'react';
import { Layout } from 'antd';
import styles from './styles.module.less';
// import { PageFooter } from '../PageFooter';

const { Content } = Layout;

export interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
	minHeight?: number | string;
	width?: number | string;
}

export const Container: FC<ContainerProps> = ({ children, width, minHeight }) => {
	return (
		<>
			<Content
				className={`${styles.container} __authing-container`}
				style={{
					width,
					minHeight
				}}
			>
				{children}
			</Content>
			{/* <PageFooter /> */}
		</>
	);
};
