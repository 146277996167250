import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { detectSingleFace } from "face-api.js";
import {
  getFaceDetectorOptions,
  isFaceDetectionModelLoaded,
  getCurrentFaceDetectionNet,
} from "./faceDetectionControls";
import { Spin } from "antd";

import styles from "./index.module.css";
import { useTranslation } from "react-i18next";
import { Progress, ProgressStatus } from "./components/Progress";

function dataURItoBlob(base64Data: any) {
  var byteString;
  if (base64Data.split(",")[0].indexOf("base64") >= 0) byteString = atob(base64Data.split(",")[1]);
  else byteString = unescape(base64Data.split(",")[1]);
  var mimeString = base64Data.split(",")[0].split(":")[1].split(";")[0];
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
}

export interface CapturePhotoProps {
  onSeize?: (blob: Blob) => Promise<boolean>;
  bottomNode?: React.ReactNode;
  headerNode?: React.ReactNode;
  errorMes?: string;
  tip?: string;
  isVideoStop?: boolean;
}

export enum VideoAction {
  "STOP" = "stop",
  "ERROR" = "error",
  "SUCCESS" = "success",
}

export const FACE_SCORE = 0.65;

export const CapturePhoto: FC<CapturePhotoProps> = ({
  onSeize,
  bottomNode,
  headerNode,
  errorMes = "",
  tip = "Loading...",
  isVideoStop = false,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const checking = useRef(false);

  const [videoError, setVideoError] = useState<string>("");

  const interval = useRef<NodeJS.Timeout | undefined>();

  const [percent, setPercent] = useState(0);

  const [progressStatus, setProgressStatus] = useState<ProgressStatus>("active");

  const [loading, setLoading] = useState(false);

  const [videoType, setVideoType] = useState<VideoAction>(VideoAction.SUCCESS);

  const { t } = useTranslation();

  const onPlay = useCallback(async () => {
    if (!interval.current) {
      interval.current = setInterval(() => onPlay(), 500);
    }

    const videoEl = videoRef.current!;

    if (videoEl.paused || videoEl.ended || !isFaceDetectionModelLoaded()) return;

    const options = getFaceDetectorOptions();

    const result = await detectSingleFace(videoEl, options);

    if (!checking.current && result) {
      if (result.score > FACE_SCORE) {
        checking.current = true;
        setLoading(true);
        setPercent(100);
        setProgressStatus("success");
        const mycanvas = canvasRef.current!;

        const ctx = mycanvas!.getContext("2d")!;
        ctx.clearRect(0, 0, mycanvas.width, mycanvas.height);
        ctx.drawImage(videoEl, 0, 0, mycanvas.width, mycanvas.height);

        const base64Data = mycanvas.toDataURL("image/jpeg", 1.0);
        const blob = dataURItoBlob(base64Data);

        if (onSeize && (await onSeize(blob))) {
          checking.current = false;
          setPercent(0);
          setProgressStatus("active");
        } else {
          clearInterval(interval.current);
          isVideoStop && setVideoType(VideoAction.STOP);
          isVideoStop && setProgressStatus("exception");
        }

        setLoading(false);
      } else {
        setProgressStatus("active");
        setPercent(() => {
          return (result.score / FACE_SCORE) * 100;
        });
      }
    }
  }, [isVideoStop, onSeize]);

  function run() {
    setLoading(true);
    // getCurrentFaceDetectionNet();
    // .loadFromUri("https://raw.githubusercontent.com/justadudewhohacks/face-api.js/master/weights")
    // .then((_) => {
    //   setLoading(false);
    // });
    getCurrentFaceDetectionNet()
      .loadFromUri(`${window.__cdnBase__}/face-api/v1/tiny_face_detector_model-weights_manifest.json`)
      .then((_) => {
        setLoading(false);
      });
  }
  const initVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: 210,
          height: 210,
        },
      })
      .then((stream) => {
        videoRef.current!.srcObject = stream;
      })
      .catch((e) => {
        setVideoType(VideoAction.ERROR);
        setProgressStatus("exception");
        setPercent(100);
        // 没有设备或者没有授权。。。
        console.error(e);
        setVideoError(e.message);
      });
  };
  useEffect(() => {
    run();
    initVideo();
    return () => {
      interval.current && clearInterval(interval.current);
    };
  }, [interval]);

  const videoActionElm = useMemo(
    () => ({
      [VideoAction.STOP]: (
        <div
          style={{
            width: 220,
            height: 220,
            borderRadius: "50%",
            backgroundColor: "#000",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            initVideo();

            checking.current = false;
            interval.current = undefined;
            setVideoType(VideoAction.SUCCESS);
            setPercent(0);
            setProgressStatus("active");
            onPlay();
          }}
        >
          {t("common.faceVideoStopDoc")}
        </div>
      ),
      [VideoAction.SUCCESS]: (
        <Spin spinning={loading} className={styles.pocSpin} wrapperClassName="poc-face-spin" tip={tip}>
          <video
            style={{
              width: 220,
              height: 220,
              borderRadius: "50%",
              verticalAlign: "middle",
            }}
            ref={videoRef}
            onLoadedMetadata={() => onPlay()}
            id="inputVideo"
            autoPlay
            muted
            playsInline
          />
        </Spin>
      ),
      [VideoAction.ERROR]: (
        <div
          style={{
            width: 220,
            height: 220,
            borderRadius: "50%",
            backgroundColor: "#000",
          }}
        />
      ),
    }),
    [loading, onPlay, t, tip]
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexFlow: "column",
      }}
    >
      <div
        className="authing-guard-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexFlow: "column",
        }}
      >
        {headerNode || null}
        <Progress
          size={228}
          percent={videoType === VideoAction.ERROR ? 100 : percent}
          status={videoType === VideoAction.ERROR ? "exception" : progressStatus}
        >
          <div
            style={{
              width: 220,
              height: 220,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              overflow: "scroll",
              boxSizing: "border-box",
              overflowX: "hidden",
              overflowY: "hidden",
            }}
          >
            {videoActionElm[videoType]}
          </div>
        </Progress>

        <canvas
          style={{
            width: 210,
            height: 210,
            opacity: 0,
            position: "absolute",
            display: "none",
          }}
          ref={canvasRef}
        />
        {bottomNode || null}
        {(errorMes !== "" || videoError !== "") && (
          <div className={styles.message}>
            {videoError !== "" ? (
              <>
                <span className={styles.messageTitle}>{t("common.captureMessageTitle") + ":"}</span>
                <span className={styles.messageTip}>{videoError}</span>
              </>
            ) : (
              <>
                <span className={styles.messageTitle}>{t("common.captureErrorTitle") + ":"}</span>
                <span className={styles.messageTip}>{errorMes}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
