import { State } from "history";
import { useNavigate, useLocation } from "react-router";
import querystring from "query-string";

let navigateWithSearch: any;

export function useNavigateWithSearch() {
  const navigte = useNavigate();
  const location = useLocation();

  const query = querystring.parse(location.search);
  const search =
    "?" +
    querystring.stringify({
      ...query,
      goto: undefined,
    });

  if (!navigateWithSearch) {
    navigateWithSearch = (
      pathname: string,
      options?: {
        replace?: boolean;
        state?: State;
      }
    ) =>
      navigte(
        {
          search,
          pathname,
        },
        options
      );
  }

  return navigateWithSearch;
}
