import axios from "axios";

axios.interceptors.response.use(
  function (response: any) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    const userPoolId = window.__userPoolId__;
    const token = window.__user__?.token;
    config.headers["authtoken"] = "Bearer " + token || "";
    config.headers["x-authing-userpool-id"] = userPoolId;
    config.headers["x-authing-app-id"] = window.__appId__;
    config.headers["x-authing-request-from"] = "userPortal";
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
