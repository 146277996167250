import React, { FC, useEffect, useState } from "react";
import { Cascader, Tree } from "antd";
import { LangxinIconFont } from "../LangxinIconFont";
import styles from "./styles.module.less";
import { FormInstance } from "antd/lib/form";
import { CascaderOptionType } from "antd/lib/cascader";

interface IOrgCascaderProps {
  form: FormInstance<any>;
  source: CascaderOptionType[];
  onChange: (name: string[]) => void;
  reset?: boolean;
  defaultValue?: string[];
  disabled?: boolean;
}
export const OrgCascader: FC<IOrgCascaderProps> = ({
  form,
  source,
  onChange,
  reset = false,
  defaultValue,
  disabled = false,
}) => {
  const [expandedKeys, setExpandedKeys] = useState<any[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<any[]>([]);
  const [value, setValue] = useState<string[]>([]);

  useEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (reset) {
      setExpandedKeys([]);
      setSelectedKeys([]);
      setValue([]);
    }
  }, [reset]);

  // 自定义下拉框
  const dropdownRender = (ev: any) => {
    const menus = ev.props.options;
    return menus ? (
      <div style={{ width: 610 }}>
        {/* 组织机构树 */}
        <Tree
          className={styles.orgCascaderTree}
          treeData={menus}
          titleRender={(node) => {
            return (
              <div className={styles.lineTitle}>
                <div className={styles.treeTitle}>
                  <LangxinIconFont type="langxin-icongroup-line" />
                  <span className={styles.title}>{(node as any).name}</span>
                </div>
              </div>
            );
          }}
          blockNode={true}
          expandedKeys={expandedKeys}
          defaultExpandAll={true}
          onExpand={(expandedKeys) => {
            setExpandedKeys(expandedKeys);
          }}
          selectedKeys={selectedKeys}
          onSelect={(selectedKeys, e) => {
            const { node } = e as any;
            if (selectedKeys[0] === "key-root-null") {
              onChange(["无"]);
              setSelectedKeys(["无"]);
              setExpandedKeys(["无"]);
              setValue(["无"]);
            } else {
              onChange(node.path);
              setExpandedKeys(node.path);
              setSelectedKeys(selectedKeys);
              setValue(node.path);
            }
          }}
        />
      </div>
    ) : null;
  };

  return (
    <Cascader
      options={source}
      expandTrigger="hover"
      changeOnSelect
      value={value}
      dropdownRender={dropdownRender}
      disabled={disabled}
    />
  );
};
