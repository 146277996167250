// TODO 将所有使用 css font 方式的图标改为这个组件
import React, { FC } from "react";
import "@/assets/css/iconfont";
import "./style.less";

export const IconFont: FC<{
  type: string;
  style?: React.CSSProperties;
}> = ({ type, style }) => {
  return (
    <svg style={{ ...style }} className="authing-js-icon">
      <use xlinkHref={`#${type}`}></use>
    </svg>
  );
};
