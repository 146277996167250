import React, { FC, useEffect, useState } from "react";
import styles from "./style.module.less";
import { ShowCard } from "../components/ShowCard";
import { getImgSrc } from "@/utils";
import { Button, Input, Space, Spin } from "antd";
import { ApplyAuthModal } from "./ApplyAuthModal";
import { LangxinIconFont } from "@/components/LangxinIconFont";
import { useAsyncFn } from "react-use";
import { searchOrgList } from "@/api/user";
import { getUserAuthStatus } from "@/api/langxinApp";
import { authCheckMap } from "../EnterpriseCert/constants";

interface IEmployeeCertProps {}

export const EmployeeCert: FC<IEmployeeCertProps> = (props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [selectedDepart, setSelectedDepart] = useState({});
  const [authStatus, setAuthStatus] = useState({
    status: -1,
    isAuth: false,
    orgInfo: {
      orgName: "",
      des: "",
      orgId: "",
    },
    failMsg: "",
  });

  const [list, getList] = useAsyncFn(async (value?: string) => {
    return searchOrgList(value);
  }, []);

  useEffect(() => {
    getUserAuthStatus().then((statusInfo) => {
      const { orgId, status, orgName, description, reason } = statusInfo || {};
      setAuthStatus({
        status,
        isAuth: !!statusInfo,
        orgInfo: {
          orgName,
          des: description,
          orgId,
        },
        failMsg: reason,
      });
    });
  }, []);

  return (
    <div className={styles.employeeCertContainer}>
      {!authStatus.isAuth ? (
        <Spin spinning={list.loading}>
          <Space direction="vertical" size="large">
            <Input
              className={styles.searchWrap}
              prefix={<LangxinIconFont type="langxin-iconsearch" style={{ fontSize: "26px", color: "#ACB5B0" }} />}
              placeholder="请输入要搜索的企业名称"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onPressEnter={() => getList(value)}
            />
            {list.value?.length ? (
              list.value?.map((item) => (
                <ShowCard
                  key={item.nodeId}
                  showActionBtn={true}
                  firstTitle={item.name}
                  secondTitle={item.description ?? "-"}
                  statusType="processing"
                  statusText={"认证中"}
                  imgProps={{
                    src: getImgSrc("/langxin/undraw_quite_town_mg2q.svg"),
                    style: {
                      height: 47,
                    },
                  }}
                  actionButton={
                    <Button
                      style={{ width: 120 }}
                      type="primary"
                      onClick={() => {
                        setVisible(true);
                        setSelectedDepart(item);
                      }}
                    >
                      申请认证
                    </Button>
                  }
                />
              ))
            ) : (
              <div className={styles.noList}>
                <img src={getImgSrc("/langxin/Lark20210514-114224.png")} alt="" />
              </div>
            )}
          </Space>
        </Spin>
      ) : (
        <ShowCard
          key={authStatus.orgInfo.orgId}
          showActionBtn={false}
          firstTitle={authStatus.orgInfo.orgName}
          secondTitle={
            authStatus.status === authCheckMap.AUTH_FAIL ? authStatus.failMsg : authStatus.orgInfo.des ?? "-"
          }
          statusType={
            authStatus.status === authCheckMap.WAIT_CHECK
              ? "processing"
              : authStatus.status === authCheckMap.AUTH_SUCESS
              ? "success"
              : "failure"
          }
          statusText={
            authStatus.status === authCheckMap.WAIT_CHECK
              ? "认证中"
              : authStatus.status === authCheckMap.AUTH_SUCESS
              ? "已认证"
              : "认证失败"
          }
          imgProps={{
            src: getImgSrc("/langxin/undraw_quite_town_mg2q.svg"),
            style: {
              height: 47,
            },
          }}
        />
      )}
      <ApplyAuthModal
        visible={visible}
        setVisible={setVisible}
        selectTree={selectedDepart}
        selectedDepart={selectedDepart}
      />
    </div>
  );
};
