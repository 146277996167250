export const certificationStatusMap = {
  NO_CERTIFICATION: "no_certification",
  PROCESSING_CERTIFICATION: "processing_certification",
  YES_CERTIFICATION: "yes_certification",
};

export const authCheckMap = {
  WAIT_CHECK: 0, // 待审核
  AUTH_SUCESS: 1, // 通过
  AUTH_FAIL: 2, // 未通过
  AUTH_CANCEL: 3, // 已撤销
};

export const authStatusMap = {
  AUTH_Yes: 1, // 已认证
  AUTH_No: 0, // 未认证
};

export const enterpriseAuthStatusMap = {
  0: {
    type: "warning",
    text: "审核中",
  },
  1: {
    type: "success",
    text: "已认证",
  },
  2: {
    type: "error",
    text: "审核失败",
  },
};

export const formatTree = (treeChilds: any[]) => {
  return treeChilds.map((treeInfo) => {
    if (treeInfo?.children?.length) {
      treeInfo.children = formatTree(treeInfo?.children);
    }
    return {
      ...treeInfo,
      title: treeInfo?.name,
      key: treeInfo?.id,
    };
  });
};
