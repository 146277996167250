import React, { FC } from "react";
import { Container } from "../../components/Container/index";
import { Header } from "../../components/Header/index";
import { RoleList } from "../../components/RoleList";
import { useTitle } from "../../hooks/useTitle";
import { useTranslation } from "react-i18next";

export const ChooseRolePage: FC = () => {
  const { t } = useTranslation();

  useTitle(t("common.chooseRole"));

  const config = window.__config__;
  const roles = [
    {
      role: t("common.administrator"),
      org: t("common.authingBackendSystem"),
      name: t("common.username"),
    },
    {
      role: t("common.administrator"),
      org: t("common.authingBackendSystem"),
      name: t("common.username"),
    },
    {
      role: t("common.administrator"),
      org: t("common.authingBackendSystem"),
      name: t("common.username"),
    },
    {
      role: t("common.administrator"),
      org: t("common.authingBackendSystem"),
      name: t("common.username"),
    },
  ];

  return (
    <Container>
      <Header title={config.name} logo={config.logo} />
      <RoleList roles={roles} />
    </Container>
  );
};
