import { SendEmailCode } from "../../../../components/SendEmailCode";
import { message, Input, Form, Modal } from "antd";
import React, { FC, useState } from "react";
import { User } from "authing-js-sdk";
import { useTranslation } from "react-i18next";
import { Lang } from "@/locales";

export interface UpdateEmailProps {
  user: User;
  visible: boolean;
  onOk?: (user: User) => void;
  onCancel?: () => void;
}

export const UpdateEmail: FC<UpdateEmailProps> = ({ user, visible, onOk, onCancel }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  return (
    <Modal
      title={t("common.modifyEmail")}
      visible={visible}
      onOk={form.submit}
      onCancel={onCancel}
      confirmLoading={loading}
      width="800px"
    >
      <Form
        form={form}
        hideRequiredMark
        labelAlign="left"
        labelCol={{ span: i18n.language === Lang.zhCn ? 6 : 8 }}
        onFinish={async (values) => {
          try {
            setLoading(true);
            const { emailCode, oldEmailCode } = values;
            const data = await window.__authing__.updateEmail(email, emailCode, user?.email!, oldEmailCode);
            onOk && onOk(data);
            message.success(t("common.updateSuccess"));
          } finally {
            setLoading(false);
          }
        }}
      >
        <Form.Item name="oldEmail" label={t("common.currentEmail")} initialValue={user?.email}>
          <Input placeholder={t("common.currentEmail")} readOnly />
        </Form.Item>

        <Form.Item
          name="oldEmailCode"
          label={t("common.currentEmailVerifyCode")}
          rules={[{ required: true, message: t("login.noEmpty") }]}
        >
          <Input
            autoComplete="one-time-code"
            placeholder={t("common.inputFourVerifyCode", {
              length: window.__config__.verifyCodeLength,
            })}
            addonAfter={<SendEmailCode email={user?.email!} />}
          />
        </Form.Item>

        <Form.Item
          name="email"
          label={t("common.newMailbox")}
          rules={[{ required: true, type: "email", message: t("common.pleaseInputCorrectMailbox") }]}
        >
          <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="请输入新邮箱" />
        </Form.Item>

        <Form.Item
          name="emailCode"
          label={t("common.newMailboxVerifyCode")}
          rules={[{ required: true, message: t("login.noEmpty") }]}
        >
          <Input
            autoComplete="one-time-code"
            placeholder={t("common.inputFourVerifyCode", {
              length: window.__config__.verifyCodeLength,
            })}
            addonAfter={<SendEmailCode email={email} />}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
