// TODO 将所有使用 css font 方式的图标改为这个组件
import React, { FC, MouseEventHandler } from "react";
import "@/assets/css/langxin-iconfont/iconfont";
import "./style.less";

export const LangxinIconFont: FC<{
  type: string;
  style?: React.CSSProperties;
  onClick?: MouseEventHandler<SVGElement>;
}> = ({ type, style, onClick }) => {
  return (
    <svg style={{ ...style }} className="authing-js-icon" onClick={onClick}>
      <use xlinkHref={`#${type}`}></use>
    </svg>
  );
};
